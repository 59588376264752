import { Logger } from '@vue-storefront/core/lib/logger';

import { cacheStorage } from '../cache';

import * as types from './mutation-types';
export function plugin(mutation, state) {
  const type = mutation.type;
  // TODO: remove after vsf version update
  // check if this mutation is compare related
  if (
    type.includes(types.COMPARE_ADD_ITEM) ||
    type.includes(types.COMPARE_DEL_ITEM) ||
    type.includes(types.COMPARE_LOAD_COMPARE)
  ) {
    cacheStorage.setItem('current-compare', state.compare.items).catch((reason) => {
      Logger.error(reason, 'compare');
    });
  }
}
