import Vue from 'vue';
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';
import { Format } from '@zento-lib/components/i18n/inline';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';

import { CartService } from '../../data-resolver/CartService';
import { createDiffLog, notifications } from '../../helpers';
import { cartHooksExecutors } from '../../hooks';
import * as types from '../mutation-types';

const synchronizeActions = {
  async load({ commit, dispatch }, { forceClientState = false }: { forceClientState?: boolean } = {}) {
    if (isServer) {
      return;
    }

    dispatch('setDefaultCheckoutMethods');

    const storedItems = await Vue.prototype.$db.cartsCollection.getItem('current-cart');

    commit(types.CART_LOAD_CART, storedItems);
    dispatch('synchronizeCart', { forceClientState });

    cartHooksExecutors.afterLoad(storedItems);
  },

  syncCartWhenLocalStorageChange({ commit }, { items }) {
    commit(types.CART_LOAD_CART, items);
  },

  async synchronizeCart({ commit, dispatch }, { forceClientState }) {
    const { synchronize, serverMergeByDefault } = config.cart;

    if (!synchronize) {
      return;
    }

    const cartStorage = Vue.prototype.$db.cartsCollection;
    const token = await cartStorage.getItem('current-cart-token');
    const hash = await cartStorage.getItem('current-cart-hash');

    if (hash) {
      commit(types.CART_SET_ITEMS_HASH, hash);
      Logger.info('Cart hash received from cache.', 'cache', hash)();
    }

    if (token) {
      commit(types.CART_LOAD_CART_SERVER_TOKEN, token);
      Logger.info('Cart token received from cache.', 'cache', token)();
      Logger.info('Syncing cart with the server.', 'cart')();
      dispatch('sync', { forceClientState, dryRun: !serverMergeByDefault });
    }

    await dispatch('create');
  },

  /** @deprecated backward compatibility only */
  async serverPull({ dispatch }, { forceClientState = false, dryRun = false }) {
    Logger.warn('The "cart/serverPull" action is deprecated and not supported in Vue Storefront 1.11', 'cart')();

    return dispatch('sync', { forceClientState, dryRun });
  },

  async sync(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { rootState, getters, rootGetters, commit, dispatch, state },
    { forceClientState = false, dryRun = false, mergeQty = false, forceSync = false },
  ) {
    const shouldUpdateClientState = rootGetters['checkout/isUserInCheckout'] || forceClientState;
    const { getCartItems, canUpdateMethods, isSyncRequired, bypassCounter } = getters;

    if ((!canUpdateMethods || !isSyncRequired) && !forceSync) {
      return createDiffLog();
    }

    commit(types.CART_SET_SYNC);
    const { result, resultCode } = await CartService.getItems(rootState.user.token);
    let { serverItems, clientItems } = cartHooksExecutors.beforeSync({
      clientItems: getCartItems,
      serverItems: result,
    });

    if (resultCode === 200) {
      const differentPrices =
        serverItems?.length &&
        clientItems.every((c) => {
          return serverItems.some((s) => s.sku === c.sku && s.price !== (c.totals?.price || c.final_price));
        });

      if (
        (rootGetters['user/isLoggedIn'] && clientItems.some((i) => 'tier_prices' in i && i.tier_prices.length)) ||
        differentPrices
      ) {
        const skus = clientItems.map((i) => i.sku);
        let query = new SearchQuery();

        query = query.applyFilter({ key: 'sku', value: { in: skus } });

        const resp = await quickSearchByQuery({ query });

        if (resp.items && resp.items.length) {
          clientItems = clientItems.map((item) => {
            const found = resp.items.find((i) => i.id === item.id);

            return found ? { ...item, ...found } : item;
          });
        }

        state.cartItems = clientItems;
      }

      const diffLog = await dispatch('merge', {
        dryRun,
        serverItems,
        clientItems,
        forceClientState: shouldUpdateClientState,
        mergeQty,
      });

      cartHooksExecutors.afterSync(diffLog);

      return diffLog;
    }

    if (bypassCounter < config.queues.maxCartBypassAttempts) {
      Logger.log('Bypassing with guest cart' + bypassCounter, 'cart')();
      commit(types.CART_UPDATE_BYPASS_COUNTER, { counter: 1 });
      await dispatch('connect', { guestCart: true });
    }

    Logger.error(result, 'cart');
    cartHooksExecutors.afterSync(result);

    return createDiffLog();
  },

  async stockSync({ dispatch, commit }, stockTask) {
    const product = { sku: stockTask.product_sku };

    const cartItem = await dispatch('getItem', { product });

    if (!cartItem || stockTask.result.code === 'ENOTFOUND') {
      return;
    }

    if (!stockTask.result.is_in_stock) {
      if (!config.stock.allowOutOfStockInCart && !config.cart.synchronize) {
        Logger.log('Removing product from cart' + stockTask.product_sku, 'stock')();
        commit(types.CART_DEL_ITEM, { product: { sku: stockTask.product_sku } }, { root: true });

        return;
      }

      dispatch('updateItem', {
        product: {
          errors: {
            stock: Format.message('Out of the stock!'),
          },
          sku: stockTask.product_sku,
          is_in_stock: false,
        },
      });

      return;
    }

    dispatch('updateItem', {
      product: { info: { stock: Format.message('In stock!') }, sku: stockTask.product_sku, is_in_stock: true },
    });

    EventBus.$emit('cart-after-itemchanged', { item: cartItem });
  },

  async restore({ dispatch }, cartId: number) {
    const { result, resultCode } = await CartService.restoreCart(cartId);
    const diffLog = createDiffLog();

    if (resultCode === 200 && result.items?.length) {
      const skus: string[] = result.items.map((i: Record<string, any>) => i.sku);
      let productsQuery = new SearchQuery();

      productsQuery = productsQuery.applyFilter({ key: 'sku', value: { in: skus } });

      productsQuery = productsQuery
        .applyFilter({ key: 'visibility', value: { in: config.zento.queryFilters.related.visibility } })
        .applyFilter({ key: 'status', value: { in: config.zento.queryFilters.related.status } });

      if (config.zento.theme.category.listOutOfStockProducts === false) {
        productsQuery = productsQuery.applyFilter({ key: 'stock.is_in_stock', value: { eq: true } });
      }

      const response = await dispatch(
        'product/list',
        { query: productsQuery, updateState: false, prefetchGroupProducts: false },
        { root: true },
      );

      if (response && response.items?.length) {
        const result = await dispatch('addItems', { productsToAdd: response.items });

        if (result) {
          return result;
        }
      } else {
        diffLog.pushNotification(
          notifications.createNotification({
            type: 'error',
            message: Format.message('no_server_items', { skus: skus.join(', ') }),
          }),
        );

        return diffLog;
      }
    } else {
      diffLog.pushNotification(
        notifications.createNotification({
          type: 'error',
          message: result.errorMessage,
        }),
      );

      return diffLog;
    }
  },

  async syncFreeGift({ rootState, getters, state, dispatch, commit }) {
    const { result, resultCode } = await CartService.getItems(rootState.user.token);

    if (resultCode === 200) {
      const { getCartItems } = getters;
      const { serverItems, clientItems } = cartHooksExecutors.beforeSync({
        clientItems: getCartItems,
        serverItems: result,
      });
      const serverItemsSkus = serverItems?.map((s: Record<string, any>) => {
        return s.product_type === 'bundle' ? s.sku.split('-')[0] : s.sku;
      });
      const clientItemsSkus = clientItems?.map((c: Record<string, any>) => c.sku);
      const differentItemsSkus = [];

      if (serverItemsSkus && clientItemsSkus) {
        for (let i = 0; i < serverItemsSkus.length; i++) {
          if (clientItemsSkus.indexOf(serverItemsSkus[i]) === -1) {
            differentItemsSkus.push(serverItemsSkus[i]);
          }
        }
      }

      if (differentItemsSkus.length) {
        let query = new SearchQuery();
        let newItems = [];

        query = query.applyFilter({ key: 'sku', value: { in: differentItemsSkus } });
        const resp = await quickSearchByQuery({ query });

        if (resp.items && resp.items.length) {
          const serverItem = serverItems.find((s: Record<string, any>) => differentItemsSkus.includes(s.sku));
          newItems = resp.items.map((i) => ({ ...i, ...serverItem }));
        }

        state.cartItems = [...state.cartItems, ...newItems];
        commit(types.CART_LOAD_CART, state.cartItems);
      } else {
        if (serverItems.length !== clientItems.length) {
          const differentClientItemsSkus = [];

          for (let i = 0; i < clientItemsSkus.length; i++) {
            if (serverItemsSkus.indexOf(clientItemsSkus[i]) === -1) {
              differentClientItemsSkus.push(clientItemsSkus[i]);
            }
          }

          if (differentClientItemsSkus.length) {
            const clientItem = clientItems.find((c: Record<string, any>) => differentClientItemsSkus.includes(c.sku));

            if (clientItem) {
              await dispatch('removeItem', { product: clientItem });
            }
          }
        } else if (!serverItems?.length) {
          state.cartItems = [];
          commit(types.CART_LOAD_CART, state.cartItems);
        }
      }
    }
  },
};

export default synchronizeActions;
