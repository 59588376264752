import { Component, BaseComponent } from '@zento-lib/components';
import { getViewportData } from 'theme/stores/application-context/store';

import type { IViewportObserver } from './Observer.d';

/**
 * Viewport Observer
 *
 * Observer component which allows you to track viewport width, height and scale on each resize.
 * Client side tacking only, using resize event
 * On each viewport resize, it will update the state in application context store
 **/
@Component({})
export class ViewportObserver extends BaseComponent<IViewportObserver, unknown> {
  onResize() {
    this.$store.dispatch('application-context/updateViewport', getViewportData());
  }

  created() {
    this.onResize();
  }

  beforeMount() {
    if (!this.$isServer) {
      this.onResize = this.onResize.bind(this);
      window.addEventListener('resize', this.onResize);
    }
  }

  beforeDestroy() {
    if (!this.$isServer) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  render() {
    return null;
  }
}
