import { createModule } from '@vue-storefront/core/lib/module';

import { checkoutModule } from './store/checkout';
import { paymentModule } from './store/payment';
import { shippingModule } from './store/shipping';
import { beforeRegistration } from './hooks/beforeRegistration';
import { afterRegistration } from './hooks/afterRegistration';
import { KEY } from './key';

export { KEY } from './key';

export const Checkout = createModule({
  key: KEY,
  store: {
    modules: [
      { key: 'shipping', module: shippingModule },
      { key: 'payment', module: paymentModule },
      { key: 'checkout', module: checkoutModule },
    ],
  },
  beforeRegistration,
  afterRegistration,
});
