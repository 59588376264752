export const SN_CHECKOUT = 'checkout';
export const CHECKOUT_PLACE_ORDER = SN_CHECKOUT + '/PLACE_ORDER';
export const CHECKOUT_SAVE_PERSONAL_DETAILS = SN_CHECKOUT + '/SAVE_PERSONAL_DETAILS';
export const CHECKOUT_SAVE_SHIPPING_DETAILS = SN_CHECKOUT + '/SAVE_SHIPPING_DETAILS';
export const CHECKOUT_SAVE_PAYMENT_DETAILS = SN_CHECKOUT + '/SAVE_PAYMENT_DETAILS';
export const CHECKOUT_LOAD_PERSONAL_DETAILS = SN_CHECKOUT + '/LOAD_PERSONAL_DETAILS';
export const CHECKOUT_LOAD_SHIPPING_DETAILS = SN_CHECKOUT + '/LOAD_SHIPPING_DETAILS';
export const CHECKOUT_LOAD_PAYMENT_DETAILS = SN_CHECKOUT + '/LOAD_PAYMENT_DETAILS';
export const CHECKOUT_UPDATE_PROP_VALUE = SN_CHECKOUT + '/UPDATE_PROP_VALUE';
export const CHECKOUT_DROP_PASSWORD = SN_CHECKOUT + '/DROP_PASSWORD';
export const CHECKOUT_SET_THANKYOU = SN_CHECKOUT + '/SET_THANKYOU';
export const CHECKOUT_SET_MODIFIED_AT = SN_CHECKOUT + '/SET_MODIFIEDAT';
export const CHECKOUT_ONLINE_PAYMENT = SN_CHECKOUT + '/SET_ONLINE_PAYMENT';
