import { Format } from '@zento-lib/components/i18n/inline';
import { ActionTree } from 'vuex';
import rootStore from '@vue-storefront/core/store';
import RootState from '@vue-storefront/core/types/RootState';
import { Logger } from '@vue-storefront/core/lib/logger';

import WishlistState from '../types/WishlistState';
import { cacheStorage } from '../cache';

import * as types from './mutation-types';

const actions: ActionTree<WishlistState, RootState> = {
  clear(context) {
    context.commit(types.WISH_LOAD_WISH, []);
  },
  load({ commit, getters }, force = false) {
    if (!force && getters.isWishlistLoaded) return;
    commit(types.SET_WISHLIST_LOADED);
    cacheStorage.getItem('current-wishlist', (err, storedItems) => {
      if (err) throw new Error(err);
      commit(types.WISH_LOAD_WISH, storedItems);
      Logger.info('Wishlist state loaded from browser cache. ', 'cache', storedItems)();
    });
  },
  addItem({ commit }, product) {
    commit(types.WISH_ADD_ITEM, { product });
    rootStore.dispatch('notification/spawnNotification', {
      type: 'success',
      message: Format.message('wishlist_add_message'),
      action1: { label: Format.message('notification_action') },
    });
  },
  removeItem({ commit }, product) {
    commit(types.WISH_DEL_ITEM, { product });
    rootStore.dispatch('notification/spawnNotification', {
      type: 'success',
      message: Format.message('wishlist_remove_message'),
      action1: { label: Format.message('notification_action') },
    });
  },
};

export default actions;
