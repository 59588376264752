import { IPersonalDetails, IShipment, IPayment, IReviewOrder, CheckoutSection } from '../types/CheckoutSteps';

import * as types from './mutation-types';

const actions = {
  setSections({ commit }, section: CheckoutSection) {
    commit(types.SECTION, section);
  },

  initPersonalDetails(context) {
    // Is the user logged in?
    if (context.rootState.user.current) {
      context.dispatch('updatePersonalDetails', {
        valid: true,
      } as IPersonalDetails);
    }
  },

  updatePersonalDetails({ commit, dispatch }, personalDetails: IPersonalDetails) {
    commit(types.PERSONAL_CHECKOUT, { valid: personalDetails.valid });

    if (personalDetails.valid) {
      dispatch('checkout/savePersonalDetails', personalDetails.data, { root: true });
    }
  },

  updateShipment({ commit, dispatch }, shipment: IShipment) {
    commit(types.SHIPMENT_CHECKOUT, { valid: shipment.valid });

    if (shipment.valid) {
      dispatch('checkout/saveShippingDetails', shipment.data, { root: true });
    }
  },

  updatePayment({ commit, dispatch }, payment: IPayment) {
    commit(types.PAYMENT_CHECKOUT, { valid: payment.valid });

    if (payment.valid) {
      dispatch('checkout/savePaymentDetails', payment.data, { root: true });
    }
  },

  updateReview({ commit }, review: IReviewOrder) {
    commit(types.REVIEW_CHECKOUT, review);
  },
};

export default actions;
