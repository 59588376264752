import { ActionTree } from 'vuex';
import config from 'config';
import type RootState from '@vue-storefront/core/types/RootState';
import rootStore from '@vue-storefront/core/store';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore/adjustMultistoreApiUrl';
import { processURLAddress } from '@vue-storefront/core/helpers';
import { Logger } from '@vue-storefront/core/lib/logger';
import { Format } from '@zento-lib/components/i18n/inline';

import type { ExtendAddress } from '../types/Address';
import type { ExtendAddressState } from '../types/AddressState';

const actions: ActionTree<ExtendAddressState, RootState> = {
  async addAddress({ rootState }, addressData: ExtendAddress) {
    const currentUser = Object.assign({}, rootState.user.current);

    if (rootState.user.token && currentUser) {
      const existAddress = currentUser.addresses.length ? currentUser.addresses : [];

      if (existAddress.length > 0) {
        for (let i = 0; i < existAddress.length; i++) {
          if (addressData.default_billing) {
            existAddress[i].default_billing = false;
          }

          if (addressData.default_shipping) {
            existAddress[i].default_shipping = false;
          }
        }
      }

      existAddress.push({
        city: addressData.city,
        country_id: addressData.country ?? addressData.country_id,
        firstname: addressData.firstname,
        lastname: addressData.lastname,
        postcode: addressData.postcode,
        region: { region: addressData.region },
        region_id: 0,
        street: Array.isArray(addressData.street)
          ? [addressData.street[0], addressData.street[1] ?? '']
          : [addressData.street, addressData.house ?? ''],
        telephone: addressData.phone ?? addressData.telephone,
        company: addressData.company,
        vat_id: addressData.taxId,
        default_billing: addressData.default_billing,
        default_shipping: addressData.default_shipping,
      });

      const customerData = {
        customer: {
          email: currentUser.email,
          firstname: currentUser.firstname,
          lastname: currentUser.lastname,
          website_id: currentUser.website_id,
          store_id: currentUser.store_id,
          addresses: existAddress,
        },
      };

      EventBus.$emit('notification-progress-start', Format.message('save_address_notif_progress'));

      try {
        let url = config.zento.extendaddress.create_endpoint.replace('{{customerId}}', currentUser.id);

        if (config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url);
        }

        await fetch(processURLAddress(url), {
          method: 'PUT',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: rootState.user.token,
          },
          body: JSON.stringify({ data: customerData }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            EventBus.$emit('notification-progress-stop');

            if (data.code === 200) {
              await rootStore.dispatch('user/me', { useCache: false }, { root: true });

              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: Format.message('address_save_success'),
                action1: { label: Format.message('notification_action') },
              });
            } else {
              const message = Array.isArray(data.result)
                ? data.result[0]?.dataPath + ' should match pattern. Please try again.'
                : data.result;

              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: Format.message(message),
                action1: { label: Format.message('notification_action') },
              });
              console.error(data);
            }
          });
      } catch (e) {
        EventBus.$emit('notification-progress-stop');
        Logger.error('Something went wrong. Try again in a few seconds.' + e, 'extend-address')();
      }
    } else {
      Logger.error('You must be logged in!', 'extend-address')();
    }
  },

  async updateAddress({ rootState }, addressData) {
    addressData = {
      ...addressData,
      newAddress: {
        ...addressData.newAddress,
      },
    };

    if (rootState.user.token && addressData.currentAddressId) {
      const currentUser = Object.assign({}, rootState.user.current);
      const existAddresses = currentUser.addresses.length ? currentUser.addresses : [];
      let index: number;

      for (let i = 0; i < existAddresses.length; i++) {
        if (addressData.newAddress.default_billing) {
          existAddresses[i].default_billing = false;
        }

        if (addressData.newAddress.default_shipping) {
          existAddresses[i].default_shipping = false;
        }

        if (existAddresses[i].id.toString() === addressData.currentAddressId.toString()) {
          index = i;
        }
      }

      if (index >= 0) {
        existAddresses[index].firstname = addressData.newAddress.firstname;
        existAddresses[index].lastname = addressData.newAddress.lastname;
        existAddresses[index].street = Array.isArray(addressData.newAddress.street)
          ? [addressData.newAddress.street[0], addressData.newAddress.street[1] ?? '']
          : [addressData.newAddress.street, addressData.newAddress.house ?? ''];
        existAddresses[index].city = addressData.newAddress.city;
        existAddresses[index].region = {
          region:
            addressData.newAddress.region && typeof addressData.newAddress.region === 'object'
              ? addressData.newAddress.region.region
              : addressData.newAddress.region
              ? addressData.newAddress.region
              : null,
        };
        existAddresses[index].country_id = addressData.newAddress.country ?? addressData.newAddress.country_id;
        existAddresses[index].postcode = addressData.newAddress.postcode;
        existAddresses[index].telephone = addressData.newAddress.telephone
          ? addressData.newAddress.telephone
          : addressData.newAddress.phone
          ? addressData.newAddress.phone
          : '';
        existAddresses[index].company = addressData.newAddress.company;
        existAddresses[index].vat_id = addressData.newAddress.taxId;
        existAddresses[index].default_billing = addressData.newAddress.default_billing;
        existAddresses[index].default_shipping = addressData.newAddress.default_shipping;
      }

      const customerData = {
        customer: {
          email: currentUser.email,
          firstname: currentUser.firstname,
          lastname: currentUser.lastname,
          website_id: currentUser.website_id,
          store_id: currentUser.store_id,
          addresses: existAddresses,
        },
      };

      EventBus.$emit('notification-progress-start', Format.message('update_address_notif_progress'));

      try {
        let url = config.zento.extendaddress.create_endpoint.replace('{{customerId}}', currentUser.id);

        if (config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url);
        }

        await fetch(processURLAddress(url), {
          method: 'PUT',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: rootState.user.token,
          },
          body: JSON.stringify({ data: customerData }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            EventBus.$emit('notification-progress-stop');

            if (data.code === 200) {
              await rootStore.dispatch('user/me', { useCache: false }, { root: true });

              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: Format.message('address_update_success'),
                action1: { label: Format.message('notification_action') },
              });
            } else {
              const message = Array.isArray(data.result)
                ? data.result[0]?.dataPath + ' should match pattern. Please try again.'
                : data.result;

              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: Format.message(message),
                action1: { label: Format.message('notification_action') },
              });
              console.error(data);
            }
          });
      } catch (e) {
        EventBus.$emit('notification-progress-stop');
        Logger.error('Something went wrong. Try again in a few seconds.' + e, 'extend-address')();
      }
    } else {
      Logger.error('Address Id missing!', 'extend-address')();
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async removeAddress({ commit, rootState }, addressId) {
    if (rootState.user.token && addressId) {
      EventBus.$emit('notification-progress-start', Format.message('remove_address_notif_progress'));

      try {
        let url = config.zento.extendaddress.remove_endpoint.replace('{{addressId}}', addressId);

        if (config.storeViews.multistore) {
          url = adjustMultistoreApiUrl(url);
        }

        await fetch(processURLAddress(url), {
          method: 'DELETE',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: rootState.user.token,
          },
          body: JSON.stringify({ data: addressId }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            EventBus.$emit('notification-progress-stop');

            if (data.code === 200) {
              await rootStore.dispatch('user/me', { useCache: false }, { root: true });

              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: Format.message('address_delete_success'),
                action1: { label: Format.message('notification_action') },
              });
            } else {
              const message = Array.isArray(data.result)
                ? data.result[0]?.dataPath + ' should match pattern. Please try again.'
                : data.result;

              rootStore.dispatch('notification/spawnNotification', {
                type: 'success',
                message: Format.message(message),
                action1: { label: Format.message('notification_action') },
              });
              console.error(data);
            }
          });
      } catch (e) {
        EventBus.$emit('notification-progress-stop');
        Logger.error('Something went wrong. Try again in a few seconds.' + e, 'extend-address')();
      }
    } else {
      Logger.error('Address Id missing!', 'extend-address')();
    }
  },
};

export default actions;
