// This function will be executed before entering each route.
// It's important to have 'next()'. It enables navigation to new route.
// See https://router.vuejs.org/guide/advanced/navigation-guards.html#global-guards
import type { Route } from 'vue-router'
import config from 'config'
import { I18n } from '@vue-storefront/i18n'
import store from '@vue-storefront/core/store'
import { Logger } from '@vue-storefront/core/lib/logger'
import { processDynamicRoute, normalizeUrlPath } from '../helpers'
import { isServer } from '@vue-storefront/core/helpers'
import { currentStoreView, localizedRoute, prepareStoreView } from '@vue-storefront/core/lib/multistore'
import type { LocalizedRoute } from '@vue-storefront/core/lib/types'
import Vue from 'vue'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import { routerHelper } from '@vue-storefront/core/helpers'
import storeCodeFromRoute from '@vue-storefront/core/lib/storeCodeFromRoute'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export const UrlDispatchMapper = async (to) => {
  const routeData = await store.dispatch('url/mapUrl', { url: to.path, query: to.query })
  return Object.assign({}, to, routeData)
}

function isDynamicDispatchd(r: Route) {
  return r && r.matched.length > 0 && r.name && r.name.startsWith('urldispatcher')
}

export async function beforeEach (to: Route, from: Route, next) {
  const routerManager = RouterManager.getInstance();
  if (routerManager.isRouteProcessing()) {
    await routerManager.getRouteLockPromise()
    next()
    return
  }
  routerManager.lockRoute()

  const path = normalizeUrlPath(to.path)
  const hasRouteParams = to.hasOwnProperty('params') && Object.values(to.params).length > 0
  const isPreviouslyDispatchedDynamicRoute = isDynamicDispatchd(to);

  if (config.storeViews.multistore) {
    // Grab current route storeCode from the route itself
    const storeCode = storeCodeFromRoute(to) || config.defaultStoreCode;
    const target = config.storeViews[storeCode];

    if (!target || !I18n.getInstance().hasLoaded(target.i18n?.defaultLocale)) {
      await prepareStoreView(storeCode);
    } else {
      if (target.storeCode !== store.state.storeView.storeCode) {
        await store.dispatch('setStoreView', { ...target });
      }
    }

    I18n.getInstance().setI18nLanguage(target.i18n?.defaultLocale);
  }

  if (!to.matched.length || (isPreviouslyDispatchedDynamicRoute && !hasRouteParams)) {
    UrlDispatchMapper(to).then((routeData) => {
      if (routeData) {
        let dynamicRoutes: LocalizedRoute[] = processDynamicRoute(routeData, path, !isPreviouslyDispatchedDynamicRoute)
        if (dynamicRoutes && dynamicRoutes.length > 0) {
          next({
            ...dynamicRoutes[0],
            replace: routerHelper.popStateDetected || dynamicRoutes[0].fullPath === from.fullPath
          })

          if (!routerHelper.popStateDetected && routeData.name === 'category') {
            store.dispatch('category/resetFilters');
            EventBus.$emit('filter-reset');
          }
        } else {
          Logger.error('Route not found ' + routeData['name'], 'dispatcher')()
          next(localizedRoute('/page-not-found', currentStoreView().storeCode))
        }
      } else {
        Logger.error('No mapping found for ' + path, 'dispatcher')()
        next(localizedRoute('/page-not-found', currentStoreView().storeCode))
      }
    }).catch(e => {
      Logger.error(e, 'dispatcher')()
      if (!isServer) {
        next(localizedRoute('/page-not-found', currentStoreView().storeCode))
      } else {
        const storeCode = currentStoreView().storeCode
        Vue.prototype.$ssrRequestContext.server.response.redirect((storeCode !== '' ? ('/' + storeCode) : '') + '/page-not-found') // TODO: Refactor this one after @filrak will give us a way to access ServerContext from Modules directly :-)
        // ps. we can't use the next() call here as it's not doing the real redirect in SSR mode (just processing different component without changing the URL and that causes the CSR / SSR DOM mismatch while hydrating)
      }
    }).finally(() => {
      routerHelper.popStateDetected = false
      routerManager.unlockRoute()
    })
  } else {
    if (
      to.fullPath.includes('search') &&
      to.params?.term !== '' &&
      store.getters['category/getCurrentCategoryProductQuery'].current !== 0
    ) {
      store.dispatch('category/resetFilters');
      // EventBus.$emit('filter-reset');
    }

    next()
    routerManager.unlockRoute()
    routerHelper.popStateDetected = false
  }
}
