import { BaseComponent, Component } from '../';

@Component({})
export class ServiceWorkerUpdater extends BaseComponent<any, unknown> {
  private static T = {
    pageReloadRequired: 'sw_page_reload_required',
    doNotReload: 'sw_page_do_not_reload',
    doReload: 'sw_page_do_reload',
  };

  private registration: ServiceWorkerRegistration | null;

  private refreshing = false;

  render() {
    return null;
  }

  async handleSWActivation() {
    // Listen for service workers awaiting activation
    if ('serviceWorker' in navigator && navigator.serviceWorker) {
      // On controller change refresh the page once
      navigator.serviceWorker.addEventListener('controllerchange', this.reloadPage);

      // Listen for waiting ServiceWorker registrations
      // const sw = this.registration;
      const sw = await navigator.serviceWorker.ready.then((r) => r);

      if (sw) {
        this.registration = sw;

        if (sw.waiting) {
          // Prompt the user for a refresh directly if the service worker is already in waiting stage
          this.requestUserConsent();
        }

        if (sw.installing) {
          // Await for the service worker to be installed before prompting the user
          this.awaitSWStateChange();
        }

        // Await for service worker updates
        sw.addEventListener('updatefound', this.awaitSWStateChange);
      }
    }
  }

  async reloadPage() {
    debugger;
    if (!this.refreshing) {
      this.refreshing = true;
      window.location.reload();
    }
  }

  mounted() {
    this.awaitSWStateChange = this.awaitSWStateChange.bind(this);
    this.requestUserConsent = this.requestUserConsent.bind(this);
    this.reloadPage = this.reloadPage.bind(this);

    this.handleSWActivation();
  }

  /**
   * Await service worker installation to complete before triggering a user prompt
   */
  private async awaitSWStateChange() {
    const requestUserConsent = this.requestUserConsent;

    const sw = this.registration;

    if (sw) {
      if (sw.installing) {
        sw.installing.addEventListener('statechange', function onStateChange() {
          // Await for the installation to complete
          if (this.state === 'installed') {
            // Prompt for user consent once the new service worker was installed
            requestUserConsent();
          }
        });
      } else if (sw.waiting) {
        // Prompt the user for a refresh directly if the service worker is already in waiting stage
        requestUserConsent();
      }
    }
  }

  private requestUserConsent() {
    this.$store.dispatch(
      'notification/spawnNotification',
      {
        type: 'warning',
        message: this.getTranslation({
          id: ServiceWorkerUpdater.T.pageReloadRequired,
        }),
        // TODO: We don't want users to NOT reload SW
        action1: {
          label: this.getTranslation({ id: ServiceWorkerUpdater.T.doNotReload }),
          action: 'close',
        },
        action2: {
          label: this.getTranslation({ id: ServiceWorkerUpdater.T.doReload }),
          action: async () => {
            // Based on user consent refresh all tabs controlled by the new service worker
            const sw = this.registration;

            if (sw) {
              sw.waiting.postMessage('skipWaiting');
              setTimeout(this.reloadPage, 1000);
            }
          },
        },
        hasNoTimeout: true,
      },
      { root: true },
    );
  }
}
