const proxies = new WeakSet<any>();

/**
 * Checks if received target is a known proxy
 */
export function isProxy<T>(target: T) {
  return proxies.has(target);
}

/**
 * Given a target object and a handler descriptor, proxies the received target by specified handler rules
 *
 * When dealing with pre-proxied targets, the target is returned as is, ensuring no recursive proxying
 */
export function proxify<T extends object>(target: T, handler: ProxyHandler<any>): T {
  if (!isProxy(target)) {
    const proxy = new Proxy(target, handler);

    proxies.add(proxy);

    return proxy as T;
  }

  // Don't re-proxy existing proxies
  return target as T;
}
