import { createModule } from '@vue-storefront/core/lib/module';

import { cartStore } from './store';
import { beforeRegistration } from './hooks/beforeRegistration';
import { afterRegistration } from './hooks/afterRegistration';
import { KEY } from './key';

export { KEY } from './key';

export const Cart = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: cartStore }] },
  beforeRegistration,
  afterRegistration,
});
