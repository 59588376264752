import { Logger } from '@vue-storefront/core/lib/logger';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

import { CartService } from '../../data-resolver/CartService';
import { preparePaymentMethodsToSync, createOrderData, createShippingInfoData } from '../../helpers';
import * as types from '../mutation-types';
import type { PaymentMethod } from '../../types/PaymentMethod';

const methodsActions = {
  async pullMethods({ getters, dispatch }, { forceServerSync }) {
    if (getters.isTotalsSyncRequired || forceServerSync) {
      await dispatch('syncShippingMethods', { forceServerSync });
      await dispatch('syncPaymentMethods', { forceServerSync });
    } else {
      Logger.debug('Skipping payment & shipping methods update as cart has not been changed', 'cart')();
    }
  },

  async setDefaultCheckoutMethods({ getters, rootGetters, commit }) {
    if (!getters.getShippingMethodCode) {
      commit(
        types.CART_UPD_SHIPPING,
        rootGetters['shipping/shippingMethods'].find((item) => item.default),
      );
    }

    if (!getters.getPaymentMethodCode) {
      commit(
        types.CART_UPD_PAYMENT,
        rootGetters['payment/paymentMethods'].find((item) => item.default),
      );
    }
  },

  async syncPaymentMethods({ rootState, getters, rootGetters, dispatch }, { forceServerSync = false }) {
    if (getters.canUpdateMethods && (getters.isTotalsSyncRequired || forceServerSync)) {
      Logger.debug('Refreshing payment methods', 'cart')();
      let backendPaymentMethods: PaymentMethod[];
      const paymentDetails = rootGetters['checkout/getPaymentDetails'];

      if (paymentDetails.country) {
        // use shipping info endpoint to get payment methods using billing address
        const shippingMethodsData = createOrderData({
          shippingDetails: rootGetters['checkout/getShippingDetails'],
          shippingMethods: rootGetters['shipping/shippingMethods'],
          paymentMethods: rootGetters['payment/paymentMethods'],
          paymentDetails: paymentDetails,
        });

        if (shippingMethodsData.country) {
          const { result } = await CartService.setShippingInfo(
            createShippingInfoData(shippingMethodsData),
            rootState.user.token,
          );
          backendPaymentMethods = result.payment_methods || [];
        }
      }

      if (!backendPaymentMethods || backendPaymentMethods.length === 0) {
        const { result } = await CartService.getPaymentMethods(rootState.user.token);
        backendPaymentMethods = result;
      }

      const { uniqueBackendMethods, paymentMethods } = preparePaymentMethodsToSync(
        backendPaymentMethods,
        rootGetters['payment/paymentMethods'].filter((itm) => typeof itm !== 'object' || !itm.is_server_method),
      );

      await dispatch('payment/replaceMethods', paymentMethods, { root: true });
      EventBus.$emit('set-unique-payment-methods', uniqueBackendMethods);
    } else {
      Logger.debug('Payment methods does not need to be updated', 'cart')();
    }
  },

  async updateShippingMethods({ dispatch }, { shippingMethods }) {
    if (typeof shippingMethods === 'string' || shippingMethods instanceof String) {
      Logger.debug(shippingMethods, 'cart')();
    } else {
      if (shippingMethods.length > 0) {
        const newShippingMethods = shippingMethods.map((method) => ({ ...method, is_server_method: true }));

        await dispatch('shipping/replaceMethods', newShippingMethods, { root: true });
      }
    }
  },

  async syncShippingMethods({ rootState, getters, rootGetters, dispatch }, { forceServerSync = false }) {
    if (getters.canUpdateMethods && (getters.isTotalsSyncRequired || forceServerSync)) {
      const storeView = currentStoreView();
      Logger.debug('Refreshing shipping methods', 'cart')();
      const shippingDetails = rootGetters['checkout/getShippingDetails'];
      let country = shippingDetails.country;

      if (!country) country = storeView.i18n.defaultCountry;

      // build address data with what we have
      const address = shippingDetails
        ? {
            region: shippingDetails.state,
            region_id: shippingDetails.region_id ? shippingDetails.region_id : 0,
            country_id: country,
            street: [shippingDetails.streetAddress1, shippingDetails.streetAddress2],
            postcode: shippingDetails.zipCode,
            city: shippingDetails.city,
            region_code: shippingDetails.region_code ? shippingDetails.region_code : '',
          }
        : { country_id: storeView.tax.defaultCountry };
      const { result } = await CartService.getShippingMethods(address, rootState.user.token);

      await dispatch('updateShippingMethods', { shippingMethods: result });
    } else {
      Logger.debug('Shipping methods does not need to be updated', 'cart')();
    }
  },
};

export default methodsActions;
