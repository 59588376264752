import { createModule } from '@vue-storefront/core/lib/module';

import { productModule } from './store/product';
import { attributeModule } from './store/attribute';
import { stockModule } from './store/stock';
import { taxModule } from './store/tax';
import { categoryModule } from './store/category';
import { beforeRegistration } from './hooks/beforeRegistration';
import { KEY } from './key';

export { KEY } from './key';

export const Catalog = createModule({
  key: KEY,
  store: {
    modules: [
      { key: 'product', module: productModule },
      { key: 'attribute', module: attributeModule },
      { key: 'stock', module: stockModule },
      { key: 'tax', module: taxModule },
      { key: 'category', module: categoryModule },
    ],
  },
  beforeRegistration,
});
