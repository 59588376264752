import Vue from 'vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

import type { IZentoConfig } from '../../../common/stores';
import { Format } from '../i18n';

import './hooks';
import type { I18nMessage } from './types';

export type IComponentOptions = {
  children?: any;
  exposed?: any;
  props?: any;
};

type InnerE = {
  // Definition of all build in additions to Vue instance
  localizedRoute: typeof localizedRoute;

  /**
   * VSF global configuration object
   */
  $config: IZentoConfig;
};

export interface IComponentBaseAttributes {
  key?: number | string;
  ref?: string | (() => void);
  slot?: string;
  style?: string | Record<string, string | number> | Array<Record<string, string | number>>;
  class?: string | Record<string, boolean> | Array<string | Record<string, boolean>>;
}

export class BaseComponent<ComponentOptions extends IComponentOptions, E extends { [p: string]: any }> extends Vue {
  /**
   * Checks if provided instance is an instance of specified base class
   */
  public static instanceOf(instance: Vue, baseClass: any) {
    return instance.$options.render === baseClass.options.render;
  }

  private _selfLocator: string;

  private _locator: string;

  private _configuration: ComponentOptions;

  // TODO: Implement the base component with support for configuration grabbing and props forcing
  // TODO: Handle dynamic meta injections by grabbing meta information from config
  // TODO: Implement broadcasting support without having to externalize the locator

  /**
   * Configuration getter for all component configuration options
   *
   * When the component uses props with the same name as the external configuration
   * the external configuration ones will be enforced if no particular value is defined
   * for the current component
   */
  public get configuration(): ComponentOptions {
    if (!this._configuration) {
      const l = this.locator.split('.');
      const currentRoute = (this.$router as any).history.current;
      let originalPath = currentRoute.path; // Default to unresolved route path

      if (currentRoute.matched[0]) {
        // Matched concrete route case
        const r = currentRoute.matched[0];

        if (r.meta && r.meta.originalPath) {
          // Multistore mapped staticly defined routes will use the template route path
          originalPath = r.meta.originalPath;
        } else if (r.path) {
          originalPath = r.path;
        }
      } else {
        if (currentRoute.meta && currentRoute.meta.originalPath) {
          // Multistore mapped staticly defined routes will use the template route path
          originalPath = currentRoute.meta.originalPath;
        }
      }

      let routeConfig = this.extended.$config.zento.routes.find((r) => r.path === originalPath);

      if (!routeConfig) {
        if (currentRoute.params) {
          let routePath = '';

          if ('parentSku' in currentRoute.params) {
            // Temporary select /p/** as product page configuration source
            routePath = '/p/:parentSku/:slug/:childSku';
          } else if ('slug' in currentRoute.params) {
            // Temporary select /c/** as category page configuration source
            routePath = '/c/:slug';
          } else if ('term' in currentRoute.params) {
            routePath = '/search/:term';
          } else if ('unmatchedterm' in currentRoute.params) {
            routePath = '/no-results/:unmatchedterm';
          }

          routeConfig = this.extended.$config.zento.routes.find((r) => r.path === routePath);
        }
      }

      let c: ComponentOptions;

      if (routeConfig) {
        c = (routeConfig.config || {}) as ComponentOptions;

        // Lookup the route configuration for current component's configuration
        for (const ll of l) {
          if (c.children && c.children[ll]) {
            c = c.children[ll];
          } else {
            // No configuration found
            break;
          }
        }
      }

      this._configuration = {
        children: {
          ...(c.children ? c.children : {}),
        },
        exposed: {
          ...(c.exposed ? c.exposed : {}),
        },
        props: {
          ...(c.props ? c.props : {}),
        },
      } as any;
    }

    return this._configuration;
  }

  public get variation() {
    const implementationClass = this.constructor as any;
    const variationType = this.variationType;

    if (implementationClass && implementationClass.V && variationType) {
      return implementationClass.V[variationType];
    }

    return null;
  }

  public get variationType() {
    const props: Record<string, any> = this.mergedConfiguration;

    return props.variation;
  }

  public get mergedConfiguration(): ComponentOptions {
    const config = this.configuration;

    return {
      ...config.props,
      ...config.exposed,
    };
  }

  /**
   * Current component's locator segment
   */
  public get selfLocator(): string {
    if (!this._selfLocator) {
      this._selfLocator = this.$vnode.tag.split('-').slice(3).join('-');
    }

    return this._selfLocator;
  }

  /**
   * Computes the current component's locator in path (relative to Application)
   */
  public get locator(): string {
    if (!this._locator) {
      const locator: string[] = [];
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let p: BaseComponent<any, unknown> = this;

      while (p) {
        if (p && 'selfLocator' in p) {
          locator.push(p.selfLocator);
        }

        p = p.$parent as any;
      }

      this._locator = locator.reverse().join('.');
    }

    return this._locator;
  }

  /**
   * Allows grabbing the current instance with extended props added to the type
   *
   * Useful when dealing with functionality inherited from Vue mixins.
   * Define the used interface of the inherited mixins and use the extended instance to
   * access the methods/properties.
   */
  get extended() {
    return (this as unknown) as Vue & E & InnerE;
  }

  /**
   * Public JSX component API keeper
   */
  propTypes: ComponentOptions['props'] & IComponentBaseAttributes & Record<any, any>;

  /**
   * Allows broadcasting a message using the bus
   *
   * Useful for tracking UI only interactions directly.
   * Data related tracking should be done from the level of their respective stores actions and mutations.
   */
  broadcast(event: string, ...args: any[]): void {
    // TODO: Determine how events name could be typed
    this.$bus.$emit(event, ...args);
  }

  /**
   * Allows listening for broadcasted messages using the bus
   */
  onBroadcast(event: string, handler: (...args: any[]) => void) {
    this.$bus.$on(event, handler);
  }

  /**
   * Allows removing listeners for broadcasted messages using the bus
   */
  offBroadcast(event: string, handler?: (...args: any[]) => void) {
    this.$bus.$off(event, handler);
  }

  // Translates the text
  getTranslation(message: I18nMessage) {
    if (typeof message === 'object') {
      return Format.message(message.id, message.data || {});
    }

    return typeof message === 'string' ? message : '';
  }
}

/**
 * Vue components base component
 *
 * To be extended by all components in our code base
 */
// export const BaseComponent = VuePropertyDecoratorMixins(Base);

export type VueClass<V> = {
  new (...args: any[]): V & Vue;
} & typeof Vue;

/**
 * Extends the base class with specified mixin(s)
 */
export function Mixins<T>(...mixins: Array<VueClass<Vue>>): VueClass<T> {
  return BaseComponent.extend({ mixins });
}
