export const SN_PRODUCT = 'product';
export const CATALOG_UPD_PRODUCTS = SN_PRODUCT + '/UPD_PRODUCTS';
export const CATALOG_UPD_RELATED = SN_PRODUCT + '/UPD_RELATED';
export const CATALOG_UPD_SEARCH_QUERY = SN_PRODUCT + '/UPD_SEARCH_QUERY';
export const CATALOG_SET_PRODUCT_CURRENT = SN_PRODUCT + '/SET_PRODUCT_CURRENT';
export const CATALOG_SET_PRODUCT_ORIGINAL = SN_PRODUCT + '/SET_PRODUCT_ORIGINAL';
export const CATALOG_RESET_PRODUCT = SN_PRODUCT + '/RESET_PRODUCT_ORIGINAL';
export const CATALOG_SET_PRODUCT_PARENT = SN_PRODUCT + '/SET_PARENT';
export const CATALOG_UPD_CUSTOM_OPTION = SN_PRODUCT + '/SET_CUSTOM_OPTION';
export const CATALOG_UPD_BUNDLE_OPTION = SN_PRODUCT + '/SET_BUNDLE_OPTION';
export const CATALOG_ADD_CUSTOM_OPTION_VALIDATOR = SN_PRODUCT + '/ADD_CUSTOM_OPTION_VALIDATOR';
export const CATALOG_UPD_GALLERY = SN_PRODUCT + '/SET_GALLERY';
export const CATALOG_SET_BREADCRUMBS = SN_PRODUCT + '/SET_BREADCRUMBS';
