import rootStore from '@vue-storefront/core/store'
import { execute as taskExecute, _prepareTask } from './task'
import { isServer } from '@vue-storefront/core/helpers/isServer'

/** Runs given task. If user is offline request will fail */
export async function execute (task): Promise<any> { // not offline task
  task = _prepareTask(task)
  return new Promise((resolve, reject) => {
    if (isServer) {
      taskExecute(task, null, null).then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    } else {
      const currentUserToken = rootStore.getters['user/getUserToken']
      const currentCartToken = rootStore.getters['cart/getCartToken']
      taskExecute(task, currentUserToken, currentCartToken).then((result) => {
        resolve(result)
      }).catch(err => {
        reject(err)
      })
    }
  })
}
