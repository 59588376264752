import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import ITaxState from '../../types/TaxState';

import actions from './actions';
import mutations from './mutations';

export const taxModule: Module<ITaxState, RootState> = {
  namespaced: true,
  state: {
    rules: [],
  },
  actions,
  mutations,
};
