import { Module } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';

import CompareState from '../types/CompareState';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const module: Module<CompareState, RootState> = {
  namespaced: true,
  state: {
    loaded: false,
    items: [],
  },
  getters,
  actions,
  mutations,
};
