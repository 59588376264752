import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import CheckoutState from '../../types/CheckoutState';

const getters: GetterTree<CheckoutState, RootState> = {
  getShippingDetails: (state) => state.shippingDetails,
  getPersonalDetails: (state) => state.personalDetails,
  getPaymentDetails: (state) => state.paymentDetails,
  isThankYouPage: (state) => state.isThankYouPage,
  isOnlinePayment: (state) => state.isOnlinePayment,
  getModifiedAt: (state) => state.modifiedAt,
  /**
   * TODO: maybe refactor because it's timestamped for now;
   * If user is in the checkout longer than 30min and will log in then the cart will be synced anyway
   */
  isUserInCheckout: (state) => Date.now() - state.modifiedAt <= 60 * 30 * 1000,
};

export default getters;
