import { Module } from 'vuex';

import type { CartState } from '../types/CartState';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const module: Module<CartState, any> = {
  namespaced: true,
  state: {
    editMode: {
      product: null,
      selectedOptions: {},
      qty: 0,
    },
  } as CartState,
  getters,
  actions,
  mutations,
};
