import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import CheckoutState from '../../types/CheckoutState';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const checkoutModule: Module<CheckoutState, RootState> = {
  namespaced: true,
  state: {
    order: {},
    personalDetails: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      password: '',
      createAccount: false,
    },
    shippingDetails: {
      firstName: '',
      lastName: '',
      email: '',
      country: '',
      countryName: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      state: '',
      stateName: '',
      region_id: 0,
      zipCode: '',
      phoneNumber: '',
      shippingMethod: '',
      shippingMethodName: '',
      countryAutocomplete: false,
      locationAutocomplete: '',
    },
    paymentDetails: {
      firstName: '',
      lastName: '',
      company: '',
      country: '',
      countryName: '',
      streetAddress: '',
      apartmentNumber: '',
      city: '',
      state: '',
      stateName: '',
      region_id: 0,
      zipCode: '',
      phoneNumber: '',
      taxId: '',
      paymentMethod: '',
      paymentMethodName: '',
      paymentMethodAdditional: {},
      countryAutocomplete: false,
      locationAutocomplete: '',
      company_reg_no: '',
      bank_name: '',
      bank_iban: '',
      generateInvoice: false,
    },
    isThankYouPage: false,
    isOnlinePayment: false,
    modifiedAt: 0,
  },
  getters,
  actions,
  mutations,
};
