import config from 'config';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore/adjustMultistoreApiUrl';
import Task from '@vue-storefront/core/lib/sync/types/Task';

import type { CartItem } from '../types/CartItem';

import type { ICartService } from './types/DataResolver';

const processURLAddress = (url = '') => {
  if (url.startsWith('/')) {
    return `${config.api.url}${url}`;
  }

  return url;
};

const processLocalizedURLAddress = (url = '') => {
  if (config.storeViews.multistore) {
    return processURLAddress(adjustMultistoreApiUrl(url));
  }

  return processURLAddress(url);
};

const setShippingInfo = async (addressInformation: any, token = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.shippinginfo_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      mode: 'cors',
      body: JSON.stringify({ addressInformation }),
    },
    silent: true,
  });

const getTotals = async (token = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.totals_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      mode: 'cors',
    },
    silent: true,
  });

const getCartToken = async (guestCart = false, forceClientState = false, token = ''): Promise<Task> => {
  const url = processLocalizedURLAddress(config.cart.create_endpoint);

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: guestCart ? '' : token },
      mode: 'cors',
    },
    force_client_state: forceClientState,
    silent: true,
  });
};

const updateItem = async (cartServerToken: string, cartItem: CartItem, userToken = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.updateitem_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
      body: JSON.stringify({
        cartItem: {
          ...cartItem,
          quoteId: cartItem.quoteId || cartServerToken,
        },
      }),
    },
  });

const deleteItem = async (cartServerToken: string, cartItem: CartItem, userToken = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.deleteitem_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
      body: JSON.stringify({
        cartItem: {
          ...cartItem,
          quoteId: cartServerToken,
        },
      }),
    },
    silent: true,
  });

const getPaymentMethods = async (userToken = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.paymentmethods_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
    },
    silent: true,
  });

const getShippingMethods = async (address: any, userToken = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.shippingmethods_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
      body: JSON.stringify({
        address,
      }),
    },
    silent: true,
  });

const getItems = async (userToken = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.pull_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
    },
    silent: true,
  });

const applyCoupon = async (couponCode: string, userToken = ''): Promise<Task> => {
  const url = processLocalizedURLAddress(config.cart.applycoupon_endpoint.replace('{{coupon}}', couponCode));

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
    },
    silent: false,
  });
};

const removeCoupon = async (userToken = ''): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.deletecoupon_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: userToken },
      mode: 'cors',
    },
    silent: false,
  });

const restoreCart = async (cartId: number): Promise<Task> => {
  return TaskQueue.execute({
    url: processLocalizedURLAddress(config.zento.cart.restore_endpoint.replace('{{cartId}}', cartId)),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
    },
    silent: true,
  });
};

export const CartService: ICartService = {
  setShippingInfo,
  getTotals,
  getCartToken,
  updateItem,
  deleteItem,
  getPaymentMethods,
  getShippingMethods,
  getItems,
  applyCoupon,
  removeCoupon,
  restoreCart,
};
