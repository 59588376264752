import { createModule } from '@vue-storefront/core/lib/module';

import { MyFiltersState } from './store';
import { KEY } from './key';

export { KEY } from './key';

export const MyFilters = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: MyFiltersState }] },
});
