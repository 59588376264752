import type { FetchConfigurationQuery } from 'theme/stores/gdpr/operations.graphql';

// Compute the platforms map
export type AvailablePlatforms = {
  [platform in keyof Omit<
    FetchConfigurationQuery['gdprClaims'],
    '__typename' | 'popup'
  >]: FetchConfigurationQuery['gdprClaims'][platform];
};

const platformsCache: {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  [platform in AvailablePlatforms[keyof AvailablePlatforms]['__typename']]: ReturnType<typeof resolvePlatform>;
} = {} as any;

/**
 * Resolve all supported platforms implementation dynamically
 */
export function resolvePlatform(platform: AvailablePlatforms[keyof AvailablePlatforms]['__typename']) {
  switch (platform) {
    case 'GoogleTagManager':
      return platformsCache[platform] || (platformsCache[platform] = import('./GTM').then((m) => m.GTM));

    case 'GoogleAnalytics':
      return platformsCache[platform] || (platformsCache[platform] = import('./GA').then((m) => m.GA));

    case 'FacebookPixel':
      return platformsCache[platform] || (platformsCache[platform] = import('./FBQ').then((m) => m.FBQ));

    case 'WhatsApp':
      return platformsCache[platform] || (platformsCache[platform] = import('./WhatsApp').then((m) => m.WhatsApp));

    case 'AdWords':
      return platformsCache[platform] || (platformsCache[platform] = import('./GAds').then((m) => m.GAds));

    case 'SalesForce':
      return (
        platformsCache[platform] ||
        (platformsCache[platform] = import('./SalesForceLiveAgent').then((m) => m.SalesForceLiveAgent))
      );

    case 'CommerceConnector':
      return (
        platformsCache[platform] ||
        (platformsCache[platform] = import('./CommerceConnector').then((m) => m.CommerceConnector))
      );

    case 'TikTok':
      return platformsCache[platform] || (platformsCache[platform] = import('./TikTok').then((m) => m.TikTok));

    case 'Tawk':
      return platformsCache[platform] || (platformsCache[platform] = import('./Tawk').then((m) => m.Tawk));

    case 'Zendesk':
      return platformsCache[platform] || (platformsCache[platform] = import('./Zendesk').then((m) => m.Zendesk));

    case 'OneSignal':
      return platformsCache[platform] || (platformsCache[platform] = import('./OneSignal').then((m) => m.OneSignal));

    case 'Hotjar':
      return platformsCache[platform] || (platformsCache[platform] = import('./Hotjar').then((m) => m.Hotjar));

    case 'Edrone':
      return platformsCache[platform] || (platformsCache[platform] = import('./Edrone').then((m) => m.Edrone));

    // Add more when needed

    default:
      throw new Error(`Unsupported analytics platform '${platform}. Please consider adding an implementation'`);
  }
}
