import { ActionTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import type { CartState } from '../../types/CartState';

import connectActions from './connectActions';
import couponActions from './couponActions';
import itemActions from './itemActions';
import mergeActions from './mergeActions';
import methodsActions from './methodsActions';
import productActions from './productActions';
import quantityActions from './quantityActions';
import synchronizeActions from './synchronizeActions';
import totalsActions from './totalsActions';

const actions: ActionTree<CartState, RootState> = {
  ...connectActions,
  ...itemActions,
  ...mergeActions,
  ...methodsActions,
  ...productActions,
  ...quantityActions,
  ...synchronizeActions,
  ...couponActions,
  ...totalsActions,
};

export default actions;
