import RawComponent from 'vue-class-component';

export {
  Emit,
  Inject,
  InjectReactive,
  Model,
  Prop,
  PropSync,
  Provide,
  ProvideReactive,
  Ref,
  Watch,
} from 'vue-property-decorator';

export const Component: typeof RawComponent = RawComponent;

export { Action, Getter, Mutation, State } from 'vuex-class';

export { namespace } from './vuex';

export { BaseComponent, Mixins } from './Base';

export { IBroadcastedAction } from './Base/types';

export { Exposed } from './decorators/exposed';

export { VariationBase } from './decorators/variation-base';
