import config from 'config';
import rootStore from '@vue-storefront/core/store';

import { IProductState } from '../../../@types/vsf/stores/product';

export const getCurrentConfig = (product: IProductState) => {
  const currentConfig = {};
  const defaultFilters = Object.keys((rootStore.state.attribute as any).list_by_code)?.length
    ? Object.keys((rootStore.state.attribute as any).list_by_code)
    : config.zento.theme.category.defaultFilters.length
    ? config.zento.theme.category.defaultFilters
    : [];

  defaultFilters.forEach((filter: string) => {
    const label = product.configurable_options.find((opt) => opt.attribute_code.indexOf(filter) === 0);

    if (label) {
      Object.assign(currentConfig, {
        [label.attribute_code]: {
          id: product[label.attribute_code],
          attribute_code: label.attribute_code,
          label: (product.totals.options as Array<Record<string, string>>).find((opt) =>
            (label.values as Array<Record<string, string>>).find((val) => val.label === opt.value),
          )?.value,
        },
      });
    }
  });

  return currentConfig;
};
