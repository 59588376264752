import type { Store } from 'vuex'
import type RootState from '@vue-storefront/core/types/RootState'
import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

// Plugins
import i18n from '@vue-storefront/i18n'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { sync } from 'vuex-router-sync'
import VueObserveVisibility from 'vue-observe-visibility'

// Apollo GraphQL client
import { getApolloProvider } from './scripts/resolvers/resolveGraphQL'

// TODO simplify by removing global mixins, plugins and filters - it can be done in normal 'vue' way
import { themeEntry, initTheme } from 'theme/index.js'
import { registerModules } from '@vue-storefront/core/lib/module'
import { prepareStoreView, currentStoreView } from '@vue-storefront/core/lib/multistore'

import * as coreMixins from '@vue-storefront/core/mixins'
import * as coreFilters from '@vue-storefront/core/filters'
import * as corePlugins from '@vue-storefront/core/compatibility/plugins'

import store from '@vue-storefront/core/store'

import { enabledModules } from './modules-entry'
import { RouterManager } from './lib/router-manager'

// Will be deprecated in 1.8
import { registerExtensions } from '@vue-storefront/core/compatibility/lib/extensions'
import { registerExtensions as extensions } from 'src/extensions'
import globalConfig from 'config'

function createRootStoreState() {
  return {
    version: '',
    __DEMO_MODE__: false,
    config: {},
    cart: {},
    checkout: {},
    cms: {},
    compare: {},
    product: {},
    shipping: {},
    user: {},
    ui: {},
    newsletter: {},
    wishlist: {},
    attribute: '',
    category: {
      current_path: '',
      current_product_query: {},
      current: {
        slug: '',
        name: ''
      },
      filters: {}
    },
    stock: {
      cache: []
    },
    storeView: {},
    twoStageCachingDelta1: 0,
    twoStageCachingDelta2: 0,
    twoStageCachingDisabled: false,
    userTokenInvalidated: null,
    userTokenInvalidateAttemptsCount: 0,
    userTokenInvalidateLock: 0
  };
}

function createRouter (): VueRouter {
  return new VueRouter({
    mode: 'history',
    base: __dirname,
    scrollBehavior: (to, from, savedPosition) => {
      if (to.hash) {
        return {
          selector: to.hash
        }
      }
      if (savedPosition) {
        return savedPosition
      } else {
        return {x: 0, y: 0}
      }
    }
  })
}

let router: VueRouter;
let routerUnsync: Function;

const createApp = async (ssrContext, config, storeCode = null): Promise<{app: Vue, router: VueRouter, store: Store<RootState>}> => {
  // Ensure proper router usage
  Vue.use(VueRouter);

  if (isServer) {
    // Replace store state with a fresh store state on each request
    store.replaceState(createRootStoreState());
  }

  if (isServer) {
    if ((store as any)._subscribers) {
      // Unregister previous route module
      const parentModulesCollection = (store as any)?._modules?.get ? (store as any)._modules.get([]) : null;

      Object.keys(store.state).forEach((k) => {
        if (parentModulesCollection && parentModulesCollection.hasChild(k)) {
          // Unregister stores only when previously registered
          store.unregisterModule(k);
        }
      });

      // Remove existing subscriptions
      (store as any)._subscribers = [];
    }
  }

  // Create a new router instance
  if (isServer || !router) {
    if (routerUnsync) {
      // Remove previous router registration
      routerUnsync();
    }

    router = createRouter();
  }

  if (isServer) {
    // Unregister previous route module
    Object.keys(store.state).forEach((k) => {
      try {
        store.unregisterModule(k);
      } catch (_) {
        // DO nothing, it will fail on first run
      }

      // Restore initial store state
    });

    store.replaceState(createRootStoreState());

    // Remove existing subscriptions
    (store as any)._subscribers = [];
  }

  // sync router with vuex 'router' store
  routerUnsync = sync(store, router)

  // TODO: Don't mutate the state directly, use mutation instead
  store.state.version = process.env.APPVERSION
  store.state.config = config // @deprecated
  store.state.__DEMO_MODE__ = (config.demomode === true)
  if (ssrContext) Vue.prototype.$ssrRequestContext = ssrContext
  if (!store.state.config) store.state.config = globalConfig //  @deprecated - we should avoid the `config`
  const storeView = await prepareStoreView(storeCode) // prepare the default storeView
  store.state.storeView = storeView

  // to depreciate in near future
  Vue.use(Meta)
  Vue.use(VueObserveVisibility)

  Object.keys(corePlugins).forEach(key => {
    Vue.use(corePlugins[key])
  })

  Object.keys(coreMixins).forEach(key => {
    Vue.mixin(coreMixins[key])
  })

  // @todo remove this part when we'll get rid of global multistore mixin
  if (isServer) {
    Object.defineProperty(ssrContext, 'helpers', {
      value: {
        currentStoreView
      },
      writable: true
    })
  }

  Object.keys(coreFilters).forEach(key => {
    Vue.filter(key, coreFilters[key])
  })

  let vueOptions = {
    router,
    store,
    i18n,
    render: h => h(themeEntry)
  }

  const apolloProvider = await getApolloProvider()
  if (apolloProvider) Object.assign(vueOptions, {provider: apolloProvider})

  const app = new Vue(vueOptions)

  const appContext = {
    isServer,
    ssrContext
  }

  // Create a new RouterManager instance with the current router
  if (isServer || !(RouterManager as any)._instance) {
    RouterManager.getInstance(router);
  }

  // Enforce module registration each time (on server)
  if (isServer) {
    enabledModules.forEach((m) => (m as any)._isRegistered = false);
  }

  if (isServer) {
    // Remove prebound modules data as part of the root store initialization
    enabledModules.reduce(
      (ret, m) => {
        const c = (m as any)._c;

        return ret.concat(
          c.store ?
            Array.from(c.store.modules).map((ss)=> (ss as any).key) :
            [c.key]
        )
      },
      []
    ).forEach(
      (m) => m in store.state && delete store.state[m]
    );
  }

  registerModules(enabledModules, appContext)
  registerExtensions(extensions, app, router, store, config, ssrContext)

  initTheme(app, router, store, config, ssrContext); // Initialize the theme directly don't use an idirection request

  EventBus.$emit('application-after-init', app)

  return { app, router, store };
}

export { createApp }
