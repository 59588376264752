/**
 * Resolve the component of specified layout
 *
 * All unique layouts have to be added as a switch case to allow static code analysis and correct bundling
 * They will be split on separate bundles due to the async nature of the call.
 */
export function resolveLayoutComponent(layout: string) {
  let componentFactory!: () => any;

  switch (layout) {
    case 'DefaultLayout':
      componentFactory = () => import('./Default/Default').then((m) => m.DefaultLayout);
      break;

    case 'MinimalLayout':
      componentFactory = () => import('./Minimal/Minimal').then((m) => m.MinimalLayout);
      break;

    case 'EmptyLayout':
      componentFactory = () => import('./Empty/Empty').then((m) => m.EmptyLayout);
      break;

    default:
      throw new Error(`Unknown layout type ${layout}`);
  }

  return componentFactory;
}
