import { MutationTree } from 'vuex';

import type { ReviewState } from '../types/ReviewState';

import * as types from './mutation-types';

const mutations: MutationTree<ReviewState> = {
  [types.REVIEW_UPD_REVIEWS](state, items) {
    state.items = items;
  },

  [types.REVIEW_UPD_REVIEWS_ITEMS](state, reviewItems) {
    state.reviewItems = reviewItems;
  },

  [types.REVIEW_UPD_PRODUCTS_REVIEWS](state, { product, productId }) {
    state.productReview[productId] = product;
  },
};

export default mutations;
