import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';

const productActions = {
  async findProductOption({ dispatch }, { serverItem }) {
    if (serverItem.product_type === 'configurable') {
      let query = new SearchQuery();
      query = query.applyFilter({ key: 'configurable_children.sku', value: { eq: serverItem.sku } });

      const { items } = await dispatch(
        'product/list',
        { query, start: 0, size: 1, updateState: false },
        { root: true },
      );

      return items.length >= 1 ? { sku: items[0].sku, childSku: serverItem.sku } : null;
    }

    if (serverItem.product_type === 'bundle') {
      let query = new SearchQuery();
      query = query.applyFilter({ key: 'sku', value: { eq: serverItem.sku.split('-')[0] } }); // TODO: see if there is a better solution

      // TODO: see if further information like "serverItem.product_option" is not needed for "setupAssociated" action in product store
      const { items } = await dispatch(
        'product/list',
        { query, start: 0, size: 1, updateState: false },
        { root: true },
      );

      return items.length >= 1 ? { sku: items[0].sku } : null;
    }

    // TODO: check for grouped products

    return { sku: serverItem.sku };
  },

  async getProductVariant({ dispatch }, { serverItem }) {
    try {
      const options = await dispatch('findProductOption', { serverItem });
      const singleProduct = await dispatch(
        'product/single',
        { options, assignDefaultVariant: true, setCurrentProduct: false, selectDefaultVariant: false },
        { root: true },
      );

      return {
        ...singleProduct,
        server_item_id: serverItem.item_id,
        qty: serverItem.qty,
        server_cart_id: serverItem.quote_id,
        product_option: serverItem.product_option || singleProduct.product_option,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};

export default productActions;
