import { createModule } from '@vue-storefront/core/lib/module';

import { extendAddressStore } from './store';
import { KEY } from './key';

export { KEY } from './key';
export { cacheStorage } from './cache';

export const ExtendAddress = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: extendAddressStore }] },
});
