import config from 'config';
import omit from 'lodash-es/omit';
import pullAll from 'lodash-es/pullAll';

import type { CartItem } from '../types/CartItem';

const optimizeProduct = (product: CartItem): Partial<CartItem> => {
  if (!config.entities.optimize || !config.entities.optimizeShoppingCart) {
    return product;
  }

  let fieldsToOmit = config.entities.optimizeShoppingCartOmitFields;

  if (config.cart.productsAreReconfigurable) {
    fieldsToOmit = pullAll(fieldsToOmit, ['configurable_children', 'configurable_options']);
  }

  return omit(product, fieldsToOmit);
};

export default optimizeProduct;
