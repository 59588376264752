import type { AbstractRequester, MinimalRequester } from '../adapters/requester/abstract/AbstractRequester';
import type { Operation, RequesterOptions } from '../adapters/requester/abstract/types';
import { GraphQlRequester } from '../adapters/requester/graphql/GraphQlRequester';

import type { StoreContext } from './BaseStore';

export type DataSources = 'graphQl' | 'elasticSearch' | 'rest';

type AnyRequester = AbstractRequester<any, any>;

export class DataSource implements MinimalRequester {
  private requesterType: DataSources;

  private requester: AnyRequester;

  /**
   * Keeps track of requesters being loaded
   */
  private loadingRequesters: { [type in DataSources]?: Promise<new (options: RequesterOptions) => AnyRequester> };

  private context: StoreContext;

  private queuedCustomHeaders: Record<string, string> | null;

  public constructor(type: DataSources, context: StoreContext) {
    this.requesterType = type;
    this.loadingRequesters = {};
    this.context = context;
    this.queuedCustomHeaders = null;
  }

  public async queue<T extends Operation<unknown, unknown, unknown>>(operation: T) {
    const instance = await this.getInstance();
    return instance.queue<T>(operation);
  }

  public async drained(): Promise<boolean> {
    const instance = await this.getInstance();
    return instance.drained();
  }

  public get customHeaders() {
    if (!this.requester) {
      return {};
    }

    return this.requester.customHeaders;
  }

  public set customHeaders(customHeaders: Record<string, string>) {
    if (this.requester) {
      this.requester.customHeaders = customHeaders;
    } else {
      this.queuedCustomHeaders = customHeaders;
    }
  }

  /**
   * Requester type getter with dynamic import resolution, allows lazy loading of requesters
   */
  private async getInstance(): Promise<AnyRequester> {
    if (!this.requester) {
      if (this.requesterType in this.loadingRequesters) {
        // Await for lazy importing and initialization of the requester from a separate call
        await this.loadingRequesters[this.requesterType];
      } else {
        // Import and instantiate the requester
        switch (this.requesterType) {
          case 'graphQl':
            // Use statically imported GraphQl requester
            this.loadingRequesters[this.requesterType] = Promise.resolve(GraphQlRequester) as Promise<
              new (options: RequesterOptions) => AnyRequester
            >;
            break;

          case 'elasticSearch':
            // this.loadingRequesters[this.requesterType] = import('../adapters/requester/elasticSearch').then((m) => m.ElasticSearchRequester);
            break;

          case 'rest':
            // this.loadingRequesters[this.requesterType] = import('../adapters/requester/rest').then((m) => m.RestRequester);
            break;

          default:
            throw new Error(`Unknown requeester type ${this.requesterType}`);
        }

        const constructor = await this.loadingRequesters[this.requesterType];

        this.requester = new constructor({
          batchOperations: this.requesterType === 'graphQl',
          debounce: true,
          debounceDelay: 100,
          debounceMaxAllowed: 200,
          url:
            this.requesterType === 'graphQl'
              ? this.context.config.zento.theme.storeData.storeUrl + '/m2/graphql' // TODO: Replace with config based option on core refactor
              : this.context.config.api.url,
        });

        if (this.queuedCustomHeaders) {
          this.customHeaders = this.queuedCustomHeaders;
          this.queuedCustomHeaders = null;
        }
      }
    }

    return this.requester;
  }
}
