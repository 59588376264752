import MailItem from '../types/MailItem'
import { Module } from 'vuex'
import config from 'config'
import { processURLAddress } from '@vue-storefront/core/helpers'

export const module: Module<any, any> = {
  namespaced: true,
  actions: {
    async sendEmail (context, letter: MailItem) {
      let res: Response;

      try {
        res = await fetch(processURLAddress(config.mailer.endpoint.token));
      } catch(e){
        console.error('Unabled to fetch email token', e);
      }

     if (res && res.ok) {
       let tokenResponse: {code: number, result: string};

        try {
          tokenResponse = await res.json();
        } catch(e){
          console.error('Malformed or empty email token', e);
        }

        if (tokenResponse) {
          try {
            return await fetch(processURLAddress(config.mailer.endpoint.send), {
              method: 'POST',
              mode: 'cors',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                ...letter,
                token: tokenResponse.result,
              })
            });
          } catch(e){
            console.error('Unable to send email', e);
          }
        }
     }

     return null;
    }
  }
}
