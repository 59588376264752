import { GDPRStore } from 'theme/stores/gdpr/gdpr';
import type { UnPromisify } from '@zento-lib/util/types';

import { Component, BaseComponent } from '../';

import type { AbstractPlatform } from './platforms/Abstract';
import { resolvePlatform } from './platforms';

type queueEvent = {
  eventName: string;
  payload: Record<any, any>;
};

/**
 * AnalyticsServices
 *
 * Listens for analytics events via broadcast and propagates the information to supported platforms
 */
@Component({})
export class AnalyticsServices extends BaseComponent<any, unknown> {
  private static analyticsPrefix = 'analytics/*';

  private gdprStore = new GDPRStore();

  /**
   * Instantiated platform handlers array
   */
  private platforms: Array<AbstractPlatform<any, any>> = [];

  /**
   * Pre initialization event queue
   */
  private preQueue: queueEvent[] = [];

  /**
   * Initialization status keeper
   */
  private initialized = false;

  /**
   * Un-watch function keeper
   */
  private unwatch: Array<() => void> = [];

  beforeMount() {
    if (this.gdprStore.cookie) {
      this.unwatch.push(this.gdprStore.watch('cookieData', this.enableServices));
    }
  }

  async mounted() {
    // Check if platforms are set
    if (!this.gdprStore.platforms.length) {
      await this.fetchClaimsInfo();
    }

    // Check if a cookie is set
    if (!this.gdprStore.cookie) {
      const cookieData = await this.gdprStore.getFromStorage();

      if (cookieData) {
        if (await this.gdprStore.setInStorage(cookieData)) {
          // Enable active platforms services on successful storage updates
          this.enableServices();
        }
      }
    }

    this.propagate = this.propagate.bind(this);

    // Listen for all analytics events in bulk
    this.onBroadcast(AnalyticsServices.analyticsPrefix, this.propagate);
  }

  beforeDestroy() {
    this.offBroadcast(AnalyticsServices.analyticsPrefix);
    this.unwatch.forEach((h) => h());
  }

  render() {
    return null;
  }

  /**
   * Starts all enabled platform handlers
   */
  private async enableServices() {
    const platforms: Array<UnPromisify<ReturnType<typeof resolvePlatform> | null>> = await Promise.all(
      // Get constructors of all enabled platforms
      this.gdprStore.platforms.map((p) =>
        p.active && p.id && p.categoryLabel && this.gdprStore.cookie[p.categoryLabel]?.checked
          ? resolvePlatform(p.__typename)
          : Promise.resolve(null),
      ),
    );

    // Instantiate platforms
    this.platforms = this.gdprStore.platforms
      .map((p, i) => {
        const P = platforms[i];

        if (P) {
          return new P(p);
        }

        return null;
      })
      .filter((p) => !!p);

    this.initialized = true;

    // Consume preinitialization queue
    if (this.preQueue.length) {
      this.preQueue.forEach((v: queueEvent) => this.propagate(v.eventName, v.payload));
    }
  }

  /**
   * Propagate events to enable platforms allowing each one to handle them
   */
  private propagate(eventName: string, payload: Record<any, any>) {
    if (!this.initialized) {
      this.preQueue.push({ eventName, payload });

      return;
    }

    const handled = this.platforms.map((p) => p.queue(eventName.slice(10), payload)).some((r) => !!r);

    // None of the platforms handled the received event
    if (!handled && this.platforms.length) {
      console.error(`Unhandled analytics event: ${eventName}`);
    }
  }

  private async fetchClaimsInfo() {
    return this.gdprStore.fetchClaims();
  }
}
