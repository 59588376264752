import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import type { ReviewState } from '../types/ReviewState';

const getters: GetterTree<ReviewState, RootState> = {
  currentReviews(state) {
    return state.items.items ? state.items.items : [];
  },

  reviewItems(state) {
    return state.reviewItems.items ? state.reviewItems.items : [];
  },

  productReview(state) {
    return state.productReview ? state.productReview : {};
  },
};

export default getters;
