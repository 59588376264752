import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import IProductState from '../../types/ProductState';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const productModule: Module<IProductState, RootState> = {
  namespaced: true,
  state: {
    breadcrumbs: {
      routes: [],
      name: '',
    },
    current: null, // shown product
    current_options: {
      color: [],
      size: [],
    },
    current_configuration: {},
    parent: null,
    list: [],
    original: null, // default, not configured product
    related: {},
    offlineImage: null,
    current_custom_options: {},
    current_bundle_options: {},
    custom_options_validators: {},
    productLoadStart: 0,
    productLoadPromise: null,
    productGallery: [],
  },
  getters,
  actions,
  mutations,
};
