import { MutationTree } from 'vuex';

const mutations: MutationTree<any> = {
  setMyAccount(state, action) {
    state.myaccount = action === true;
  },
  setModalActive(state, action) {
    state.modalActive = action === true;
  },
  setDrawerActive(state, action) {
    state.drawerActive = action === true;
  },
};

export default mutations;
