import type { Module } from 'vuex';

import type { ExtendAddressState } from '../types/AddressState';

import actions from './actions';

export const extendAddressStore: Module<ExtendAddressState, any> = {
  namespaced: true,
  actions,
};
