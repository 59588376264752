import { CartService } from '../../data-resolver/CartService';

const couponActions = {
  async removeCoupon({ rootState, getters, dispatch }, { sync = true } = {}) {
    if (getters.canSyncTotals) {
      const { result } = await CartService.removeCoupon(rootState.user.token);

      if (result && sync) {
        await dispatch('syncTotals', { forceServerSync: true });

        return result;
      }
    }
  },

  async applyCoupon({ rootState, getters, dispatch }, couponCode) {
    if (couponCode && getters.canSyncTotals) {
      const { result } = await CartService.applyCoupon(couponCode, rootState.user.token);

      if (result) {
        await dispatch('syncTotals', { forceServerSync: true });
      }

      return result;
    }
  },
};

export default couponActions;
