import { createModule } from '@vue-storefront/core/lib/module';

import { MyAccountState } from './store';
import { KEY } from './key';

export { KEY } from './key';

export const MyAccount = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: MyAccountState }] },
});
