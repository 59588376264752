import { Store } from 'vuex';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueProgressBar from 'vue-progressbar';
import { once } from '@vue-storefront/core/helpers';
import type RootState from '@vue-storefront/core/types/RootState';
import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore';
import { RouterManager } from '@vue-storefront/core/lib/router-manager';
import '@vue-storefront/core/lib/passive-listeners';
import VueScrollactive from 'vue-scrollactive';
import type { IZentoConfig } from '@zento-common/lib/config';
import { BaseStore } from '@zento-lib/stores/BaseStore';

import { Application } from './App';
import routes from './router';

// Put Vue in verbose mode
Vue.config.devtools = process.env.NODE_ENV !== 'production'; // Enable vue development tools extension support
Vue.config.silent = process.env.NODE_ENV === 'production'; // Put Vue in silent mode on production
Vue.config.productionTip = process.env.NODE_ENV !== 'production'; // Offer performance hints for production
Vue.config.performance = process.env.NODE_ENV !== 'production'; // Measure Vue performance

// Attache global Vue error handlers
Vue.config.errorHandler = function globalVueErrorHandler(err, vm, info) {
  console.dir({ err, vm, info });
};

// Attach client side global error handler (outside Vue lifecycles)
if (typeof window === 'object') {
  window.onerror = function globalErrorHandler(event, source, line, col, err) {
    console.dir({ event, source, line, col, err });
  };

  window.onunhandledrejection = function globalPromiseRejectionHandler(ev) {
    console.dir(ev);
  };
}

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar);
  Vue.use(VueScrollactive);
});

export const themeEntry = Application;

export function initTheme(
  _app: Vue,
  router: VueRouter,
  _globalStore: Store<RootState>,
  config: IZentoConfig,
  context: any, // SSR context
) {
  if (config.storeViews.multistore) {
    // Register all stores routes
    setupMultistoreRoutes(config, router, routes);
  } else {
    RouterManager.getInstance().addRoutes(routes, router);
  }

  // Register stores context
  BaseStore.registerContext(context || {}, config);

  if (typeof window !== 'undefined') {
    // Allow bulk event handling
    if (typeof (Vue as any).multicastedEmit === 'undefined') {
      (Vue as any).multicastedEmit = true;
      const originalEmit = Vue.prototype.$emit;

      Vue.prototype.$emit = function multicastEmit(eventName: string, ...args: any[]) {
        if (eventName && eventName.includes('analytics/')) {
          return originalEmit.apply(this, ['analytics/*', eventName].concat(args));
        }

        return originalEmit.apply(this, [eventName].concat(args));
      };
    }
  }
}
