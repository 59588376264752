/**
 * Helper method for getting attribute name - TODO: to be moved to external/shared helper
 *
 * @param {String} attributeCode
 * @param {String} optionId - value to get label for
 */
import toString from 'lodash-es/toString';

export function optionLabel(state, { attributeKey, searchBy = 'code', optionId }) {
  if (!optionId) {
    return { label: '', order: 0 };
  }

  const attrCache = state.labels[attributeKey];

  if (attrCache) {
    const opt = attrCache[optionId];

    if (opt && opt.label) {
      return { label: opt.label, order: opt.order };
    }
  }

  const attr = state['list_by_' + searchBy][attributeKey];

  if (attr) {
    const opt = attr.options?.find((op) => {
      // TODO: cache it in memory
      if (toString(op.value) === toString(optionId)) {
        return op;
      }
    }); // TODO: i18n support with  multi-website attribute names

    if (opt) {
      if (!state.labels[attributeKey]) {
        state.labels[attributeKey] = {};
      }

      state.labels[attributeKey][optionId] = { label: opt.label, order: opt.sort_order };

      return opt ? { label: opt.label, order: opt.sort_order } : { label: optionId, order: 0 };
    } else {
      return { label: optionId, order: 0 };
    }
  } else {
    return { label: optionId, order: 0 };
  }
}
