import { createModule } from '@vue-storefront/core/lib/module';

import { ApplicationContextState } from './store';
import { KEY } from './key';

export { KEY } from './key';

export const ApplicationContext = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: ApplicationContextState }] },
});
