/**
 * Mark's a store property as a state field and ensures it can be client hydrated at client side
 */
export const Field = (target: {}, key: string) => {
  if (!key) {
    throw new Error(`Field: Invalid @Field decorator usage. Expected to be applied at property level`);
  }

  if (typeof target[key] === 'function') {
    throw new Error(`Field: Invalid @Field decorator usage. Expected to be applied at property level`);
  }

  // Add annotated properties to the list of store fields
  const t = target as any;
  t.__storeFields = t.__storeFields || new Set();
  t.__storeFields.add(key);
};
