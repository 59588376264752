import { ActionTree } from 'vuex';
import config from 'config';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore/adjustMultistoreApiUrl';
import type RootState from '@vue-storefront/core/types/RootState';
import rootStore from '@vue-storefront/core/store';
import { processURLAddress } from '@vue-storefront/core/helpers';
import { Format } from '@zento-lib/components/i18n';

import type { ReviewState } from '../types/ReviewState';
import type { Review } from '../types/Review';
import type { ReviewRequest } from '../types/ReviewRequest';

import * as types from './mutation-types';

const actions: ActionTree<ReviewState, RootState> = {
  /**
   * Retrieve reviews
   *
   * @param context
   * @param {number} productId
   * @param {number} customerId
   * @param {boolean} approved
   * @param {number} start
   * @param {number} size
   * @param {string} entityType
   * @param {string} sort
   * @param {string[]} excludeFields
   * @param {string[]} includeFields
   * @returns {Promise<any>}
   */
  async list(
    context,
    {
      productId,
      customerId,
      approved = true,
      start = 0,
      size = 50,
      entityType = 'review',
      sort = '',
      excludeFields = null,
      includeFields = null,
    }: any,
  ): Promise<any> {
    let query = new SearchQuery();

    if (productId) {
      query = query.applyFilter({ key: 'product_id', value: { eq: productId } });
    }

    if (customerId) {
      query = query.applyFilter({ key: 'customer_id', value: { eq: customerId } });
    }

    if (approved) {
      query = query.applyFilter({ key: 'review_status', value: { eq: 1 } });
    }

    const response = await quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields });

    if (response) {
      context.commit(types.REVIEW_UPD_REVIEWS, response);
    }

    return response || null;
  },

  /**
   * Retrieve reviews
   *
   * @param context
   * @param {number} productId
   * @param {number} customerId
   * @param {boolean} approved
   * @param {number} start
   * @param {number} size
   * @param {string} entityType
   * @param {string} sort
   * @param {string[]} excludeFields
   * @param {string[]} includeFields
   * @returns {Promise<any>}
   */
  async reviewItems(
    context,
    {
      productIds,
      approved = true,
      start = 0,
      size = 50,
      entityType = 'review',
      sort = '',
      excludeFields = null,
      includeFields = null,
    }: any,
  ): Promise<any> {
    let query = new SearchQuery();

    if (productIds?.length) {
      query = query.applyFilter({ key: 'product_id', value: { in: productIds } });
    }

    if (approved) {
      query = query.applyFilter({ key: 'review_status', value: { eq: 1 } });
    }

    const response = await quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields });

    if (response) {
      context.commit(types.REVIEW_UPD_REVIEWS_ITEMS, response);
    }

    return response || null;
  },

  /**
   * Retrieve product information from review
   *
   * @param context
   * @param {number} productId
   * @param {string} entityType
   * @returns {Promise<any>}
   */
  // eslint-disable-next-line max-len
  async reviewsProducts(
    context,
    { productId, entityType = 'product', start = 0, size = 50, sort = '' }: any,
  ): Promise<any> {
    const query = new SearchQuery();
    const excludeFields = config.entities.product.excludeFields;
    const includeFields = config.entities.product.includeFields;

    query.applyFilter({ key: 'id', value: { eq: productId } });

    const response = await quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields });

    if (response) {
      context.commit(types.REVIEW_UPD_PRODUCTS_REVIEWS, { product: response.items[0], productId: productId });
    }

    return response || null;
  },

  /**
   * Add new review
   *
   * @param context
   * @param {Review} reviewData
   * @returns {Promise<void>}
   */
  async add(context, reviewData: Review): Promise<void> {
    const review: ReviewRequest = { review: reviewData };

    EventBus.$emit('notification-progress-start', Format.message('adding_review'));

    let url = config.reviews.create_endpoint;

    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url);
    }

    try {
      await fetch(processURLAddress(url), {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(review),
      });

      EventBus.$emit('notification-progress-stop');

      rootStore.dispatch('notification/spawnNotification', {
        type: 'success',
        message: Format.message('product_review_submit_message'),
        action1: { label: Format.message('notification_action') },
      });

      EventBus.$emit('clear-add-review-form');
    } catch (e) {
      EventBus.$emit('notification-progress-stop');

      rootStore.dispatch('notification/spawnNotification', {
        type: 'error',
        message: Format.message('product_review_submit_error_message'),
        action1: { label: Format.message('notification_action') },
      });
    }
  },
};

export default actions;
