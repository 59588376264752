import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import IProductState from '../../types/ProductState';

const getters: GetterTree<IProductState, RootState> = {
  productParent: (state) => state.parent,
  productCurrent: (state) => state.current,
  currentConfiguration: (state) => state.current_configuration,
  productOriginal: (state) => state.original,
  currentOptions: (state) => state.current_options,
  breadcrumbs: (state) => state.breadcrumbs,
  productGallery: (state) => state.productGallery,
  getProductRelated: (state) => state.related,
};

export default getters;
