import type { Module } from 'vuex';

import IMyFiltersState from '../types/MyFiltersState';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const MyFiltersState: Module<IMyFiltersState, any> = {
  namespaced: true,
  state: {
    drawerFilter: false,
  } as IMyFiltersState,
  getters,
  actions,
  mutations,
};
