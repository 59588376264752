import { MutationTree } from 'vuex';

import { IPersonalDetails, IShipment, IPayment, IReviewOrder, CheckoutSection } from '../types/CheckoutSteps';

import * as types from './mutation-types';

const mutations: MutationTree<any> = {
  [types.SECTION](state, section: CheckoutSection) {
    state.section = section;
  },

  [types.PERSONAL_CHECKOUT](state, personalDetails: IPersonalDetails) {
    state.personalDetails = personalDetails;
  },

  [types.SHIPMENT_CHECKOUT](state, shipment: IShipment) {
    state.shipment = shipment;
  },

  [types.PAYMENT_CHECKOUT](state, payment: IPayment) {
    state.payment = payment;
  },

  [types.REVIEW_CHECKOUT](state, review: IReviewOrder) {
    state.review = review;
  },
};

export default mutations;
