import { createModule } from '@vue-storefront/core/lib/module';

import { module } from './store';
import { beforeRegistration } from './hooks/beforeRegistration';
import { plugin } from './store/plugin';
import { KEY } from './key';

export { KEY } from './key';

export { cacheStorage } from './cache';

export const Compare = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }], plugin },
  beforeRegistration,
});
