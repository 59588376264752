import config from 'config';
import type { IZentoConfig } from '@zento-common/lib/config';
import type { IRoute } from '@zento-common/lib/config/types/zento/route';

import { resolvePageComponent } from './resolve';

const allRoutes = (config as IZentoConfig).zento.routes;

/**
 * Compute the routes based on currently selected configuration
 * Enforce async fetching
 */
export default allRoutes
  .filter((r) => r.page)
  .map((r) => {
    const page = `${r.page.slice(0, 1).toUpperCase()}${r.page.slice(1)}`;

    const rr = {
      name: r.name,
      path: r.path,
      get component() {
        return resolvePageComponent(page);
      },
      ...(r.props ? { props: r.props } : {}),
      ...(r.meta ? { meta: r.meta } : {}),
      ...(r.config ? { config: r.config } : {}),
      ...(r.alias ? { alias: r.alias } : {}),
    };

    if (
      config.storeViews.multistore === true &&
      rr.path !== '/c/:slug' &&
      rr.path !== '/p/:parentSku/:slug/:childSku' &&
      rr.path !== '/p/:parentSku/:slug'
    ) {
      // Homepage case, define homepage for each store in multistore cases
      return [rr].concat(
        config.storeViews.mapStoreUrlsFor.map((storeCode: string) => {
          return {
            ...rr,
            path: rr.path === '/' ? `/${storeCode}` : `/${storeCode}${rr.path}`,
            name: `${rr.name}-${storeCode}`,
            meta: {
              ...(rr.meta || {}),
              originalPath: rr.path === '/' ? '/' : rr.path,
            },
          };
        }),
      );
    }

    return [rr];
  })
  .reduce((ret, r) => ret.concat(r), []);

export const routes: IRoute[] = allRoutes;

export const staticRoutes: IRoute[] = allRoutes.filter((r) => !r.page);
