import { cartCacheHandlerFactory } from '../helpers/cartCacheHandler';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function afterRegistration({ Vue, config, store, isServer }) {
  if (!isServer) {
    store.dispatch('cart/load');
  }

  store.subscribe(cartCacheHandlerFactory(Vue));
}
