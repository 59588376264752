import Vue from 'vue';
import { MutationTree } from 'vuex';
import { entityKeyName } from '@vue-storefront/core/store/lib/entities';
import { Logger } from '@vue-storefront/core/lib/logger';

import ITaxState from '../../types/TaxState';

import * as types from './mutation-types';

const mutations: MutationTree<ITaxState> = {
  [types.TAX_UPDATE_RULES](state, taxClasses) {
    const cache = Vue.prototype.$db.elasticCacheCollection;

    // we store each product separately in cache to have offline access for products/single method
    for (const tc of taxClasses.items) {
      const cacheKey = entityKeyName('tc', tc.id);
      cache.setItem(cacheKey, tc).catch((err) => {
        Logger.error('Cannot store cache for ' + cacheKey + ', ' + err)();
      });
    }
    state.rules = taxClasses.items; // extract fields from ES _source
  },
};

export default mutations;
