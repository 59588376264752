import * as localForage from 'localforage';
import UniversalStorage from '@vue-storefront/core/store/lib/storage';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function beforeRegistration({ Vue, config, store, isServer }) {
  const storeView = currentStoreView();
  const dbNamePrefix = storeView.storeCode ? storeView.storeCode + '-' : '';

  Vue.prototype.$db.cartsCollection = new UniversalStorage(
    localForage.createInstance({
      name: (config.storeViews.commonCache ? '' : dbNamePrefix) + 'shop',
      storeName: 'carts',
      driver: localForage[config.localForage.defaultDrivers.carts],
    }),
  );
}
