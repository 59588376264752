import type { Module } from 'vuex';
import config from 'config';
import type RootState from '@vue-storefront/core/types/RootState';

import ShippingState from '../../types/ShippingState';

export const shippingModule: Module<ShippingState, RootState> = {
  namespaced: true,
  state: {
    methods: config.zento.defaultShippings.methods,
  },
  mutations: {
    addMethod(state, shippingMethods) {
      state.methods.push(shippingMethods);
    },
    replaceMethods(state, shippingMethods) {
      state.methods = shippingMethods;
    },
  },
  actions: {
    addMethod({ commit }, shippingMethod) {
      commit('addMethod', shippingMethod);
    },

    replaceMethods({ commit }, shippingMethods) {
      commit('replaceMethods', shippingMethods);
    },
  },
  getters: {
    shippingMethods(state) {
      return state.methods;
    },
  },
};
