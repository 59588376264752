import Vue from 'vue';
import config from 'config';
import { Logger } from '@vue-storefront/core/lib/logger';

/**
 * Return unique entity key name for specified key value
 * @param {String} key
 * @param {String} value
 */
export function entityKeyName(...values) {
  return values.join('$$');
}

const getCacheKey = (product, cacheByKey) => {
  if (!product[cacheByKey]) {
    cacheByKey = 'id';
  }

  return entityKeyName(cacheByKey, product[cacheByKey === 'sku' && product.parentSku ? 'parentSku' : cacheByKey]); // to avoid caching products by configurable_children.sku
};

export const canCache = ({ includeFields, excludeFields }) => {
  const isCacheable = includeFields === null && excludeFields === null;

  if (isCacheable) {
    Logger.debug('Entity cache is enabled for productList')();
  } else {
    Logger.debug('Entity cache is disabled for productList')();
  }

  return isCacheable;
};

export const preConfigureProduct = ({ product, populateRequestCacheTags }) => {
  const shouldPopulateCacheTags = populateRequestCacheTags && Vue.prototype.$cacheTags;
  const isFirstVariantAsDefaultInURL =
    config.products.setFirstVarianAsDefaultInURL &&
    // eslint-disable-next-line
    product.hasOwnProperty('configurable_children') &&
    product.configurable_children.length > 0;

  // this is an object to store validation result for custom options and others
  product.errors = {};
  product.info = {};

  if (shouldPopulateCacheTags) {
    Vue.prototype.$cacheTags.add(`P${product.id}`);
  }

  if (!product.parentSku) {
    product.parentSku = product.sku;
  }

  if (isFirstVariantAsDefaultInURL) {
    product.sku = product.configurable_children[0].sku;
  }

  return product;
};

export const configureChildren = (product) => {
  if (product.configurable_children) {
    for (const configurableChild of product.configurable_children) {
      if (configurableChild.custom_attributes) {
        for (const opt of configurableChild.custom_attributes) {
          configurableChild[opt.attribute_code] = opt.value;
        }
      }
    }
  }

  return product;
};

export const getOptimizedFields = ({ excludeFields, includeFields }) => {
  if (config.entities.optimize) {
    return {
      excluded: excludeFields || config.entities.product.excludeFields,
      included: includeFields || config.entities.product.includeFields,
    };
  }

  return { excluded: excludeFields, included: includeFields };
};

export const storeProductToCache = (product, cacheByKey) => {
  const cacheKey = getCacheKey(product, cacheByKey);
  const cache = Vue.prototype.$db.elasticCacheCollection;

  cache.setItem(cacheKey, product, null, config.products.disablePersistentProductsCache).catch((err) => {
    Logger.error('Cannot store cache for ' + cacheKey, err);

    if (err.name === 'QuotaExceededError' || err.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
      // quota exceeded error
      cache.clear(); // clear products cache if quota exceeded
    }
  });
};
