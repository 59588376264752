import Vue from 'vue';
import { MutationTree } from 'vuex';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { entityKeyName } from '@vue-storefront/core/store/lib/entities';
import { Logger } from '@vue-storefront/core/lib/logger';

import AttributeState from '../../types/AttributeState';

import * as types from './mutation-types';

const mutations: MutationTree<AttributeState> = {
  /**
   * Store attributes by code in state and localForage
   * @param {} state
   * @param {Array} attributes
   */
  async [types.ATTRIBUTE_UPD_ATTRIBUTES](state, attributes) {
    const attrList = attributes.items; // extract fields from ES _source
    const attrHashByCode = state.list_by_code;
    const attrHashById = state.list_by_id;
    const collectionSetters: Array<Promise<any>> = [];

    for (const attr of attrList) {
      attrHashByCode[attr.attribute_code] = attr;
      attrHashById[attr.attribute_id] = attr;

      const attrCollection = Vue.prototype.$db.attributesCollection;

      try {
        collectionSetters.push(
          attrCollection
            .setItem(entityKeyName('attribute_code', attr.attribute_code.toLowerCase()), attr)
            .catch((reason) => {
              Logger.error(reason, 'mutations'); // it doesn't work on SSR
            }),
        ); // populate cache by slug
        collectionSetters.push(
          attrCollection.setItem(entityKeyName('attribute_id', attr.attribute_id.toString()), attr).catch((reason) => {
            Logger.error(reason, 'mutations'); // it doesn't work on SSR
          }),
        ); // populate cache by id
      } catch (e) {
        Logger.error(e, 'mutations')();
      }
    }

    // Await for all set operations to be executed on attribute Collections
    await Promise.all(collectionSetters);

    Vue.set(state, 'list_by_code', attrHashByCode);
    Vue.set(state, 'list_by_id', attrHashById);

    EventBus.$emit('product-after-attributes-loaded');
  },
};

export default mutations;
