import { module } from './store'
import { plugin } from './store/plugin'
import { createModule } from '@vue-storefront/core/lib/module'
import { afterRegistration } from './hooks/afterRegistration'
import { KEY } from './key';

export { cacheStorage } from './cache';
export { KEY } from './key';

export const RecentlyViewed = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }], plugin },
  afterRegistration
})
