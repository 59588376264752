
import Vue from 'vue'
import { Logger } from '@vue-storefront/core/lib/logger'
import { _prepareTask } from './task'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import config from 'config'

/** Syncs given task. If user is offline requiest will be sent to the server after restored connection */
export async function queue (task) {
  const tasksCollection = Vue.prototype.$db.syncTaskCollection
  task = _prepareTask(task)
  Logger.info('Sync task queued ' + task.url, 'sync', { task })()
  return new Promise((resolve, reject) => {
    tasksCollection.setItem(task.task_id.toString(), task, (err, resp) => {
      if (err) Logger.error(err, 'sync')()
      EventBus.$emit('sync/PROCESS_QUEUE', { config: config }) // process checkout queue
      resolve(task)
    }, config.syncTasks.disablePersistentTaskQueue).catch((reason) => {
      Logger.error(reason, 'sync')() // it doesn't work on SSR
      reject(reason)
    })
  })
}
