import { ActionTree } from 'vuex';
import config from 'config';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types';
import type RootState from '@vue-storefront/core/types/RootState';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { Logger } from '@vue-storefront/core/lib/logger';
import { processURLAddress } from '@vue-storefront/core/helpers';
import { Format } from '@zento-lib/components/i18n/inline';

import IStockState from '../../types/StockState';

const actions: ActionTree<IStockState, RootState> = {
  /**
   * Reset current configuration and selected variants
   */
  async queueCheck(context, { product }) {
    if (config.stock.synchronize) {
      let url = processURLAddress(`${config.stock.endpoint}/check?sku=${encodeURIComponent(product.sku)}`);

      if (config.zento.theme.stock.msi.enabled) {
        if (!product.stock.stock_id) {
          return {
            status: 'no_stock_id',
          };
        }

        // TODO: [ZENDEL-571] temporarily hardcoded "stock_id"
        if (product.stock.sources && product.stock.stock_id !== 2) {
          product.stock.stock_id = 2;

          if (
            !product.stock.backorders &&
            product.stock.qty === 0 &&
            product.stock.sources.some((s) => s.status === '1' && parseInt(s.quantity) > 0)
          ) {
            // TODO: [ZENDEL-571] temporarily hardcoded "stock.qty" and "stock status"
            product.stock.qty = product.stock.sources.reduce((ret, s) => {
              return ret + parseInt(s.quantity);
            }, 0);
            product.stock.is_in_stock = true;
          }
        }

        url = processURLAddress(
          `${config.stock.endpoint}/check?sku=${encodeURIComponent(product.sku)}&stockId=${encodeURIComponent(
            product.stock.stock_id,
          )}`,
        );
      }

      const task: any = await TaskQueue.execute({
        url,
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        },
        is_result_cacheable: true, // store result for the Checkout.js double check
        product_sku: product.sku,
        callback_event: 'store:stock/stockAfterCheck',
      });

      return {
        qty: task.resultCode === 200 && task.result ? task.result.qty : product.stock ? product.stock.qty : 0,
        status:
          task.resultCode === 200 && task.result
            ? !task.result.manage_stock
              ? 'ok'
              : task.result.is_in_stock
              ? 'ok'
              : task.result.backorders !== 0
              ? 'ok'
              : 'out_of_stock'
            : product.stock
            ? product.stock.is_in_stock
              ? 'ok'
              : product.stock.backorders
              ? 'ok'
              : 'out_of_stock'
            : 'volatile',
        onlineCheckTaskId: task.task_id,
      }; // if online we can return ok because it will be verified anyway
    } else {
      return {
        qty: product.stock ? product.stock.qty : 0,
        status: product.stock
          ? product.stock.is_in_stock
            ? 'ok'
            : product.stock.backorders
            ? 'ok'
            : 'out_of_stock'
          : 'volatile',
      }; // if not online, cannot check the source of true here
    }
  },
  /**
   * Reset current configuration and selected variants
   */
  async check(context, { product }) {
    if (config.stock.synchronize) {
      const task: any = TaskQueue.execute({
        url: processURLAddress(`${config.stock.endpoint}/check?sku=${encodeURIComponent(product.sku)}`),
        payload: {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
        },
        product_sku: product.sku,
      });
      return {
        qty: task.result ? task.result.qty : 0,
        status: task.result ? (task.result.is_in_stock ? 'ok' : 'out_of_stock') : 'ok',
        onlineCheckTaskId: task.task_id,
      }; // if online we can return ok because it will be verified anyway
    } else {
      return {
        qty: product.stock ? product.stock.qty : 0,
        status: product.stock ? (product.stock.is_in_stock ? 'ok' : 'out_of_stock') : 'volatile',
      }; // if not online, cannot check the source of true here
    }
  },
  /**
   * Reset current configuration and selected variants
   */
  list(context, { skus }) {
    if (config.stock.synchronize) {
      try {
        const task: any = TaskQueue.execute({
          url: processURLAddress(`${config.stock.endpoint}/list?skus=${encodeURIComponent(skus.join(','))}`),
          payload: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
          },
          skus: skus,
        });
        if (task.resultCode === 200) {
          for (const si of task.result) {
            context.state.cache[si.product_id] = {
              is_in_stock: si.is_in_stock,
              qty: si.qty,
              product_id: si.product_id,
            }; // TODO: should be moved to mutation
          }
        }
        return task; // if online we can return ok because it will be verified anyway
      } catch (err) {
        Logger.error(err, 'stock')();
        return null;
      }
    } else {
      return null; // if not online, cannot check the source of true here
    }
  },
  clearCache(context) {
    context.state.cache = {};
  },
  async stockAfterCheck(context, event) {
    setTimeout(async () => {
      // TODO: Move to cart module
      const cartItem: any = await context.dispatch('cart/getItem', event.product_sku, { root: true });
      if (cartItem && event.result.code !== 'ENOTFOUND') {
        if (!event.result.is_in_stock) {
          // if config.cart.synchronize is true then -
          // the items are being removed by the result of cart/update action executed from cart/sync
          if (!config.stock.allowOutOfStockInCart && !config.cart.synchronize) {
            Logger.log('Removing product from cart' + event.product_sku, 'stock')();
            context.commit('cart/' + types.CART_DEL_ITEM, { product: { sku: event.product_sku } }, { root: true });
          } else {
            context.dispatch(
              'cart/updateItem',
              {
                product: {
                  errors: { stock: Format.message('out_of_stock_label') },
                  sku: event.product_sku,
                  is_in_stock: false,
                },
              },
              { root: true },
            );
          }
        } else {
          context.dispatch(
            'cart/updateItem',
            {
              product: {
                info: { stock: Format.message('in_stock_label') },
                sku: event.product_sku,
                is_in_stock: true,
              },
            },
            { root: true },
          );
        }
        EventBus.$emit('cart-after-itemchanged', { item: cartItem });
      }
      Logger.debug('Stock quantity checked for ' + event.result.product_id);
      Logger.debug('response time: ' + (event.transmited_at - event.created_at) + ' ms', 'stock')();
      Logger.debug(event, 'stock')();
    }, 500);
  },
};

export default actions;
