import config from 'config';

import type { CartItem } from '../types/CartItem';

const createCartItemForUpdate = (
  clientItem: CartItem,
  serverItem: any,
  updateIds = false,
  mergeQty = false,
  update = false,
): CartItem => {
  let sku = clientItem.parentSku && config.cart.setConfigurableProductOptions ? clientItem.parentSku : clientItem.sku;

  if (update && serverItem && serverItem.product_type === 'configurable') {
    sku = serverItem.sku;
  }

  const cartItem = {
    sku,
    qty: mergeQty ? clientItem.qty + serverItem.qty : clientItem.qty,
    product_option: !update ? clientItem.product_option : {},
  } as CartItem;

  if (updateIds && serverItem.quote_id && serverItem.item_id) {
    return {
      ...cartItem,
      quoteId: serverItem.quote_id,
      item_id: serverItem.item_id,
    };
  }

  return cartItem;
};

export default createCartItemForUpdate;
