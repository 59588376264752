import { createModule } from '@vue-storefront/core/lib/module';

import { CheckoutStepsStore } from './store';
import { KEY } from './key';

export { KEY } from './key';

export const CheckoutSteps = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: CheckoutStepsStore }] },
});
