import config from 'config';
import vsfI18n from '@vue-storefront/core/i18n';
import { date as vsfDateFormatter } from '@vue-storefront/core/filters/date';
import { duration as vsfDurationFormatter } from '@vue-storefront/core/filters/duration';

import { ILocaleFormat } from '../../../common/lib/config/types/zento/locales';
import type { IZentoConfig } from '../../../common/stores';

interface II18nMessageData {
  [interpolatedVarName: string]: string | number;
}

const defaultThousandsSeparator = ',';
const defaultDecimalSeparator = '.';

export class Format {
  /**
   * Get current locale default representation
   */
  private static get locale() {
    return (config as IZentoConfig).zento.locales[vsfI18n.locale];
  }

  private static normalizeNumberFormat(
    options: Partial<ILocaleFormat['number']>,
    defaults: ILocaleFormat['number'] = Format.locale.format.number,
  ) {
    return {
      decimalsSeparator:
        typeof options.decimalsSeparator === 'string' ? options.decimalsSeparator : defaults.decimalsSeparator,
      thousandsSeparator:
        typeof options.thousandsSeparator === 'string' ? options.thousandsSeparator : defaults.thousandsSeparator,
      precision: typeof options.precision === 'number' ? options.precision : defaults.precision,
    } as ILocaleFormat['number'];
  }

  private static normalizeFormatCurrency(
    options: Partial<ILocaleFormat['currency']>,
    defaults: ILocaleFormat['currency'] = Format.locale.format.currency,
  ) {
    return {
      code: typeof options.code === 'string' ? options.code : defaults.code,
      sign: typeof options.sign === 'string' ? options.sign : defaults.sign,
      symbol: typeof options.symbol === 'string' ? options.symbol : defaults.symbol,
      number: Format.normalizeNumberFormat(options.number || {}, Format.locale.format.currency.number),
      enableSign: options.enableSign,
      enableValue: options.enableValue,
    } as ILocaleFormat['currency'];
  }

  /**
   * Formats the received number by the specified format
   */
  public static number(value: number, options: Partial<ILocaleFormat['number']> = {}) {
    // Normalize options
    options = Format.normalizeNumberFormat(options);

    const v = value.toString().split('.');
    let integral = v[0] || '';
    let fraction = v[1] || '';

    integral = (integral
      .split('')
      .reverse()
      .map((c, i) => {
        if ((i + 1) % 3 === 0) {
          return [c, options.thousandsSeparator || defaultThousandsSeparator];
        }

        return c;
      })
      .reduce((ret, v) => {
        ret = ret.concat(...(Array.isArray(v) ? v : [v]));

        return ret;
      }, []) as string[])
      .reverse()
      .join('');

    fraction = new Array(options.precision)
      .fill(0)
      .map((v, i) => fraction[i] || v)
      .join('');

    if (integral.indexOf(options.thousandsSeparator) === 0) {
      integral = integral.slice(options.thousandsSeparator.length);
    }

    integral = integral.replace('-,', '-');

    return fraction ? `${integral}${options.decimalsSeparator || defaultDecimalSeparator}${fraction}` : `${integral}`;
  }

  /**
   * Formats provided value as currency
   */
  public static currency(value: number, options: Partial<ILocaleFormat['currency']> = {}) {
    // Normalize options
    options = Format.normalizeFormatCurrency(options);

    const numeric = Format.number(value, options.number);

    const s = options.symbol || ({} as ILocaleFormat['currency']['symbol']);
    const isBefore = s.placement ? s.placement === 'before' : true;

    const symbol = `${
      // Symbol prefix
      s.prefix ? s.prefix : isBefore ? ' ' : ''
    }${s.use === 'sign' ? options.sign || 'Ø' : options.code || 'XYZ'}${
      // Symbol suffix
      s.suffix ? s.suffix : !isBefore ? ' ' : ''
    }`;

    return isBefore
      ? `${symbol}${options.enableValue ? numeric : ''}`
      : `${options.enableValue ? numeric : ''}${options.enableSign ? symbol : ''}`;
  }

  /**
   * Given the message id, desired count and eventual values, pluralizes the message by specified count
   */
  public static pluralize(message: string, count?: number, values?: any[]) {
    return vsfI18n.tc(message, count, values);
  }

  /**
   * Given the message id, translates it to the current locale interpolating eventually received data
   */
  public static message(message: string, data?: II18nMessageData) {
    if (message) {
      return data ? vsfI18n.t(message, data) : vsfI18n.t(message);
    }

    return '';
  }

  /**
   * Formats the input date by specified format
   */
  public static date(date: Date | number | string, format?: string) {
    date = new Date(date).toString();

    return vsfDateFormatter(date, format || undefined);
  }

  /**
   * Formats the input duration
   */
  public static duration(duration: number) {
    return vsfDurationFormatter(duration);
  }
}
