import sumBy from 'lodash-es/sumBy';
import { Format } from '@zento-lib/components/i18n/inline';

import type { ShippingMethod } from '../types/ShippingMethod';
import type { PaymentMethod } from '../types/PaymentMethod';
import type { CartItem } from '../types/CartItem';

function asNumber(v: string | number) {
  return parseInt(v.toString());
}

const calculateTotals = (shippingMethod: ShippingMethod, paymentMethod: PaymentMethod, cartItems: CartItem[]) => {
  const shippingTax = shippingMethod ? shippingMethod.price_incl_tax : 0;

  const totalsArray = [
    {
      code: 'subtotal_incl_tax',
      title: Format.message('subtotal_incl_tax'),
      value: sumBy(cartItems, (p: CartItem) => p.qty * asNumber(p.price_incl_tax ?? p.final_price)),
    },
    {
      code: 'grand_total',
      title: Format.message('grand_total'),
      value: sumBy(cartItems, (p) => p.qty * asNumber(p.price_incl_tax ?? p.final_price) + shippingTax),
    },
  ];

  if (paymentMethod) {
    totalsArray.push({
      code: 'payment',
      title: Format.message(paymentMethod.title),
      value: paymentMethod.cost_incl_tax,
    });
  }

  if (shippingMethod) {
    totalsArray.push({
      code: 'shipping',
      title: Format.message(shippingMethod.method_title),
      value: shippingMethod.price_incl_tax,
    });
  }

  return totalsArray;
};

export default calculateTotals;
