import { ActionTree } from 'vuex';
import config from 'config';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { Logger } from '@vue-storefront/core/lib/logger';
import type RootState from '@vue-storefront/core/types/RootState';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';

import IAttributeState from '../../types/AttributeState';

import * as types from './mutation-types';

const actions: ActionTree<IAttributeState, RootState> = {
  /**
   * Load attributes with specific codes
   * @param {Object} context
   * @param {Array} attrCodes attribute codes to load
   */
  list(
    context,
    {
      filterValues = null,
      filterField = 'attribute_code',
      only_user_defined = false,
      only_visible = true,
      only_filterable = true,
      filterable_in_search = false,
      includeFields = config.entities.optimize ? config.entities.attribute.includeFields : null,
    },
  ) {
    const commit = context.commit;
    const orgFilterValues = filterValues ? [...filterValues] : [];
    let searchQuery = new SearchQuery();

    if (filterValues) {
      filterValues = filterValues.filter((fv) => {
        // check the already loaded
        // return that this attribute is not on our blacklist
        if (context.state.blacklist !== null && context.state.blacklist.includes(fv)) {
          return false;
        }

        if (filterField === 'attribute_id') {
          return typeof context.state.list_by_id[fv] === 'undefined' || context.state.list_by_id[fv] === null;
        }

        if (filterField === 'attribute_code') {
          return typeof context.state.list_by_code[fv] === 'undefined' || context.state.list_by_code[fv] === null;
        }
      });

      if (!filterValues || filterValues.length === 0) {
        Logger.info('Skipping attribute load - attributes already loaded', 'attr', { orgFilterValues, filterField })();
        return Promise.resolve({
          items: Object.values(context.state.list_by_code),
        });
      }

      searchQuery = searchQuery.applyFilter({ key: filterField, value: { in: filterValues } });
    }

    if (only_user_defined) {
      searchQuery = searchQuery.applyFilter({ key: 'is_user_defined', value: { in: [true] } });
    }

    if (only_visible) {
      searchQuery = searchQuery.applyFilter({ key: 'is_visible_on_front', value: { in: [true] } });
    }

    if (only_filterable) {
      searchQuery = searchQuery.applyFilter({ key: 'is_filterable', value: { in: [1, 2] } });
    }

    if (filterable_in_search) {
      searchQuery = searchQuery.applyFilter({ key: 'is_filterable_in_search', value: { in: [1] } });
    }

    return quickSearchByQuery({
      entityType: 'attribute',
      query: searchQuery,
      includeFields: includeFields,
      size: config.zento.theme.category.layeredNavigation.sizeFilterItems,
    }).then((resp) => {
      if (resp && Array.isArray(orgFilterValues) && orgFilterValues.length > 0) {
        const foundValues = resp.items.map((attr) => attr[filterField]);
        const toBlackList = filterValues.filter((ofv) => !foundValues.includes(ofv));
        toBlackList.map((tbl) => {
          if (!context.state.blacklist.includes(tbl)) context.state.blacklist.push(tbl);
        }); // extend the black list of not-found attributes
      }

      commit(types.ATTRIBUTE_UPD_ATTRIBUTES, resp);
    });
  },
};

export default actions;
