import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import { ICheckoutSteps } from '../types/CheckoutSteps';

const getters: GetterTree<ICheckoutSteps, RootState> = {
  getPersonalDetails(state) {
    return state.personalDetails;
  },

  getShipment(state) {
    return state.shipment;
  },

  getPayment(state) {
    return state.payment;
  },

  getReviewOrder(state) {
    return state.reviewOrder;
  },

  getSection(state) {
    return state.section;
  },
};

export default getters;
