import { createModule } from '@vue-storefront/core/lib/module';

import { module } from './store';
import { KEY } from './key';

export { KEY } from './key';

export const ExtendCart = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }] },
});
