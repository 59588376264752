import type { AsyncComponent } from 'vue';

/**
 * Resolve the component of specified page
 *
 * All unique pages have to be added as a switch case to allow static code analysis and correct bundling
 * They will be split on separate bundles due to the async nature of the call.
 */
export function resolvePageComponent(page: string) {
  let componentFactory!: AsyncComponent;

  switch (page) {
    case 'Category':
      componentFactory = () => import('../pages/Category/Category').then((m) => m.Category);
      break;

    case 'Checkout':
      componentFactory = () => import('../pages/Checkout/Checkout').then((m) => m.Checkout);
      break;

    case 'CmsPage':
      componentFactory = () => import('../pages/CmsPage/CmsPage').then((m) => m.CmsPage);
      break;

    case 'Compare':
      componentFactory = () => import('../pages/Compare/Compare').then((m) => m.Compare);
      break;

    case 'Error':
      componentFactory = () => import('../pages/Error/Error').then((m) => m.Error);
      break;

    case 'Home':
      componentFactory = () => import('../pages/Home/Home').then((m) => m.Home);
      break;

    case 'Logout':
      componentFactory = () => import('../pages/Logout/Logout').then((m) => m.Logout);
      break;

    case 'MyAccount':
      componentFactory = () => import('../pages/MyAccount/MyAccount').then((m) => m.MyAccount);
      break;

    case 'NoResults':
      componentFactory = () => import('../pages/NoResults/NoResults').then((m) => m.NoResults);
      break;

    case 'Offline':
      componentFactory = () => import('../pages/Offline/Offline').then((m) => m.Offline);
      break;

    case 'OrderConfirmation':
      componentFactory = () => import('../pages/OrderConfirmation/OrderConfirmation').then((m) => m.OrderConfirmation);
      break;

    case 'PageNotFound':
      componentFactory = () => import('../pages/PageNotFound/PageNotFound').then((m) => m.PageNotFound);
      break;

    case 'Product':
      componentFactory = () => import('../pages/Product/Product').then((m) => m.Product);
      break;

    case 'Redirect':
      componentFactory = () => import('../pages/Redirect/Redirect').then((m) => m.Redirect);
      break;

    case 'Search':
      componentFactory = () => import('../pages/Search/Search').then((m) => m.Search);
      break;

    case 'WidgetList':
      componentFactory = () => import('../pages/WidgetList/WidgetList').then((m) => m.WidgetList);
      break;

    case 'Blog':
      componentFactory = () => import('../pages/Blog/Blog').then((m) => m.Blog);
      break;

    case 'BlogArticle':
      componentFactory = () => import('../pages/Blog/Article/Article').then((m) => m.Article);
      break;

    case 'CartRestore':
      componentFactory = () => import('../pages/CartRestore/CartRestore').then((m) => m.CartRestore);
      break;

    case 'CreateNewPassword':
      componentFactory = () => import('../pages/CreateNewPassword/CreateNewPassword').then((m) => m.CreateNewPassword);
      break;

    case 'CategoriesWidgetList':
      componentFactory = () =>
        import('../pages/CategoriesWidgetList/CategoriesWidgetList').then((m) => m.CategoriesWidgetList);
      break;

    case 'Cart2Quote':
      componentFactory = () => import('../pages/Cart2Quote/Cart2Quote').then((m) => m.Cart2Quote);
      break;

    default:
      throw new Error(`Unknown page ${page}, please consider registering it into resolvePageComponent.`);
  }

  return componentFactory;
}
