import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import ICategoryState from '../../types/CategoryState';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const categoryModule: Module<ICategoryState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    current: {},
    filters: {
      available: {},
      chosen: {},
    },
    breadcrumbs: {
      routes: [],
    },
    current_product_query: {},
    current_path: [], // list of categories from root to current
    productsOrder: [],
  },
  getters,
  actions,
  mutations,
};
