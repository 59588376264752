import config from 'config';
import { RouterManager } from '@vue-storefront/core/lib/router-manager';
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore';
import { Format } from '@zento-lib/components/i18n/inline';

const proceedToCheckoutAction = () => ({
  label: Format.message('cart_go_to_checkout'),
  action: () => RouterManager.getInstance().router.push(localizedRoute('/checkout', currentStoreView().storeCode)),
});

const checkoutAction = () => (!config.externalCheckout ? proceedToCheckoutAction() : null);

const productAddedToCart = () => ({
  type: 'success',
  message: Format.message('product_added_to_cart'),
  action1: { label: Format.message('notification_action') },
  action2: checkoutAction(),
});

const productQuantityUpdated = () => ({
  type: 'success',
  message: Format.message('product_qty_updated'),
  action1: { label: Format.message('notification_action') },
  action2: checkoutAction(),
});

const unsafeQuantity = () => ({
  type: 'warning',
  message: Format.message('product_pre_reservation'),
  action1: { label: Format.message('notification_action') },
});

const outOfStock = () => ({
  type: 'error',
  message: Format.message('cart_product_out_of_stock'),
  action1: { label: Format.message('notification_action') },
});

const productRemovedFromCart = () => ({
  type: 'success',
  message: Format.message('product_remove_from_cart'),
  action1: { label: Format.message('notification_action') },
});

const noStockId = () => ({
  type: 'warning',
  message: Format.message('product_no_stock_id'),
  action1: { label: Format.message('notification_action') },
});

const createNotification = ({ type, message }) => ({
  type,
  message,
  action1: { label: Format.message('notification_action') },
});

const createNotifications = ({ type, messages }) => messages.map((message) => createNotification({ type, message }));

export {
  createNotification,
  createNotifications,
  productAddedToCart,
  productQuantityUpdated,
  unsafeQuantity,
  outOfStock,
  productRemovedFromCart,
  noStockId,
};
