import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import ICategoryState from '../../types/CategoryState';

const getters: GetterTree<ICategoryState, RootState> = {
  getCurrentCategory: (state) => state.current,
  getCurrentCategoryPath: (state) => state.current_path,
  getAllCategoryFilters: (state) => state.filters,
  getActiveCategoryFilters: (state) => state.filters.chosen,
  getAvailableCategoryFilters: (state) => state.filters.available,
  getCurrentCategoryProductQuery: (state) => state.current_product_query,
  getCategories: (state) => state.list,
  getCategoryBreadcrumbs: (state) => state.breadcrumbs,
  getCategoryProductsOrder: (state) => state.productsOrder,
  /**
   * @deprecated use getCurrentCategory instead
   */
  current: (_, getters) => getters.getCurrentCategory,
  /**
   * @deprecated use getCategories instead
   */
  list: (_, getters) => getters.getCategories,
};

export default getters;
