import Component from 'vue-class-component';

// Register custom component hooks
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
  'afterRegistration',
  'beforeRegistration',
  'preAsyncData',
  'asyncData',
  'prefetch',
  'title',
  'meta',
  'metaInfo',
]);
