import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

const getters: GetterTree<any, RootState> = {
  // Add when needed
  getDrawerState(state) {
    return state.drawerActive;
  },

  getAccountState(state) {
    return state.myaccount;
  },
};

export default getters;
