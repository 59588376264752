import storeCodeFromRoute from '@vue-storefront/core/lib/storeCodeFromRoute';
import { BaseComponent, Component, PropSync } from '@zento-lib/components';
// import { Redbox } from '@zento-lib/components/Redbox';
import { AnalyticsServices } from '@zento-lib/components/AnalyticsServices';
import { ViewportObserver } from '@zento/modules/atom/ViewportObserver/Observer';
import { ServiceWorkerUpdater } from '@zento/lib/components/ServiceWorkerUpdater/ServiceWorkerUpdater';

import { resolveLayoutComponent } from './layouts';
import type { IApplication } from './App.d';
import style from './style/index.scss';

@Component({})
export class Application extends BaseComponent<IApplication, unknown> {
  @PropSync('_orderData') orderData!: string[];

  private layoutsCache: Record<string, any> = {};

  /**
   * Layout computed property
   */
  public get layout() {
    let layout: string = this.$route.meta.layout || 'default';
    layout = `${layout.slice(0, 1).toUpperCase() + layout.slice(1)}Layout`;

    // Resolve from cache if possible
    if (!this.layoutsCache[layout]) {
      // Or ensure the layout will be cached for further renders
      this.layoutsCache[layout] = resolveLayoutComponent(layout);
    }

    return this.layoutsCache[layout];
  }

  public metaInfo() {
    return {
      htmlAttrs: {
        lang: this.extended.$config.zento.html.lang || '',
      },
    };
  }

  public render() {
    const Layout = this.layout;

    return (
      <div id='app' class={style.normalize}>
        <ServiceWorkerUpdater />
        <ViewportObserver />
        <Layout key={storeCodeFromRoute(typeof location !== 'undefined' ? location.pathname : '')}>
          <router-view />
        </Layout>
        <AnalyticsServices />
      </div>
    );
  }
}

export default Application;
