import config from 'config';
import { Logger } from '@vue-storefront/core/lib/logger';

import { CartService } from '../../data-resolver/CartService';
import { createDiffLog } from '../../helpers';
import * as types from '../mutation-types';

const connectActions = {
  toggleMicrocart({ commit }) {
    commit(types.CART_TOGGLE_MICROCART);
  },

  /**
   * It will always clear cart items on frontend.
   * Options:
   * sync - if you want to sync it with backend.
   * disconnect - if you want to clear cart token.
   */
  async clear({ commit, dispatch }, { disconnect = true, sync = true } = {}) {
    await commit(types.CART_LOAD_CART, []);

    if (sync) {
      await dispatch('sync', { forceClientState: true, forceSync: true });
    }

    if (disconnect) {
      await commit(types.CART_SET_ITEMS_HASH, null);
      await dispatch('disconnect');
    }
  },

  async disconnect({ commit }) {
    commit(types.CART_LOAD_CART_SERVER_TOKEN, null);
  },

  async authorize({ dispatch, getters }) {
    const coupon = getters.getCoupon.code;

    if (coupon) {
      await dispatch('removeCoupon', { sync: false });
    }

    await dispatch('connect', { guestCart: false, mergeQty: true });

    if (coupon) {
      await dispatch('applyCoupon', coupon);
    }
  },

  async connect(
    { rootState, getters, dispatch, commit },
    { guestCart = false, forceClientState = false, mergeQty = false },
  ) {
    if (!getters.isCartSyncEnabled) {
      return;
    }

    const { result, resultCode } = await CartService.getCartToken(guestCart, forceClientState, rootState.user.token);

    if (resultCode === 200) {
      Logger.info('Server cart token created.', 'cart', result)();
      commit(types.CART_LOAD_CART_SERVER_TOKEN, result);

      return await dispatch('sync', { forceClientState, dryRun: !config.cart.serverMergeByDefault, mergeQty });
    }

    if (resultCode === 401 && getters.bypassCounter < config.queues.maxCartBypassAttempts) {
      Logger.log('Bypassing with guest cart' + getters.bypassCounter, 'cart')();
      commit(types.CART_UPDATE_BYPASS_COUNTER, { counter: 1 });
      Logger.error(result, 'cart')();

      return dispatch('connect', { guestCart: true });
    }

    Logger.warn('Cart sync is disabled by the config', 'cart')();

    return createDiffLog();
  },

  /**
   * Create cart token when there are products in cart and we don't have token already
   */
  async create({ dispatch, getters }) {
    const storedItems = getters.getCartItems || [];
    const cartToken = getters.getCartToken;

    if (storedItems.length && !cartToken) {
      Logger.info('Creating server cart token', 'cart')();

      return await dispatch('connect', { guestCart: false });
    }
  },
};

export default connectActions;
