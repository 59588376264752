import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import { fetchFreeShippingMethods, FetchFreeShippingMethodsMutation } from './operations.graphql';

@Store
export class FreeShippingMethodsStore extends BaseStore {
  /**
   * Determines free shipping methods
   */
  @Field
  private freeShippingMethodsData: FetchFreeShippingMethodsMutation['freeShippingMethods'];

  /**
   * Check online payment status
   */
  public async fetchFreeShippingMethods(token: [string, number]) {
    let cartId = null;
    let quoteId = null;

    if (Object.prototype.toString.call(token) === '[object String]') {
      cartId = token;
    } else {
      quoteId = token;
    }

    return this.mutate(async () => {
      try {
        // Add current store code to header
        this.dataSources.graphQl.customHeaders = {
          ...this.dataSources.graphQl.customHeaders,
          Store: currentStoreView().storeCode || 'default',
        };

        const shippingMethods = await this.dataSources.graphQl.queue({
          ...fetchFreeShippingMethods,
          params: { cartId, quoteId },
        });

        if (shippingMethods) {
          this.freeShippingMethodsData = shippingMethods.freeShippingMethods;
        }
      } catch (e) {
        console.error('Free shipping methods Store (fetchFreeShippingMethods): ', e.message);
      }
    });
  }

  /**
   * Determines free shipping methods
   */
  public get freeShippingMethods() {
    return this.freeShippingMethodsData || [];
  }
}
