import { currentStoreView } from '@vue-storefront/core/lib/multistore';

import type { OrderShippingDetails } from '../types/OrderShippingDetails';
import type { PaymentMethod } from '../types/PaymentMethod';
import type { ShippingMethod } from '../types/ShippingMethod';
import type { CheckoutData } from '../types/CheckoutData';

const getDefaultShippingMethod = (shippingMethods: ShippingMethod[] = []): ShippingMethod => {
  const onlineShippingMethods = shippingMethods.filter((shippingMethod) => !shippingMethod.offline);

  if (!onlineShippingMethods.length) {
    return;
  }

  return onlineShippingMethods.find((shippingMethod) => !!shippingMethod.default) || onlineShippingMethods[0];
};

const getDefaultPaymentMethod = (paymentMethods: PaymentMethod[] = []): PaymentMethod => {
  if (!paymentMethods || !paymentMethods.length) {
    return;
  }

  return paymentMethods.find((item) => item.default) || paymentMethods[0];
};

const createOrderData = ({
  shippingDetails,
  shippingMethods,
  paymentMethods,
  paymentDetails,
  taxCountry = currentStoreView().tax.defaultCountry,
}: CheckoutData): OrderShippingDetails => {
  const country = shippingDetails.country ? shippingDetails.country : taxCountry;
  const shipping = getDefaultShippingMethod(shippingMethods);
  const payment = getDefaultPaymentMethod(paymentMethods);

  return {
    country,
    shippingAddress: {
      firstname: shippingDetails.firstName,
      lastname: shippingDetails.lastName,
      city: shippingDetails.city,
      postcode: shippingDetails.zipCode,
      street: [shippingDetails.streetAddress, shippingDetails.apartmentNumber],
    },
    billingAddress: {
      firstname: paymentDetails.firstName,
      lastname: paymentDetails.lastName,
      city: paymentDetails.city,
      postcode: paymentDetails.zipCode,
      street: [paymentDetails.streetAddress, shippingDetails.apartmentNumber],
      countryId: country,
    },
    method_code: shippingDetails.shippingMethod
      ? shippingDetails.shippingMethod
      : shipping && shipping.method_code
      ? shipping.method_code
      : null,
    carrier_code: shippingDetails.shippingCarrier
      ? shippingDetails.shippingCarrier
      : shipping && shipping.carrier_code
      ? shipping.carrier_code
      : null,
    payment_method: paymentDetails.paymentMethod
      ? paymentDetails.paymentMethod
      : payment && payment.code
      ? payment.code
      : null,
    shipping_method_name:
      shippingDetails.shippingMethodName?.method_title || shippingDetails.shippingMethodName?.carrier_title,
    payment_method_name: paymentDetails.paymentMethodName?.title,
  };
};

export default createOrderData;
