import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import type { ReviewState } from '../types/ReviewState';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const ReviewModule: Module<ReviewState, RootState> = {
  namespaced: true,
  state: {
    items: {},
    reviewItems: {},
    productReview: {},
  },
  getters,
  actions,
  mutations,
};
