import type { VueStorefrontModule } from '@vue-storefront/core/lib/module';
import { Catalog } from 'theme/stores/catalog';
import { Checkout } from 'theme/stores/checkout';
import { Mailer } from '@vue-storefront/core/modules/mailer';
import { Compare } from 'theme/stores/compare';
import { Wishlist } from 'theme/stores/wishlist';
import { Notification } from '@vue-storefront/core/modules/notification';
import { RecentlyViewed } from '@vue-storefront/core/modules/recently-viewed';
import { Url } from '@vue-storefront/core/modules/url';
import { PromotedOffers } from '@vue-storefront/src/modules/promoted-offers';
import { PaymentBackendMethods } from '@vue-storefront/src/modules/payment-backend-methods';
import { InstantCheckout } from '@vue-storefront/src/modules/instant-checkout';
import { OrderHistory } from '@vue-storefront/src/modules/order-history';
import { Cart } from 'theme/stores/cart';
import { ExtendCart } from 'theme/stores/extend-cart';
import { MyAccount } from 'theme/stores/account';
import { CheckoutSteps } from 'theme/stores/checkoutSteps';
import { MyFilters } from 'theme/stores/filters';
import { ApplicationContext } from 'theme/stores/application-context';
import { Review } from 'theme/stores/review';
import { ExtendAddress } from 'theme/stores/extend-address';
import { Ui } from 'theme/stores/ui-store';
import { User } from '@vue-storefront/core/modules/user';

export const registerModules: VueStorefrontModule[] = [
  Checkout,
  Catalog,
  Cart,
  Compare,
  Review,
  Mailer,
  Wishlist,
  Notification,
  Ui,
  RecentlyViewed,
  PromotedOffers,
  PaymentBackendMethods,
  InstantCheckout,
  Url,
  OrderHistory,
  ExtendCart,
  MyAccount,
  CheckoutSteps,
  MyFilters,
  ApplicationContext,
  ExtendAddress,
  User,
];
