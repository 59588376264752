import type { PublicInterface } from '@zento/lib/util/types';

import { defaults } from './defaults';
import type { Operation, NormalizedOperation, ScheduledOperation, RequesterOptions } from './types';

const isServer = typeof window === 'undefined';

/**
 * External Requester surface to be enforced on all implementations
 */
export type MinimalRequester = PublicInterface<
  AbstractRequester<Operation<unknown, unknown, unknown>, RequesterOptions>
>;

export abstract class AbstractRequester<O extends Operation<unknown, unknown, unknown>, Opts extends RequesterOptions> {
  /**
   * Normalized requests queue
   */
  private _queue: Array<NormalizedOperation<any>> = [];

  private _customHeaders: Record<string, string> = {};

  /**
   * Queued operations data structure
   */
  private queued: {
    /**
     * Grouped queued operations (deduplicated by grouping categories)
     */
    groups: { [tokenizedToken: string]: { [tokenizedParams: string]: Array<ScheduledOperation<any>> } };

    /**
     * Fast lookup table by id (scheduled query id to scheduled query)
     */
    lookup: { [id: number]: ScheduledOperation<any> };
  } = { groups: {}, lookup: {} };

  /**
   *
   */
  private nextInterval: number;

  private _options: Opts;

  private nextId = 0;

  public async queue<T extends Operation<unknown, unknown, unknown>>(operation: T): Promise<T['result']> {
    // Restrain from rescheduling when a tick was already scheduled or in SSR contexts
    const warm = this.options.debounce && !!this.unrequestedOperations.length;
    const at = +new Date();
    const normalizedOperation = this.normalizeOperation(operation.operation);
    const normalizedParams = this.normalize(operation.params || ({} as T['params']));

    // Push the new request to the queue after normalization
    const item: NormalizedOperation<T> = {
      at,
      id: this.nextId++,
      params: normalizedParams,
      operation: normalizedOperation,
      result: operation.result,
      resultAt: -Infinity, // Ensure the result is outdated initially,
      fetchAt: -Infinity,
    };

    this._queue.push(item);

    // Tokenize query and params
    const tokenizedOperation = this.tokenizedOperation(normalizedOperation);
    const tokenizedParams = this.tokenizeParams(normalizedParams);

    // Group the request to ensure no duplicate requests will be created in a debounce cycle
    this.queued.groups[tokenizedOperation] = this.queued.groups[tokenizedOperation] || {};
    this.queued.groups[tokenizedOperation][tokenizedParams] =
      this.queued.groups[tokenizedOperation][tokenizedParams] || [];

    const scheduled: Partial<ScheduledOperation<T['result']>> = {
      done: false,
      id: item.id,
      tokenizedParams,
      tokenizedOperation,
    };
    scheduled.p = this.promisify(scheduled);

    this.queued.groups[tokenizedOperation][tokenizedParams].push(scheduled as ScheduledOperation<T['result']>);

    // Register the scheduled operation in the lookup table
    this.queued.lookup[scheduled.id] = scheduled as ScheduledOperation<T['result']>;

    // Push query execution by debounce delay amount to accommodate any further fast occurring queries
    if (this._options.debounce) {
      this.nextInterval = at + this._options.debounceDelay;
    }

    if (!warm) {
      // Schedule a queue revalidation in order to ensure currently added operation will be handled
      if (isServer) {
        await this.schaduleQueueRevalidation();
      } else {
        this.schaduleQueueRevalidation();
      }
    }

    return scheduled.p.p;
  }

  /**
   * Custom headers getter
   */
  public get customHeaders() {
    return { ...this._customHeaders };
  }

  /**
   * Custom headers setter
   */
  public set customHeaders(customHeaders: Record<string, string>) {
    this._customHeaders = customHeaders;
  }

  /**
   * Empty queue notification mechanism
   *
   * Useful when dealing with global level loaders or parent level loaders in conjunction with children that can or do request data
   */
  public drained() {
    return Promise.all(
      this._queue.map((q) =>
        this.queued.lookup[q.id].done ? Promise.resolve(true) : this.queued.lookup[q.id].p.p.finally(() => true),
      ),
    ).then(() => true);
  }

  /**
   * Read only options getter
   */
  protected get options() {
    return { ...this._options };
  }

  /**
   * Request execution mechanism
   *
   * Called by the scheduler in order to generate the requests
   */
  protected abstract request<
    T extends NormalizedOperation<
      O extends { operation: infer OO; params: infer P; result: infer R }
        ? NormalizedOperation<Operation<OO, P, R>>
        : never
    >
  >(options: T): Promise<T['result']>;

  /**
   * Request batch execution mechanism
   *
   * Called by the scheduler in order to generate the requests (batch mode only)
   * The requestBatch is expected to return an array of result in the same order as the operations in input
   */
  protected abstract requestBatch<
    T extends NormalizedOperation<
      O extends { operation: infer OO; params: infer P; result: infer R }
        ? NormalizedOperation<Operation<OO, P, R>>
        : never
    >
  >(...options: T[]): Promise<Array<T['result']>>;

  protected init<T extends Opts>(options?: Partial<T>) {
    // Merge newly received options with defaults
    this._options = {
      ...defaults,
      ...options,
    } as T;

    // Ensure no debouncing in SSR contexts
    this._options.debounce = isServer ? false : this._options.debounce;

    if (this._options.debounce) {
      // Evaluate the stack on next interval only
      this.nextInterval = +new Date() + this._options.debounceDelay;
    } else {
      // Set debounce delay into the past to ensure immediate request handling
      this.nextInterval = -Infinity;
    }

    // Bound tick method to the current instance
    this.tick = this.tick.bind(this);
  }

  /**
   * Normalize received value in order to ensure identical ordering in identical data structures
   */
  protected normalize<V>(v: V) {
    // Null, Undefined, NaN, Boolean, Number, Function, String, Symbol, BigInt
    if (
      v === null ||
      v === undefined ||
      // eslint-disable-next-line no-self-compare
      v !== v || // NaN
      !!v === (v as any) || // Boolean
      typeof v === 'number' ||
      typeof v === 'function' ||
      typeof v === 'string' ||
      typeof v === 'symbol' ||
      typeof v === 'bigint'
    ) {
      return v;
    } else if (Array.isArray(v)) {
      // Sort the array elements
      return v.sort();
    } else if (typeof v === 'object') {
      return Object.keys(v)
        .sort()
        .reduce((ret, k) => {
          ret[k] = this.normalize(v[k]);

          return ret;
        }, {} as V);
    }

    throw new Error('Unhandled value type in Requester normalization');
  }

  /**
   * Operation normalization
   */
  protected abstract normalizeOperation(operation: O['operation']): O['operation'];

  /**
   * Params tokenizer
   */
  protected tokenizeParams(params: NormalizedOperation<O>['params']) {
    return JSON.stringify(params);
  }

  /**
   * Query tokenizer
   */
  protected tokenizedOperation(o: NormalizedOperation<O>['operation']) {
    return JSON.stringify(o);
  }

  /**
   * Registers a loop in order to ensure queued operations are executed
   */
  private async schaduleQueueRevalidation() {
    if (isServer) {
      // SSR rendering will call the tick function directly to ensure promises are created and data is awaited
      await this.tick();
    } else {
      // Register a tick handler on nextAnimationFrame to ensure other work is completed while starting new requests
      window.requestAnimationFrame(this.tick);
    }
  }

  private get unrequestedOperations() {
    return this._queue.filter((o) => !o.requested);
  }

  /**
   * Schedule requests for execution based on debouncing configuration
   */
  private async tick() {
    const now = +new Date();

    if (now >= this.nextInterval) {
      const requestLeads: Array<ScheduledOperation<any>> = [];
      const opDiffParams: { [tokenizedOperation: string]: Array<ScheduledOperation<any>> } = {};
      const normalizedLookup = this._queue.reduce(
        (ret, q) => ({ ...ret, [q.id]: q }),
        {} as Record<NormalizedOperation<any>['id'], NormalizedOperation<any>>,
      );

      // Determine group leads
      Object.keys(this.queued.groups).forEach((q) => {
        // For each parameters format
        Object.keys(this.queued.groups[q]).forEach((qq) => {
          const scheduled = this.queued.groups[q][qq][0];
          const normalized = normalizedLookup[scheduled.id];

          // Group queries of same type with different parameters to facilitate aliasing
          opDiffParams[scheduled.tokenizedOperation] = opDiffParams[scheduled.tokenizedOperation] || [];
          opDiffParams[scheduled.tokenizedOperation].push(scheduled);

          if (normalized && !normalized.requested) {
            // Add all non requested group operations to the operations to be requested
            requestLeads.push(scheduled);
          }
        });
      });

      if (this._options.batchOperations) {
        const operations: Array<{
          lead: ScheduledOperation<any>;
          normalized: NormalizedOperation<any>;
          group: Array<{
            scheduled: ScheduledOperation<any>;
            normalized: NormalizedOperation<any>;
          }>;
        }> = requestLeads.map((q) => {
          const group: Array<{
            scheduled: ScheduledOperation<any>;
            normalized: NormalizedOperation<any>;
          }> = this.queued.groups[q.tokenizedOperation][q.tokenizedParams].map((qq) => ({
            scheduled: qq,
            normalized: normalizedLookup[qq.id],
          }));

          // Mark all queries in group as being requested
          group.forEach((qq) => (qq.normalized.requested = true));

          return {
            lead: group[0].scheduled,
            normalized: group[0].normalized,
            group,
          };
        });

        // Execute all operations in batch
        if (operations.length) {
          await this.requestBatch(...operations.map((o) => o.normalized))
            .then((results: any[]) => operations.forEach((o, i) => o.lead.p.resolve(results[i]))) // Resolve each group's promise on success
            .catch((e) => operations.forEach((o) => o.lead.p.reject(e))) // Reject each group's promise on failure with the same error (single error on batch cases as it's a single operation)
            .finally(() => {
              operations.forEach((o) => {
                // Mark each group as being completed
                o.group.forEach((qq) => (qq.scheduled.done = true));
              });
            });
        }
      } else {
        // Execute each operations group individually
        requestLeads.forEach(async (q) => {
          const group: Array<{
            scheduled: ScheduledOperation<any>;
            normalized: NormalizedOperation<any>;
          }> = this.queued.groups[q.tokenizedOperation][q.tokenizedParams].map((qq) => ({
            scheduled: qq,
            normalized: normalizedLookup[qq.id],
          }));

          // Mark all queries in group as being requested
          group.forEach((qq) => (qq.normalized.requested = true));

          // Execute only the leading query for each group as others are duplicates and can reuse the same result
          await this.request(
            group[0].normalized, // Create a new request with the normalized query and parameters
          )
            .then(
              group[0].scheduled.p.resolve, // Resolve the group's promise on success
            )
            .catch(
              group[0].scheduled.p.reject, // Reject the group's promise on failure
            )
            .finally(() => {
              // Mark the entire group as being completed
              group.forEach((qq) => {
                qq.scheduled.done = true;
              });
            });
        });
      }

      // Clear previously finished queries
      this._queue = this._queue.filter((q) => {
        const scheduled = this.queued.lookup[q.id];

        if (scheduled.done) {
          // Remove references of current item from the lookup table
          delete this.queued.lookup[scheduled.id];

          // Remove the params group if not already removed
          if (this.queued.groups[scheduled.tokenizedOperation]) {
            if (this.queued.groups[scheduled.tokenizedOperation][scheduled.tokenizedParams]) {
              delete this.queued.groups[scheduled.tokenizedOperation][scheduled.tokenizedParams];
            }

            // Remove empty groups
            if (Object.keys(this.queued.groups[scheduled.tokenizedOperation]).length === 0) {
              delete this.queued.groups[scheduled.tokenizedOperation];
            }
          }

          // Omit the item from the queue due to it's completion
          return false;
        }

        // Keep unfinished items
        return true;
      });
    }

    if (this.unrequestedOperations.length) {
      // Ensure interval continuity
      this.schaduleQueueRevalidation();
    }
  }

  /**
   * Generate a promise allowing to wait for the request's resolution
   *
   * When dealing with duplicate queries in the same debounce cycle, the promise of first query will be reused for subsequent queries
   */
  private promisify<R>(q: Partial<ScheduledOperation<R>>): ScheduledOperation<R>['p'] {
    if (
      this.queued.groups[q.tokenizedOperation] &&
      this.queued.groups[q.tokenizedOperation][q.tokenizedParams] &&
      this.queued.groups[q.tokenizedOperation][q.tokenizedParams].length
    ) {
      // Reuse existing promise mechanism from previously registered query
      return this.queued.groups[q.tokenizedOperation][q.tokenizedParams][0].p;
    }

    const p: Partial<ScheduledOperation<R>['p']> = {};
    p.p = new Promise<R>((resolve, reject) => {
      p.reject = reject;
      p.resolve = resolve;
    });

    return p as ScheduledOperation<R>['p'];
  }
}
