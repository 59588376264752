import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import AttributeState from '../../types/AttributeState';

const getters: GetterTree<AttributeState, RootState> = {
  attributeListByCode: (state) => state.list_by_code,
  attributeListById: (state) => state.list_by_id,
};

export default getters;
