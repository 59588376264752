import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

@Store
export class CatalogStore extends BaseStore {
  /**
   * Sort by selection
   */
  @Field
  private sortBy: string;

  /**
   * Determines price range min value
   */
  @Field
  private priceRangeMinValue: number;

  /**
   * Determines price range min value
   */
  @Field
  private priceRangeMaxValue: number;

  /**
   * Determines price range visibility
   */
  @Field
  private priceRange: boolean;

  /**
   * Determines default category sort by
   */
  public defaultSortByValue(sortByValue: string) {
    let sortBy = sortByValue;

    switch (sortBy) {
      case 'latest':
        sortBy = 'updated_at:desc';
        break;
      case 'position':
        sortBy = 'position:asc';
        break;
      case 'position_desc':
        sortBy = 'position:desc';
        break;
      case 'name':
        sortBy = 'name.keyword';
        break;
      case 'name_desc':
        sortBy = 'name.keyword:desc';
        break;
      case 'price':
        sortBy = 'final_price';
        break;
      case 'price_desc':
        sortBy = 'final_price_desc';
        break;
      case 'stock':
        sortBy = 'stock.is_in_stock:desc';
        break;
      case 'rating':
        sortBy = 'rating:desc';
        break;
      case '':
        // Default case
        sortBy = sortByValue;
        break;
    }

    this.sortBy = sortBy;
  }

  public get sortBySelection() {
    return this.sortBy ?? '';
  }

  public set sortBySelection(value: string) {
    this.sortBy = value;
  }

  public get priceRangeMin() {
    return this.priceRangeMinValue ?? 0;
  }

  public set priceRangeMin(value: number) {
    this.priceRangeMinValue = value;
  }

  public get priceRangeMax() {
    return this.priceRangeMaxValue ?? 0;
  }

  public set priceRangeMax(value: number) {
    this.priceRangeMaxValue = value;
  }

  public get priceRangeFilter() {
    return this.priceRange ?? false;
  }

  public set priceRangeFilter(value: boolean) {
    this.priceRange = value;
  }
}
