import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import UniversalStorage from '@vue-storefront/core/store/lib/storage';
import * as localForage from 'localforage';

import {
  fetchCategories,
  fetchConfiguration,
  FetchCategoriesQuery,
  FetchConfigurationQuery,
} from './operations.graphql';

type CookieData = { [categoryTitle: string]: { checked: boolean; required: boolean; description: string } };
type StorageCookieData = { [categoryTitle: string]: boolean };

const ClaimsStorageKey = 'cookiesAccepted';

@Store
export class GDPRStore extends BaseStore {
  /**
   * GDPR categories types
   */
  @Field
  private categoriesData: FetchCategoriesQuery['gdprcategories'];

  /**
   * GDPR claims keeper
   */
  @Field
  private claims: FetchConfigurationQuery['gdprClaims'] | null;

  /**
   * Cookie stored previous user selection
   */
  @Field
  private cookieData: CookieData | null;

  /**
   * Determines acceptance
   */
  @Field
  acceptCookiesData: boolean;

  private storage!: UniversalStorage;

  /**
   * Fetch current GDPR categories
   */
  public async fetchCategories() {
    let categories: FetchCategoriesQuery = { gdprcategories: [] };

    try {
      categories = await this.dataSources.graphQl.queue(fetchCategories);
    } catch (e) {
      console.error('GDPR Store (fetchCategories): ', e);
    }

    this.categoriesData = [...(this.categoriesData || []), ...categories.gdprcategories];

    return this.categoriesData;
  }

  /**
   * Fetch current GDPR claims
   */
  public async fetchClaims() {
    let claims: FetchConfigurationQuery = { gdprClaims: {} };

    try {
      // Add current store code to header
      this.dataSources.graphQl.customHeaders = {
        ...this.dataSources.graphQl.customHeaders,
        Store: currentStoreView().storeCode || 'default',
      };

      claims = await this.dataSources.graphQl.queue(fetchConfiguration);
    } catch (e) {
      console.error('GDPR Store (fetchClaims): ', e);
    }

    this.claims = { ...(this.claims || {}), ...claims.gdprClaims };

    return this.claims;
  }

  /**
   * Get current user accepted GDPR claims
   *
   * When there are no previous user selections registered into the storage, the default selection is computed
   */
  public async getFromStorage() {
    if (!this.context.isServer) {
      if (
        await this.mutate(async () => {
          if (!this.categoriesData) {
            // Fetch categories data if missing
            await this.fetchCategories();
          }

          // Fetch previous data from storage
          const res = await this.storage.getItem(ClaimsStorageKey);

          // Compute default selection
          const defaultSelection = this.categoriesData.reduce(
            (ret, c) => ({
              ...ret,
              [c.title]: {
                checked: !!c.checkedByDefault,
                required: !!c.required,
                description: c.description,
                id: c.categoryId,
              },
            }),
            {} as CookieData,
          );

          this.cookieData = defaultSelection;

          // Overwrite with storage data (if available)
          if (res && res.value) {
            // Enforce the previous user selection
            const stored = res.value as StorageCookieData;

            this.cookieData = this.categoriesData.reduce(
              (ret, c) => ({
                ...ret,
                [c.title]: {
                  checked: stored[c.title],
                  required: !!c.required,
                  description: c.description,
                  id: c.categoryId,
                },
              }),
              this.cookie,
            );

            if (res.value?.fallback) {
              this.cookieData = { ...this.cookieData, fallback: true } as any;
            }
          } else {
            // Mark the cookie as beeing based on fallback values
            this.cookieData = { ...this.cookieData, ...defaultSelection, fallback: true } as any;
          }
        })
      ) {
        // Return the cookie contents on successful fetch
        return this.cookieData;
      }
    }

    return null;
  }

  /**
   * Store the user accepted claims
   */
  public async setInStorage(cookieData: CookieData) {
    this.cookieData = { ...cookieData };

    // Simplify the stored value
    const toStore = this.categories.reduce(
      (ret, c) => ({
        ...ret,
        [c.title]: cookieData[c.title].checked,
      }),
      {} as StorageCookieData,
    );

    return await this.mutate(async () => {
      this.storage.setItem(ClaimsStorageKey, {
        code: ClaimsStorageKey,
        created_at: new Date(),
        value: cookieData.fallback ? Object.assign(toStore, { fallback: true }) : toStore,
      });
    });
  }

  /**
   * GDPR popup configuration getter
   */
  public get popup(): FetchConfigurationQuery['gdprClaims']['popup'] {
    return this.claims
      ? this.claims.popup // Use existing data
      : { acceptance: false, active: false, displayMode: '' }; // Default popup state
  }

  public get allClaims() {
    return this.claims || {};
  }

  /**
   * GDPR supported analytics platforms getter
   */
  public get platforms() {
    return (Object.keys(this.claims || {}) as Array<
      keyof Omit<FetchConfigurationQuery['gdprClaims'], '__typename' | 'popup'>
    >)
      .filter((k) => !['__typename', 'popup'].includes(k))
      .map((k) => this.claims[k]);
  }

  /**
   * Platforms as a map getter
   */
  public get platformsMap() {
    return this.platforms.reduce(
      (ret, p) => ({ ...ret, [p.__typename]: p }),
      {} as {
        [k in FetchConfigurationQuery['gdprClaims'][keyof Omit<
          FetchConfigurationQuery['gdprClaims'],
          '__typename' | 'popup'
        >]['__typename']]: FetchConfigurationQuery['gdprClaims'][keyof Omit<
          FetchConfigurationQuery['gdprClaims'],
          '__typename' | 'popup'
        >];
      },
    );
  }

  /**
   * Previously stored user selection getter
   */
  public get cookie() {
    if (this.cookieData) {
      Object.keys(this.cookieData).forEach((key) => {
        const replacedKey = key.replace(/ /g, '_');

        if (key !== replacedKey) {
          this.cookieData[replacedKey] = this.cookieData[key];
        }
      });
    }

    return this.cookieData || null;
  }

  /**
   * Categories getter
   */
  public get categories() {
    return this.categoriesData || [];
  }

  /**
   * Accept cookies getter
   */
  public get acceptCookies() {
    return this.acceptCookiesData || false;
  }

  /**
   * Accept cookies setter
   */
  public set acceptCookies(accept: boolean) {
    this.acceptCookiesData = accept;
  }

  public get commerceConnector(): FetchConfigurationQuery['gdprClaims']['commerceConnector'] {
    return this.claims
      ? this.claims.commerceConnector // Use existing data
      : {
          __typename: 'CommerceConnector',
          active: false,
          id: '',
        }; // Default commerce connector state
  }

  /**
   * Grab a UniversalStorage reference at registration time
   */
  protected beforeRegistration() {
    const storeView = currentStoreView();
    const dbNamePrefix = storeView.storeCode ? storeView.storeCode + '-' : '';
    const config = this.context.config;

    this.storage = new UniversalStorage(
      localForage.createInstance({
        name: (config.storeViews.commonCache ? '' : dbNamePrefix) + 'shop',
        storeName: 'claims',
        driver: localForage[config.localForage.defaultDrivers.claims],
      }),
    );
  }
}
