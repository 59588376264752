import Vue from 'vue';
import { ActionTree } from 'vuex';
import config from 'config';
import omit from 'lodash-es/omit';
import uniqBy from 'lodash-es/uniqBy';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { Logger } from '@vue-storefront/core/lib/logger';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import { quickSearchByQuery, isOnline } from '@vue-storefront/core/lib/search';
import rootStore from '@vue-storefront/core/store';
import type RootState from '@vue-storefront/core/types/RootState';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import { entityKeyName } from '@vue-storefront/core/store/lib/entities';
import { formatBreadCrumbRoutes } from '@vue-storefront/core/helpers';
import { CatalogStore } from 'theme/stores/catalog/catalogStore';
// import { RouterManager } from '@vue-storefront/core/lib/router-manager';

import { configureProductAsync } from '../../store/category/helpers/configurableChilds';
import {
  doPlatformPricesSync,
  filterOutUnavailableVariants,
  calculateTaxes,
  populateProductConfigurationAsync,
  setCustomProductOptionsAsync,
  setBundleProductOptionsAsync,
  getMediaGallery,
  configurableChildrenImages,
  attributeImages,
} from '../../helpers';
import { optionLabel } from '../../helpers/optionLabel';
import IProductState from '../../types/ProductState';
import {
  canCache,
  preConfigureProduct,
  configureChildren,
  getOptimizedFields,
  storeProductToCache,
} from '../../helpers/search';
import type { TopNavigationData } from '../../../navigation/types';

import * as types from './mutation-types';

const PRODUCT_REENTER_TIMEOUT = 20000;

const actions: ActionTree<IProductState, RootState> = {
  /**
   * Reset current configuration and selected variants
   */
  reset(context) {
    const productOriginal = context.getters.productOriginal;
    context.commit(types.CATALOG_RESET_PRODUCT, productOriginal);
  },
  /**
   * Setup product breadcrumbs path
   */
  async setupBreadcrumbs(context, { product }) {
    let breadcrumbName = null;
    const setBreadcrumbRoutesFromPath = (paths: TopNavigationData[]) => {
      if (
        paths.findIndex((itm) => {
          return itm.slug === context.rootGetters['category/getCurrentCategory'].slug;
        }) < 0
      ) {
        paths.push({
          url_path: context.rootGetters['category/getCurrentCategory'].url_path,
          slug: context.rootGetters['category/getCurrentCategory'].slug,
          name: context.rootGetters['category/getCurrentCategory'].name,
        }); // current category at the end
      }

      breadcrumbName = product.name;
      // Eliminate root category
      paths = paths.slice(1);

      const breadcrumbs = {
        routes: formatBreadCrumbRoutes(paths),
        current: breadcrumbName,
        name: breadcrumbName,
      };

      context.commit(types.CATALOG_SET_BREADCRUMBS, breadcrumbs);
    };

    if (product.category && product.category.length > 0) {
      const categoryIds = (product.category as Array<Record<string, string | number>>)
        .reverse()
        .map((cat) => cat.category_id);

      await context
        .dispatch('category/list', { key: 'id', value: categoryIds }, { root: true })
        .then(async (categories) => {
          let catList: TopNavigationData[] = [];
          // Filter out not active and not included in menu categories
          const items: TopNavigationData[] = categories.items.filter(
            (itm: TopNavigationData) => itm.is_active && itm.include_in_menu === 1,
          );

          for (const catId of categoryIds) {
            const category = items.find((itm) => {
              return itm.id.toString() === catId.toString();
            });

            if (category) {
              catList.push(category);
            }
          }

          let rootCat = catList.find((c) => c.url_path === (context.rootState.category.current as any).url_path);

          if (!rootCat) {
            rootCat = catList.shift();
          }

          // Sort categories ASC by their level
          catList = catList.sort((a, b) => a.level - b.level);
          let catForBreadcrumbs = rootCat;

          for (const cat of catList) {
            const catPath = cat.path;
            if (
              catPath &&
              catPath.includes(rootCat.path) &&
              catPath.split('/').length > catForBreadcrumbs.path.split('/').length
            ) {
              catForBreadcrumbs = cat;
            }
          }

          if (typeof catForBreadcrumbs !== 'undefined') {
            await context
              .dispatch('category/single', { key: 'id', value: catForBreadcrumbs.id }, { root: true })
              .then(() => {
                // this sets up category path and current category
                setBreadcrumbRoutesFromPath(context.rootGetters['category/getCurrentCategoryPath']);
              })
              .catch((err) => {
                setBreadcrumbRoutesFromPath(context.rootGetters['category/getCurrentCategoryPath']);
                console.error(err);
              });
          } else {
            setBreadcrumbRoutesFromPath(context.rootGetters['category/getCurrentCategoryPath']);
          }
        });
    }
  },
  doPlatformPricesSync(context, { products }) {
    return doPlatformPricesSync(products);
  },
  /**
   * Download Magento2 / other platform prices to put them over ElasticSearch prices
   */
  syncPlatformPricesOver(context, { skus }) {
    const storeView = currentStoreView();
    return TaskQueue.execute({
      url:
        config.products.endpoint +
        '/render-list?skus=' +
        encodeURIComponent(skus.join(',')) +
        '&currencyCode=' +
        encodeURIComponent(storeView.i18n.currencyCode) +
        '&storeId=' +
        encodeURIComponent(storeView.storeId), // sync the cart
      payload: {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
      },
      callback_event: 'prices-after-sync',
    }).then((task: any) => {
      return task.result;
    });
  },
  /**
   * Setup associated products
   */
  setupAssociated(context, { product, skipCache = true }) {
    const subloaders = [];
    if (product.type_id === 'grouped') {
      product.price = 0;
      product.priceInclTax = 0;
      Logger.debug(product.name + ' SETUP ASSOCIATED', product.type_id)();
      if (product.product_links && product.product_links.length > 0) {
        for (const pl of product.product_links) {
          if (pl.link_type === 'associated' && pl.linked_product_type === 'simple') {
            // prefetch links
            Logger.debug('Prefetching grouped product link for ' + pl.sku + ' = ' + pl.linked_product_sku)();
            subloaders.push(
              context
                .dispatch('single', {
                  options: { sku: pl.linked_product_sku },
                  setCurrentProduct: false,
                  selectDefaultVariant: false,
                  skipCache: skipCache,
                })
                .catch((err) => {
                  Logger.error(err);
                })
                .then((asocProd) => {
                  if (asocProd) {
                    pl.product = asocProd;
                    pl.product.qty = 1;
                    product.price += pl.product.price;
                    product.priceInclTax += pl.product.priceInclTax;
                    product.tax += pl.product.tax;
                  } else {
                    Logger.error('Product link not found', pl.linked_product_sku)();
                  }
                }),
            );
          }
        }
      } else {
        Logger.error('Product with type grouped has no product_links set!', product)();
      }
    }

    if (product.type_id === 'bundle') {
      product.price = 0;
      product.priceInclTax = 0;

      Logger.debug(product.name + ' SETUP ASSOCIATED', product.type_id)();
      if (product.bundle_options && product.bundle_options.length > 0) {
        product.bundle_options.map((bo) => {
          let defaultOption = bo.product_links.find((p) => {
            return p.is_default;
          });
          if (!defaultOption) defaultOption = bo.product_links[0];

          bo.product_links.map((pl) => {
            Logger.debug('Prefetching bundle product link for ' + bo.sku + ' = ' + pl.sku)();
            subloaders.push(
              context
                .dispatch('single', {
                  options: { sku: pl.sku },
                  setCurrentProduct: false,
                  selectDefaultVariant: false,
                  skipCache: skipCache,
                })
                .catch((err) => {
                  Logger.error(err);
                })
                .then((asocProd) => {
                  if (asocProd) {
                    pl.product = asocProd;
                    pl.product.qty = pl.qty;
                  } else {
                    Logger.error('Product link not found', pl.sku)();
                  }
                }),
            );
          });
        });
      }
    }

    if (product.type_id === 'configurable') {
      const selectedVariant = configureProductAsync(context, {
        product: product,
        configuration: {},
        selectDefaultVariant: false,
      });

      Object.assign(product, omit(selectedVariant, ['url_path', 'visibility']));
    }

    return Promise.all(subloaders);
  },
  /**
   * This is fix for https://github.com/DivanteLtd/vue-storefront/issues/508
   * TODO: probably it would be better to have "parent_id" for simple products
   * Or to just ensure configurable variants are not visible in categories/search
   */
  checkConfigurableParent(context, { product }) {
    if (product.type_id === 'simple') {
      Logger.log('Checking configurable parent')();

      let searchQuery = new SearchQuery();
      searchQuery = searchQuery.applyFilter({
        key: 'configurable_children.sku',
        value: { eq: context.state.current.sku },
      });

      return context
        .dispatch('list', { query: searchQuery, start: 0, size: 1, updateState: false })
        .then((resp) => {
          if (resp.items.length >= 1) {
            const parentProduct = resp.items[0];
            context.commit(types.CATALOG_SET_PRODUCT_PARENT, parentProduct);
          }
        })
        .catch((err) => {
          Logger.error(err)();
        });
    }
  },
  /**
   * Load required configurable attributes
   * @param context
   * @param product
   */
  loadConfigurableAttributes(context, { product }) {
    let attributeKey = 'attribute_id';
    const configurableAttrKeys = product.configurable_options.map((opt) => {
      if (opt.attribute_id) {
        attributeKey = 'attribute_id';
        return opt.attribute_id;
      } else {
        attributeKey = 'attribute_code';
        return opt.attribute_code;
      }
    });

    return context.dispatch(
      'attribute/list',
      {
        filterValues: configurableAttrKeys,
        filterField: attributeKey,
      },
      { root: true },
    );
  },
  /**
   * Setup product current variants
   */
  setupVariants(context, { product }) {
    const subloaders = [];
    if (product.type_id === 'configurable' && 'configurable_options' in product) {
      subloaders.push(
        context
          .dispatch('product/loadConfigurableAttributes', { product }, { root: true })
          .then(() => {
            context.state.current_options = {};
            for (const option of product.configurable_options) {
              if (!('position' in option)) {
                option.position =
                  (context.rootState.attribute as any)?.list_by_id?.[option.attribute_id]?.position || 0;
              }

              if ('values' in option && option.values.length) {
                for (const ov of option.values) {
                  const ol = optionLabel(context.rootState.attribute, {
                    attributeKey: option.attribute_id,
                    searchBy: 'id',
                    optionId: ov.value_index,
                  });

                  const lb = ov.label ? ov.label : ol.label;

                  if (lb.trim() !== '') {
                    const optionKey = option.attribute_code ? option.attribute_code : option.label.toLowerCase();

                    if (!context.state.current_options[optionKey]) {
                      context.state.current_options[optionKey] = [];
                    }

                    context.state.current_options[optionKey].push({
                      label: lb,
                      id: ov.value_index,
                      attribute_code: option.attribute_code,
                      position: ol.order ?? option.position ?? 0,
                    });
                  }
                }
              }
            }
            Vue.set(context.state, 'current_options', context.state.current_options);
            const selectedVariant = context.state.current;
            populateProductConfigurationAsync(context, { selectedVariant: selectedVariant, product: product });
          })
          .catch((err) => {
            Logger.error(err)();
          }),
      );
    }

    return Promise.all(subloaders);
  },
  filterUnavailableVariants(context, { product }) {
    return filterOutUnavailableVariants(context, product);
  },

  /**
   * Search ElasticSearch catalog of products using simple text query
   * Use bodybuilder to build the query, aggregations etc: http://bodybuilder.js.org/
   * @param {Object} query is the object of searchQuery class
   * @param {Int} start start index
   * @param {Int} size page size
   * @return {Promise}
   */
  list(
    context,
    {
      query,
      start = 0,
      size = 50,
      entityType = 'product',
      sort = '',
      cacheByKey = 'sku',
      prefetchGroupProducts = !Vue.prototype.$isServer,
      updateState = false,
      meta = {},
      excludeFields = null,
      configuration = null,
      append = false,
      populateRequestCacheTags = true,
      order = {},
    },
  ) {
    const isCacheable = excludeFields === null;
    if (isCacheable) {
      Logger.debug('Entity cache is enabled for productList')();
    } else {
      Logger.debug('Entity cache is disabled for productList')();
    }

    if (rootStore.state.config.entities.optimize) {
      if (excludeFields === null) {
        // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        // excludeFields = rootStore.state.config.entities.product.excludeFields;
      }
    }

    // Determines products in stock
    if (config.zento.theme.category.listOutOfStockProducts === false) {
      query = query.applyFilter({ key: 'stock.is_in_stock', value: { eq: true } });
    }

    /**
     * Determines price range values
     */
    const catalogStore = new CatalogStore();
    const priceSliderAttribute = config.zento.theme.category.layeredNavigation.priceSliderAttribute;

    catalogStore.priceRangeFilter = false;

    rootStore
      .dispatch('product/priceRangeSingle', {
        query: query,
        sort: 'final_price',
      })
      .then((res) => {
        if (res && res.items.length) {
          catalogStore.priceRangeMin = Math.floor(
            Math.min(
              ...res.items.map((attribute) => {
                return attribute[priceSliderAttribute];
              }),
            ),
          );
        }
      });

    rootStore
      .dispatch('product/priceRangeSingle', {
        query: query,
        sort: 'final_price:desc',
      })
      .then((res) => {
        if (res && res.items.length) {
          catalogStore.priceRangeMax = Math.floor(
            Math.max(
              ...res.items.map((attribute) => {
                return attribute[priceSliderAttribute];
              }),
            ),
          );

          catalogStore.priceRangeFilter = true;
        }
      });

    return quickSearchByQuery({ query, start, size, entityType, sort, excludeFields }).then((resp) => {
      if (resp.items && resp.items.length) {
        let items = resp.items;

        if (sort.includes('position') && Object.keys(order).length) {
          items = items.sort((a, b) =>
            rootStore.state.config.entities.product.sortOrder === 'DESC'
              ? order[b.id] - order[a.id]
              : order[a.id] - order[b.id],
          );
        }

        // preconfigure products; eg: after filters
        for (const product of items) {
          if (populateRequestCacheTags && Vue.prototype.$ssrRequestContext) {
            Vue.prototype.$ssrRequestContext.output.cacheTags.add(`P${product.id}`);
          }
          product.errors = {}; // this is an object to store validation result for custom options and others
          product.info = {};

          if (!product.parentSku) {
            product.parentSku = product.sku;
          }

          // set product quantity
          if (!product.qty) {
            product.qty = product.stock.min_sale_qty > 1 ? product.stock.min_sale_qty : 1;
          }

          if (
            rootStore.state.config.products.setFirstVarianAsDefaultInURL &&
            'configurable_children' in product &&
            product.configurable_children.length > 0
          ) {
            product.sku = product.configurable_children[0].sku;
          }
          if (configuration) {
            const selectedVariant = configureProductAsync(context, {
              product: product,
              configuration: configuration,
              selectDefaultVariant: false,
            });
            Object.assign(product, omit(selectedVariant, ['url_path', 'visibility']));
          }
          if (product.url_path) {
            rootStore.dispatch(
              'url/registerMapping',
              {
                url: product.url_path,
                routeData: {
                  params: {
                    parentSku: product.parentSku,
                    slug: product.slug,
                  },
                  name: product.type_id + '-product',
                },
              },
              { root: true },
            );
          }
        }
      }

      return calculateTaxes(resp.items, context).then(() => {
        // handle cache
        const cache = Vue.prototype.$db.elasticCacheCollection;

        // commit update products list mutation
        if (updateState) {
          context.commit(types.CATALOG_UPD_PRODUCTS, { products: resp, append: append });
        }
        EventBus.$emit('product-after-list', {
          query: query,
          start: start,
          size: size,
          sort: sort,
          entityType: entityType,
          meta: meta,
          result: resp,
        });

        for (const prod of resp.items) {
          // we store each product separately in cache to have offline access to products/single method
          if (prod.configurable_children) {
            for (const configurableChild of prod.configurable_children) {
              if (configurableChild.custom_attributes) {
                for (const opt of configurableChild.custom_attributes) {
                  configurableChild[opt.attribute_code] = opt.value;
                }
              }
            }
          }

          if (!prod[cacheByKey]) {
            cacheByKey = 'id';
          }

          // to avoid caching products by configurable_children.sku
          const cacheKey = entityKeyName(
            cacheByKey,
            prod[cacheByKey === 'sku' && prod.parentSku ? 'parentSku' : cacheByKey],
          );

          if (isCacheable) {
            // store cache only for full loads
            cache.setItem(cacheKey, prod).catch((err) => {
              Logger.error('Cannot store cache for ' + cacheKey, err);
            });
          }

          if (prod.type_id === 'grouped' && prefetchGroupProducts && !Vue.prototype.$isServer) {
            context.dispatch('setupAssociated', { product: prod });
          }
        }

        return resp;
      });
    });
  },

  /**
   * Search ElasticSearch catalog of products using simple text query
   * Use bodybuilder to build the query, aggregations etc: http://bodybuilder.js.org/
   */
  priceRangeSingle(context, { query, start = 0, size = 1, entityType = 'product', sort = '', excludeFields = null }) {
    const isCacheable = excludeFields === null;

    if (isCacheable) {
      Logger.debug('Entity cache is enabled for productList')();
    } else {
      Logger.debug('Entity cache is disabled for productList')();
    }

    if (rootStore.state.config.entities.optimize) {
      if (excludeFields === null) {
        // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        // excludeFields = rootStore.state.config.entities.product.excludeFields;
      }
    }

    // Determines products in stock
    if (config.zento.theme.category.listOutOfStockProducts === false) {
      query = query.applyFilter({ key: 'stock.is_in_stock', value: { eq: true } });
    }

    return quickSearchByQuery({ query, start, size, entityType, sort, excludeFields }).then((resp) => {
      return resp;
    });
  },

  /**
   * Update associated products for bundle product
   * @param context
   * @param product
   */
  configureBundleAsync(context, product) {
    return context
      .dispatch('setupAssociated', {
        product: product,
        skipCache: true,
      })
      .then(() => {
        context.dispatch('setCurrent', product);
      })
      .then(() => {
        EventBus.$emit('product-after-setup-associated');
      });
  },

  /**
   * Update associated products for group product
   * @param context
   * @param product
   */
  configureGroupedAsync(context, product) {
    return context
      .dispatch('setupAssociated', {
        product: product,
        skipCache: true,
      })
      .then(() => {
        context.dispatch('setCurrent', product);
      });
  },

  /**
   * Search product by specific field for submenu
   * @param {Object} options
   */
  async featureProduct(
    context,
    {
      options,
      setCurrentProduct = true,
      selectDefaultVariant = true,
      assignDefaultVariant = false,
      key = 'sku',
      skipCache = false,
    },
  ) {
    if (!options[key]) {
      throw Error('Please provide the search key ' + key + ' for product/single action!');
    }
    const cacheKey = entityKeyName(key, options[key]);

    return new Promise((resolve, reject) => {
      const benchmarkTime = new Date();
      const cache = Vue.prototype.$db.elasticCacheCollection;

      const setupProduct = (prod) => {
        // set product quantity
        if (!prod.qty) {
          prod.qty = prod.stock.min_sale_qty > 1 ? prod.stock.min_sale_qty : 1;
        }

        // check is prod has configurable children
        const hasConfigurableChildren = prod && prod.configurable_children && prod.configurable_children.length;
        if (prod.type_id === 'simple' && hasConfigurableChildren) {
          // workaround for #983
          prod = omit(prod, ['configurable_children', 'configurable_options']);
        }

        // set current product - configurable or not
        if (prod.type_id === 'configurable' && hasConfigurableChildren) {
          // set first available configuration
          // todo: probably a good idea is to change this [0] to specific id
          const selectedVariant = configureProductAsync(context, {
            product: prod,
            configuration: { sku: options.childSku ?? options.sku },
            selectDefaultVariant: selectDefaultVariant,
            setProductErorrs: true,
          });

          if (selectedVariant && assignDefaultVariant) {
            prod = Object.assign(prod, omit(selectedVariant, ['visibility']));
          }
        }

        return prod;
      };

      const syncProducts = () => {
        let searchQuery = new SearchQuery();
        searchQuery = searchQuery.applyFilter({ key: key, value: { eq: options[key] } });

        return context
          .dispatch('list', {
            // product list syncs the platform price on it's own
            query: searchQuery,
            prefetchGroupProducts: false,
            updateState: false,
          })
          .then((res) => {
            if (res && res.items && res.items.length) {
            } else {
              reject(new Error('Product query returned empty result'));
            }
          });
      };

      const getProductFromCache = () => {
        cache.getItem(cacheKey, (err, res) => {
          // report errors
          if (!skipCache && err) {
            Logger.error(err, 'product')();
          }

          if (res !== null) {
            Logger.debug(
              'Product:single - result from localForage (for ' +
                cacheKey +
                '),  ms=' +
                (new Date().getTime() - benchmarkTime.getTime()),
              'product',
            )();
            const _returnProductFromCacheHelper = () => {
              const cachedProduct = setupProduct(res);
              resolve(cachedProduct);
            };

            if (setCurrentProduct || selectDefaultVariant) {
              const subConfigPromises = [];
              subConfigPromises.push(context.dispatch('setupVariants', { product: res }));

              Promise.all(subConfigPromises).then(_returnProductFromCacheHelper);
            } else {
              _returnProductFromCacheHelper();
            }
          } else {
            syncProducts();
          }
        });
      };

      if (!skipCache) {
        getProductFromCache();
      } else {
        if (!isOnline()) {
          skipCache = false;
        }

        syncProducts();
      }
    });
  },

  /**
   * Search products by specific field
   * @param {Object} options
   */
  async single(
    context,
    {
      options,
      setCurrentProduct = true,
      selectDefaultVariant = true,
      assignDefaultVariant = false, // TODO: see if needed to change the flag
      key = 'sku',
      skipCache = false,
    },
  ) {
    if (!options[key]) {
      throw Error('Please provide the search key ' + key + ' for product/single action!');
    }
    const cacheKey = entityKeyName(key, options[key]);

    return new Promise((resolve, reject) => {
      const benchmarkTime = new Date();
      const cache = Vue.prototype.$db.elasticCacheCollection;

      const setupProduct = (prod) => {
        // set product quantity
        if (!prod.qty) {
          prod.qty = prod.stock.min_sale_qty > 1 ? prod.stock.min_sale_qty : 1;
        }
        // set original product
        if (setCurrentProduct) {
          context.dispatch('setOriginal', prod);
        }
        // check is prod has configurable children
        const hasConfigurableChildren = prod && prod.configurable_children && prod.configurable_children.length;
        if (prod.type_id === 'simple' && hasConfigurableChildren) {
          // workaround for #983
          prod = omit(prod, ['configurable_children', 'configurable_options']);
        }

        // set current product - configurable or not
        if (prod.type_id === 'configurable' && hasConfigurableChildren) {
          // set first available configuration
          // todo: probably a good idea is to change this [0] to specific id
          const selectedVariant = configureProductAsync(context, {
            product: prod,
            configuration: { sku: options.childSku },
            selectDefaultVariant: selectDefaultVariant,
            setProductErorrs: true,
          });

          if (selectedVariant && assignDefaultVariant) {
            prod = Object.assign(prod, omit(selectedVariant, ['visibility']));
          }
        } else if (!skipCache || prod.type_id === 'simple' || prod.type_id === 'downloadable') {
          if (setCurrentProduct) context.dispatch('setCurrent', prod);
        }

        return prod;
      };

      const syncProducts = () => {
        let searchQuery = new SearchQuery();
        searchQuery = searchQuery.applyFilter({ key: key, value: { eq: options[key] } });

        return context
          .dispatch('list', {
            // product list syncs the platform price on it's own
            query: searchQuery,
            prefetchGroupProducts: false,
            updateState: false,
          })
          .then((res) => {
            if (res && res.items && res.items.length) {
              const prd = res.items[0];
              const _returnProductNoCacheHelper = () => {
                EventBus.$emitFilter('product-after-single', { key: key, options: options, product: prd });
                resolve(setupProduct(prd));
              };

              if (setCurrentProduct || selectDefaultVariant) {
                const subConfigPromises = [];
                if (prd.type_id === 'bundle') {
                  subConfigPromises.push(context.dispatch('configureBundleAsync', prd));
                }

                if (prd.type_id === 'grouped') {
                  subConfigPromises.push(context.dispatch('configureGroupedAsync', prd));
                }

                subConfigPromises.push(context.dispatch('setupVariants', { product: prd }));
                Promise.all(subConfigPromises).then(_returnProductNoCacheHelper);
              } else {
                _returnProductNoCacheHelper();
              }
            } else {
              reject(new Error('Product query returned empty result'));
            }
          });
      };

      const getProductFromCache = () => {
        cache.getItem(cacheKey, (err, res) => {
          // report errors
          if (!skipCache && err) {
            Logger.error(err, 'product')();
          }

          if (res !== null) {
            Logger.debug(
              'Product:single - result from localForage (for ' +
                cacheKey +
                '),  ms=' +
                (new Date().getTime() - benchmarkTime.getTime()),
              'product',
            )();
            const _returnProductFromCacheHelper = () => {
              const cachedProduct = setupProduct(res);
              if (config.products.alwaysSyncPlatformPricesOver) {
                doPlatformPricesSync([cachedProduct]).then((products) => {
                  EventBus.$emitFilter('product-after-single', {
                    key: key,
                    options: options,
                    product: products[0],
                  });
                  resolve(products[0]);
                });

                if (!config.products.waitForPlatformSync) {
                  EventBus.$emitFilter('product-after-single', {
                    key: key,
                    options: options,
                    product: cachedProduct,
                  });
                  resolve(cachedProduct);
                }
              } else {
                EventBus.$emitFilter('product-after-single', {
                  key: key,
                  options: options,
                  product: cachedProduct,
                });
                resolve(cachedProduct);
              }
            };

            if (setCurrentProduct || selectDefaultVariant) {
              const subConfigPromises = [];
              subConfigPromises.push(context.dispatch('setupVariants', { product: res }));

              if (res.type_id === 'bundle') {
                subConfigPromises.push(context.dispatch('configureBundleAsync', res));
              }
              if (res.type_id === 'grouped') {
                subConfigPromises.push(context.dispatch('configureGroupedAsync', res));
              }
              Promise.all(subConfigPromises).then(_returnProductFromCacheHelper);
            } else {
              _returnProductFromCacheHelper();
            }
          } else {
            syncProducts();
          }
        });
      };

      if (!skipCache) {
        getProductFromCache();
      } else {
        if (!isOnline()) {
          skipCache = false;
        }

        syncProducts();
      }
    });
  },
  /**
   * Configure product with given configuration and set it as current
   * @param {Object} context
   * @param {Object} product
   * @param {Array} configuration
   */
  configure(
    context,
    { product = null, configuration, selectDefaultVariant = true, fallbackToDefaultWhenNoAvailable = true },
  ) {
    return configureProductAsync(context, {
      product: product,
      configuration: configuration,
      selectDefaultVariant: selectDefaultVariant,
      fallbackToDefaultWhenNoAvailable: fallbackToDefaultWhenNoAvailable,
    });
  },

  setCurrentOption(context, productOption) {
    if (productOption && typeof productOption === 'object') {
      // TODO: this causes some kind of recurrence error
      context.commit(
        types.CATALOG_SET_PRODUCT_CURRENT,
        Object.assign({}, context.state.current, {
          product_option: productOption,
        }),
      );
    }
  },

  setCurrentErrors(context, errors) {
    if (errors && typeof errors === 'object') {
      context.commit(types.CATALOG_SET_PRODUCT_CURRENT, Object.assign({}, context.state.current, { errors: errors }));
    }
  },
  /**
   * Assign the custom options object to the current product
   */
  setCustomOptions(context, { customOptions, product }) {
    if (customOptions) {
      // TODO: this causes some kind of recurrence error
      context.commit(
        types.CATALOG_SET_PRODUCT_CURRENT,
        Object.assign({}, product, {
          product_option: setCustomProductOptionsAsync(context, {
            product: context.state.current,
            customOptions: customOptions,
          }),
        }),
      );
    }
  },
  /**
   * Assign the bundle options object to the variant product
   */
  setBundleOptions(context, { bundleOptions, product }) {
    if (bundleOptions) {
      // TODO: this causes some kind of recurrence error
      context.commit(
        types.CATALOG_SET_PRODUCT_CURRENT,
        Object.assign({}, product, {
          product_option: setBundleProductOptionsAsync(context, {
            product: context.state.current,
            bundleOptions: bundleOptions,
          }),
        }),
      );
    }
  },
  /**
   * Set current product with given variant's properties
   * @param {Object} context
   * @param {Object} productVariant
   */
  setCurrent(context, productVariant) {
    if (productVariant && typeof productVariant === 'object') {
      // get original product
      const productOriginal = context.getters.productOriginal;

      // check if passed variant is the same as original
      const productUpdated = Object.assign({}, productOriginal, productVariant);
      populateProductConfigurationAsync(context, { product: productUpdated, selectedVariant: productVariant });

      if (
        !config.products.gallery.mergeConfigurableChildren ||
        config.zento.theme.productPage.galleryMergeConfigurableChildren
      ) {
        context.commit(types.CATALOG_UPD_GALLERY, attributeImages(productVariant, productUpdated.configurable_options));
      }

      // TODO: [ZENDEL-423] temporarily hardcoded "stock_id" for those products that do not have one if MSI enabled
      // "stock_id" should be provided by backend from vsbridge on each child of configurable product
      if (
        config.zento.theme.stock.msi.enabled &&
        productUpdated.type_id === 'configurable' &&
        !productUpdated.stock.stock_id
      ) {
        productUpdated.stock.stock_id = 2;
      }

      context.commit(types.CATALOG_SET_PRODUCT_CURRENT, productUpdated);

      return productUpdated;
    } else Logger.debug('Unable to update current product.', 'product')();
  },
  /**
   * Set given product as original
   * @param {Object} context
   * @param {Object} originalProduct
   */
  setOriginal(context, originalProduct) {
    if (originalProduct && typeof originalProduct === 'object') {
      context.commit(types.CATALOG_SET_PRODUCT_ORIGINAL, originalProduct);
    } else {
      Logger.debug('Unable to setup original product.', 'product')();
    }
  },
  /**
   * Set related products
   */
  related(context, { key = 'related-products', items }) {
    context.commit(types.CATALOG_UPD_RELATED, { key, items });
  },

  /**
   * Load the product data
   */
  fetch(context, { parentSku, childSku = null, skipCache = false }) {
    // pass both id and sku to render a product
    const productSingleOptions = {
      sku: parentSku,
      childSku: childSku,
    };

    return context.dispatch('single', { options: productSingleOptions, skipCache: skipCache }).then((product) => {
      if (product.status >= 2) {
        throw new Error(`Product query returned empty result product status = ${product.status}`);
      }

      if (config.zento.theme.productPage.customStatus && product.visibility === 1) {
        context.commit(types.CATALOG_SET_PRODUCT_CURRENT, Object.assign({}, product, { custom_status: true }));
      } else if (product.visibility === 1) {
        // not visible individually
        console.error(`Product query returned empty result product visibility = ${product.visibility}`);
        // RouterManager.getInstance().router.push('/page-not-found'); // TODO: see if a better solution is needed
      }

      const subloaders = [];
      if (product) {
        const productFields = Object.keys(product).filter((fieldName) => {
          // don't load metadata info for standard fields
          return config.entities.product.standardSystemFields.indexOf(fieldName) < 0;
        });

        // load attributes to be shown on the product details - the request is now async
        const attributesPromise = context.dispatch(
          'attribute/list',
          {
            filterValues: config.entities.product.useDynamicAttributeLoader ? productFields : null,
            only_visible: config.entities.product.useDynamicAttributeLoader === true,
            only_user_defined: true,
            excludeFields: config.entities.attribute.excludeFields,
            // TODO: it might be refactored to kind of: `await context.dispatch('attributes/list) - or using new Promise()
            // .. to wait for attributes to be loaded before executing the next action.
            // However it may decrease the performance - so for now we're just waiting with the breadcrumbs
          },
          { root: true },
        );
        if (Vue.prototype.$isServer) {
          subloaders.push(context.dispatch('setupBreadcrumbs', { product: product }));
          subloaders.push(context.dispatch('filterUnavailableVariants', { product: product }));
        } else {
          // if this is client's side request postpone breadcrumbs
          // setup till attributes are loaded to avoid too-early breadcrumb switch #2469
          attributesPromise.then(() => context.dispatch('setupBreadcrumbs', { product: product }));
          context.dispatch('filterUnavailableVariants', { product: product }); // exec async
        }
        subloaders.push(attributesPromise);
        context.dispatch('setProductGallery', { product: product });

        if (config.products.preventConfigurableChildrenDirectAccess) {
          subloaders.push(context.dispatch('checkConfigurableParent', { product: product }));
        }
      } else {
        // error or redirect
      }
      return subloaders;
    });
  },
  /**
   * Add custom option validator for product custom options
   */
  addCustomOptionValidator(context, { validationRule, validatorFunction }) {
    context.commit(types.CATALOG_ADD_CUSTOM_OPTION_VALIDATOR, { validationRule, validatorFunction });
  },

  /**
   * Set product gallery depending on product type
   */

  setProductGallery(context, { product }) {
    const galleryConfig = config.zento.images.productsGallery;

    if (product.type_id === 'configurable' && 'configurable_children' in product) {
      if (!config.products.gallery.mergeConfigurableChildren && product.is_configured) {
        context.commit(
          types.CATALOG_UPD_GALLERY,
          attributeImages(context.state.current, context.state.current.configurable_options),
        );
      } else {
        let productGallery = [];
        let galleryVideos = [];

        if (galleryConfig.uniqBy !== 'src') {
          galleryVideos = getMediaGallery(product).filter((media) => media.video);
          productGallery = uniqBy(
            uniqBy(configurableChildrenImages(product), galleryConfig.uniqBy).concat(getMediaGallery(product)),
            'src',
          );
        } else {
          galleryVideos = getMediaGallery(product).filter((media) => media.video);
          productGallery = uniqBy(configurableChildrenImages(product).concat(getMediaGallery(product)), 'src');
        }

        if (galleryVideos?.length) {
          productGallery = uniqBy(galleryVideos.concat(productGallery), 'src');
        }

        productGallery = productGallery
          .filter((f) => {
            return f.src && f.src !== config.images.productPlaceholder;
          })
          .sort((a, b) => (galleryConfig.sort === 'ASC' ? a.pos - b.pos : b.pos - a.pos));

        context.commit(types.CATALOG_UPD_GALLERY, productGallery);
      }
    } else {
      const productGallery = uniqBy(configurableChildrenImages(product).concat(getMediaGallery(product)), 'src')
        .filter((f) => {
          return f.src && f.src !== config.images.productPlaceholder;
        })
        .sort((a, b) => (galleryConfig.sort === 'ASC' ? a.pos - b.pos : b.pos - a.pos));
      context.commit(types.CATALOG_UPD_GALLERY, productGallery);
    }
  },

  /**
   * Load the product data - async version for asyncData()
   */
  fetchAsync(context, { parentSku, childSku = null, route = null, skipCache = false }) {
    if (context.state.productLoadStart && Date.now() - context.state.productLoadStart < PRODUCT_REENTER_TIMEOUT) {
      Logger.log('Product is being fetched ...', 'product')();
    } else {
      context.state.productLoadPromise = new Promise((resolve, reject) => {
        context.state.productLoadStart = Date.now();
        Logger.info('Fetching product data asynchronously', 'product', { parentSku, childSku })();
        EventBus.$emit('product-before-load', { store: rootStore, route: route });
        context.dispatch('reset').then(() => {
          context
            .dispatch('fetch', { parentSku: parentSku, childSku: childSku, skipCache: skipCache })
            .then((subpromises) => {
              Promise.all(subpromises)
                .then(() => {
                  EventBus.$emitFilter('product-after-load', {
                    store: rootStore,
                    route: route,
                  })
                    .then(() => {
                      context.state.productLoadStart = null;
                      return resolve();
                    })
                    .catch((err) => {
                      context.state.productLoadStart = null;
                      Logger.error(err, 'product')();
                      return resolve();
                    });
                })
                .catch((errs) => {
                  context.state.productLoadStart = null;
                  reject(errs);
                });
            })
            .catch((err) => {
              context.state.productLoadStart = null;
              reject(err);
            })
            .catch((err) => {
              context.state.productLoadStart = null;
              reject(err);
            });
        });
      });
    }

    return context.state.productLoadPromise;
  },

  async findConfigurableParent(context, { product, configuration }) {
    const searchQuery = new SearchQuery();
    const query = searchQuery.applyFilter({ key: 'configurable_children.sku', value: { eq: product.sku } });
    const products = await context.dispatch('findProducts', { query, configuration });

    return products.items && products.items.length > 0 ? products.items[0] : null;
  },

  async findProducts(
    context,
    {
      query,
      start = 0,
      size = 50,
      entityType = 'product',
      sort = '',
      cacheByKey = 'sku',
      excludeFields = null,
      includeFields = null,
      configuration = null,
      populateRequestCacheTags = true,
    },
  ) {
    const isCacheable = canCache({ includeFields, excludeFields });
    const { excluded, included } = getOptimizedFields({ excludeFields, includeFields });
    const resp = await quickSearchByQuery({
      query,
      start,
      size,
      entityType,
      sort,
      excludeFields: excluded,
      includeFields: included,
    });
    const products = await context.dispatch('configureLoadedProducts', {
      products: resp,
      isCacheable,
      cacheByKey,
      populateRequestCacheTags,
      configuration,
    });

    return products;
  },

  async configureLoadedProducts(
    context,
    { products, isCacheable, cacheByKey, populateRequestCacheTags, configuration },
  ) {
    if (products.items && products.items.length) {
      // preconfigure products; eg: after filters
      for (let product of products.items) {
        product = await context.dispatch('preConfigureProduct', { product, populateRequestCacheTags, configuration }); // preConfigure(product)
      }
    }

    await calculateTaxes(products.items, context);

    // We store each product separately in cache to have offline access to products/single method
    for (let prod of products.items) {
      prod = configureChildren(prod);

      if (isCacheable) {
        // store cache only for full loads
        storeProductToCache(prod, cacheByKey);
      }
    }

    return products;
  },

  preConfigureProduct(context, { product, populateRequestCacheTags, configuration }) {
    let prod = preConfigureProduct({ product, populateRequestCacheTags });

    if (configuration) {
      const selectedVariant = configureProductAsync(context, {
        product: prod,
        selectDefaultVariant: false,
        configuration,
      });

      prod = Object.assign({}, prod, omit(selectedVariant, ['visibility']));
    }

    return prod;
  },
};

export default actions;
