import Vue from 'vue';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import config from 'config';
import { ActionTree } from 'vuex';
import chunk from 'lodash-es/chunk';
import trim from 'lodash-es/trim';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { entityKeyName } from '@vue-storefront/core/store/lib/entities';
import rootStore from '@vue-storefront/core/store';
import RootState from '@vue-storefront/core/types/RootState';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import { currentStoreView, localizedDispatcherRoute } from '@vue-storefront/core/lib/multistore';
import { Logger } from '@vue-storefront/core/lib/logger';
import { Format } from '@zento-lib/components/i18n';
import { CatalogStore } from 'theme/stores/catalog/catalogStore';

import { optionLabel } from '../../helpers/optionLabel';
import ICategoryState from '../../types/CategoryState';

// import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers';

import * as types from './mutation-types';
import type { Attribute } from './types/FilterType';

const actions: ActionTree<ICategoryState, RootState> = {
  /**
   * Reset current category and path
   * @param {Object} context
   */
  reset(context) {
    context.commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, []);
    context.commit(types.CATEGORY_UPD_CURRENT_CATEGORY, {});
    rootStore.dispatch('stock/clearCache');
    EventBus.$emit('category-after-reset', {});
  },
  /**
   * Load categories within specified parent
   * @param {Object} commit promise
   * @param {Object} parent parent category
   */
  list(
    context,
    {
      parent = null,
      key = null,
      value = null,
      level = null,
      onlyActive = true,
      onlyNotEmpty = false,
      onlyIncludedInMenu = false,
      size = 4000,
      start = 0,
      sort = 'position:asc',
      includeFields = config.entities.optimize ? config.entities.category.includeFields : null,
      excludeFields = config.entities.optimize ? config.entities.category.excludeFields : null,
      skipCache = false,
      updateState = true,
    },
  ) {
    const commit = context.commit;
    // that means the parameters are != defaults;
    // with defaults parameter the data could be get from window.__INITIAL_STATE__ - this is optimization trick
    let customizedQuery = false;
    let searchQuery = new SearchQuery();

    if (parent && typeof parent !== 'undefined') {
      searchQuery = searchQuery.applyFilter({
        key: 'parent_id',
        value: { eq: typeof parent === 'object' ? parent.id : parent },
      });
      customizedQuery = true;
    }

    if (level !== null) {
      searchQuery = searchQuery.applyFilter({ key: 'level', value: { eq: level } });

      // if this is the default level we're getting the results from window.__INITIAL_STATE__ not querying the server
      if (
        level !== config.entities.category.categoriesDynamicPrefetchLevel &&
        !context.rootGetters['application-context/isServer']
      ) {
        customizedQuery = true;
      }
    }

    if (key !== null) {
      if (Array.isArray(value)) {
        searchQuery = searchQuery.applyFilter({ key: key, value: { in: value } });
      } else {
        searchQuery = searchQuery.applyFilter({ key: key, value: { eq: value } });
      }

      customizedQuery = true;
    }

    if (onlyActive === true) {
      searchQuery = searchQuery.applyFilter({ key: 'is_active', value: { eq: true } });
    }

    if (onlyNotEmpty === true) {
      searchQuery = searchQuery.applyFilter({ key: 'product_count', value: { gt: 0 } });
      customizedQuery = true;
    }

    if (onlyIncludedInMenu === true) {
      searchQuery = searchQuery.applyFilter({ key: 'include_in_menu', value: { eq: 1 } });
    }

    if (skipCache || !context.state.list || context.state.list.length === 0 || customizedQuery) {
      return quickSearchByQuery({
        entityType: 'category',
        query: searchQuery,
        sort: sort,
        size: size,
        start: start,
        includeFields: includeFields,
        excludeFields: excludeFields,
      }).then((resp) => {
        for (const category of resp.items) {
          if (category.url_path && updateState) {
            rootStore.dispatch(
              'url/registerMapping',
              {
                url: localizedDispatcherRoute(category.url_path, currentStoreView().storeCode),
                routeData: {
                  params: {
                    slug: category.slug,
                  },
                  name: 'category',
                },
              },
              { root: true },
            );
          }
        }
        if (updateState) {
          commit(types.CATEGORY_UPD_CATEGORIES, Object.assign(resp, { includeFields, excludeFields }));
          EventBus.$emit('category-after-list', {
            query: searchQuery,
            sort: sort,
            size: size,
            start: start,
            list: resp,
          });
        }

        return resp;
      });
    } else {
      return new Promise((resolve) => {
        const resp = { items: context.state.list, total: context.state.list.length };
        if (updateState) {
          EventBus.$emit('category-after-list', {
            query: searchQuery,
            sort: sort,
            size: size,
            start: start,
            list: resp,
          });
        }

        resolve(resp);
      });
    }
  },

  /**
   * Load category object by specific field - using local storage/indexed Db
   * loadCategories() should be called at first!
   * @param {Object} commit
   * @param {String} key
   * @param {String} value
   * @param {Bool} setCurrentCategory default=true and means that state.current_category is set to the one loaded
   */
  single(
    context,
    {
      key,
      value,
      setCurrentCategory = true,
      setCurrentCategoryPath = true,
      populateRequestCacheTags = true,
      skipCache = false,
    },
  ) {
    const state = context.state;
    const commit = context.commit;
    const dispatch = context.dispatch;

    return new Promise((resolve, reject) => {
      const fetchCat = ({ key, value }) => {
        if (key !== 'id' || value >= config.entities.category.categoriesRootCategorylId /* root category */) {
          context
            .dispatch('list', { key: key, value: value })
            .then((res) => {
              if (res && res.items && res.items.length) {
                setcat(null, res.items[0]); // eslint-disable-line @typescript-eslint/no-use-before-define
              } else {
                reject(new Error('Category query returned empty result ' + key + ' = ' + value));
              }
            })
            .catch(reject);
        } else {
          reject(new Error('Category query returned empty result ' + key + ' = ' + value));
        }
      };
      const setcat = (error, mainCategory) => {
        if (!mainCategory) {
          fetchCat({ key, value });

          return;
        }
        if (error) {
          Logger.error(error)();
          reject(error);
        }

        if (setCurrentCategory) {
          commit(types.CATEGORY_UPD_CURRENT_CATEGORY, mainCategory);
        }
        if (populateRequestCacheTags && mainCategory && Vue.prototype.$ssrRequestContext) {
          Vue.prototype.$ssrRequestContext.output.cacheTags.add(`C${mainCategory.id}`);
        }
        if (setCurrentCategoryPath) {
          const currentPath = [];
          const recurCatFinder = (category) => {
            if (!category) {
              return;
            }
            if (category.parent_id >= config.entities.category.categoriesRootCategorylId) {
              dispatch('single', {
                key: 'id',
                value: category.parent_id,
                setCurrentCategory: false,
                setCurrentCategoryPath: false,
              })
                .then((sc) => {
                  // TODO: move it to the server side for one requests OR cache in indexedDb
                  if (!sc) {
                    commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath);
                    EventBus.$emit('category-after-single', { category: mainCategory });
                    return resolve(mainCategory);
                  }
                  currentPath.unshift(sc);
                  if (sc.parent_id) {
                    recurCatFinder(sc);
                  }
                })
                .catch((err) => {
                  Logger.error(err)();
                  // this is the case when category is not binded to the root tree - for example 'Erin Recommends'
                  commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath);
                  resolve(mainCategory);
                });
            } else {
              commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath);
              EventBus.$emit('category-after-single', { category: mainCategory });
              resolve(mainCategory);
            }
          };

          if (typeof mainCategory !== 'undefined') {
            recurCatFinder(mainCategory); // TODO: Store breadcrumbs in IndexedDb for further usage to optimize speed?
          } else {
            reject(new Error('Category query returned empty result ' + key + ' = ' + value));
          }
        } else {
          EventBus.$emit('category-after-single', { category: mainCategory });
          resolve(mainCategory);
        }
      };

      let foundInLocalCache = false;
      // SSR - there were some issues with using localForage,
      // So it's the reason to use local state instead, when possible
      if (state.list.length > 0 && !skipCache) {
        const category = state.list.find((itm) => {
          return itm[key] === value;
        });
        // Check if category exists in the store OR we have recursively reached Default category (id=1)
        if (category && value >= config.entities.category.categoriesRootCategorylId /** root category parent */) {
          foundInLocalCache = true;
          setcat(null, category);
        }
      }
      if (!foundInLocalCache) {
        if (skipCache || context.rootGetters['application-context/isServer']) {
          fetchCat({ key, value });
        } else {
          const catCollection = Vue.prototype.$db.categoriesCollection;
          // Check if category does not exist in the store AND we haven't recursively reached Default category (id=1)
          catCollection.getItem(entityKeyName(key, value), setcat);
        }
      }
    });
  },
  /**
   * Filter category products
   */
  products(
    context,
    {
      populateAggregations = false,
      filters = [],
      searchProductQuery,
      current = 0,
      perPage = config.zento.theme.category.perPage,
      sort = '',
      includeFields = null,
      excludeFields = null,
      configuration = null,
      append = false,
      skipCache = false,
    },
  ) {
    context.dispatch('setSearchOptions', {
      populateAggregations,
      filters,
      current,
      perPage,
      includeFields,
      excludeFields,
      configuration,
      append,
      sort,
    });

    let prefetchGroupProducts = true;

    if (
      rootStore.state.config.entities.twoStageCaching &&
      rootStore.state.config.entities.optimize &&
      !Vue.prototype.$isServer &&
      !rootStore.state.twoStageCachingDisabled
    ) {
      // only client side, only when two stage caching enabled
      includeFields = rootStore.state.config.entities.productListWithChildren.includeFields;
      // we need configurable_children for filters to work
      excludeFields = rootStore.state.config.entities.productListWithChildren.excludeFields;
      prefetchGroupProducts = false;
      Logger.log('Using two stage caching for performance optimization - executing first stage product pre-fetching')();
    } else {
      prefetchGroupProducts = true;
      if (rootStore.state.twoStageCachingDisabled) {
        Logger.log('Two stage caching is disabled runtime because of no performance gain')();
      } else {
        Logger.log('Two stage caching is disabled by the config')();
      }
    }
    const t0 = new Date().getTime();
    const sortFilterOptions =
      'sortFilterOptionsAlphabetically' in rootStore.state.config.zento.theme.category.layeredNavigation
        ? rootStore.state.config.zento.theme.category.layeredNavigation.sortFilterOptionsAlphabetically
        : false;
    const precachedQuery = searchProductQuery;
    const productPromise = rootStore
      .dispatch('product/list', {
        query: precachedQuery,
        start: current,
        size: perPage,
        excludeFields: excludeFields,
        includeFields: includeFields,
        configuration: configuration,
        append: append,
        sort: sort,
        updateState: true,
        prefetchGroupProducts: prefetchGroupProducts,
      })
      .then((res) => {
        const t1 = new Date().getTime();
        const subloaders = [];

        rootStore.state.twoStageCachingDelta1 = t1 - t0;

        if (!res || res.noresults) {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'warning',
            message: Format.message('no_products_sync'),
            action1: { label: Format.message('notification_action') },
          });
          if (!append) rootStore.dispatch('product/reset');
          rootStore.state.product.list = { items: [] };
          // no products to show TODO: refactor to rootStore.state.category.reset() and rootStore.state.product.reset()
          // rootStore.state.category.filters = { color: [], size: [], price: [] }
          return [];
        } else {
          /**
           * Determines price range values
           */
          const catalogStore = new CatalogStore();

          catalogStore.priceRangeFilter = true;

          if (config.zento.theme.category.enableReviews) {
            const reviewItems = res.items.map((i) => i.id);

            rootStore.dispatch('review/reviewItems', { productIds: reviewItems });
          }

          if (
            rootStore.state.config.products.filterUnavailableVariants &&
            rootStore.state.config.products.configurableChildrenStockPrefetchStatic
          ) {
            // prefetch the stock items
            const skus = [];
            let prefetchIndex = 0;
            res.items.map((i) => {
              if (rootStore.state.config.products.configurableChildrenStockPrefetchStaticPrefetchCount > 0) {
                if (
                  prefetchIndex > rootStore.state.config.products.configurableChildrenStockPrefetchStaticPrefetchCount
                ) {
                  return;
                }
              }
              skus.push(i.sku);
              // main product sku to be checked anyway
              if (i.type_id === 'configurable' && i.configurable_children && i.configurable_children.length > 0) {
                for (const confChild of i.configurable_children) {
                  const cachedItem = context.rootState.stock.cache[confChild.id];
                  if (typeof cachedItem === 'undefined' || cachedItem === null) {
                    skus.push(confChild.sku);
                  }
                }

                prefetchIndex++;
              }
            });
            for (const chunkItem of chunk(skus, 15)) {
              rootStore.dispatch('stock/list', { skus: chunkItem, skipCache }); // store it in the cache
            }
          }

          if (populateAggregations === true && res.aggregations) {
            // populate filter aggregates
            for (const attrToFilter of filters) {
              // fill out the filter options
              // TODO - Get our filters
              const roots = context.rootState as typeof context.rootState & {
                attribute: typeof context.rootState.attribute & { list_by_code: Record<string, any> };
              };
              const filterOptions = [];
              const uniqueFilterValues = new Map();
              let attributeData = roots.attribute.list_by_code[attrToFilter];

              if (
                !attributeData &&
                (attrToFilter !== 'stock.is_in_stock' || attrToFilter !== 'stock') &&
                attrToFilter !== 'rating'
              ) {
                continue;
              }

              if (attrToFilter === 'category_ids') {
                const items = context.getters.getCurrentCategory;

                attributeData = {
                  attribute_code: 'category_ids',
                  frontend_input: 'select',
                  attribute_id: 1.1,
                  default_frontend_label: Format.message('categories_filter_title'),
                  frontend_label: Format.message('categories_filter_title'),
                  is_visible: true,
                  is_visible_on_front: true,
                  position: 0,
                };

                if (items.children_data.length) {
                  const categories = items.children_data.sort((a, b) => a.position - b.position);

                  filterOptions.push(...categories);
                } else {
                  const siblings: any = [];

                  // TODO: Add a better logic
                  if (context.getters.list.length) {
                    context.getters.list[0].children_data.filter((firstLevel) => {
                      if (firstLevel.slug === items.slug) {
                        siblings.push(...context.getters.list[0].children_data);
                      } else {
                        firstLevel.children_data.filter((secondLevel) => {
                          if (secondLevel.slug === items.slug) {
                            siblings.push(...firstLevel.children_data);
                          } else {
                            secondLevel.children_data.filter((thirdLevel) => {
                              if (thirdLevel.slug === items.slug) {
                                siblings.push(...secondLevel.children_data);
                              } else {
                                // Stop here, add more if needed
                              }
                            });
                          }
                        });
                      }
                    });

                    const categories = siblings
                      .filter((f) => {
                        return f.slug !== items.slug;
                      })
                      .sort((a, b) => {
                        return a.position > b.position;
                      });

                    filterOptions.push(...categories);
                  }
                }
              } else if (attrToFilter === 'stock.is_in_stock' || attrToFilter === 'stock') {
                attributeData = {
                  attribute_code: 'stock.is_in_stock',
                  frontend_input: 'select',
                  attribute_id: 1.2,
                  default_frontend_label: Format.message('availability_filter'),
                  frontend_label: Format.message('availability_filter'),
                  is_visible: true,
                  is_visible_on_front: true,
                  position: 0,
                };

                filterOptions.push(
                  {
                    id: true,
                    label: Format.message('filter_in_stock'),
                  },
                  {
                    id: false,
                    label: Format.message('filter_out_of_stock'),
                  },
                );
              } else if (attrToFilter === 'rating') {
                attributeData = {
                  attribute_code: 'rating',
                  frontend_input: 'select',
                  attribute_id: 1.3,
                  default_frontend_label: Format.message('rating_filter'),
                  frontend_label: Format.message('rating_filter'),
                  is_visible: true,
                  is_visible_on_front: true,
                  position: 0,
                };

                filterOptions.push(
                  {
                    id: 1,
                    from: 1,
                    to: 1.9,
                    label: Format.message('rating_1'),
                  },
                  {
                    id: 2,
                    from: 2,
                    to: 2.9,
                    label: Format.message('rating_2'),
                  },
                  {
                    id: 3,
                    from: 3,
                    to: 3.9,
                    label: Format.message('rating_3'),
                  },
                  {
                    id: 4,
                    from: 4,
                    to: 4.9,
                    label: Format.message('rating_4'),
                  },
                  {
                    id: 5,
                    label: Format.message('rating_5'),
                    from: 5,
                    to: 5,
                  },
                );
              } else if (attrToFilter !== 'price') {
                if (res.aggregations['agg_terms_' + attrToFilter]) {
                  let buckets = res.aggregations['agg_terms_' + attrToFilter].buckets;

                  if (res.aggregations['agg_terms_' + attrToFilter + '_options']) {
                    buckets = buckets.concat(res.aggregations['agg_terms_' + attrToFilter + '_options'].buckets);

                    // if (buckets?.length) {
                    //   buckets = buckets.reduce(
                    //     (ret: Array<{ key: number; doc_count: number }>, b: { key: number; doc_count: number }) => {
                    //       const ind = ret.findIndex((e) => e.key === b.key);

                    //       if (ind > -1) {
                    //         ret[ind].doc_count = +ret[ind].doc_count + +b.doc_count;
                    //       } else {
                    //         ret.push(b);
                    //       }

                    //       return ret;
                    //     },
                    //     [],
                    //   );
                    // }
                  }

                  buckets.forEach((o) => {
                    uniqueFilterValues.set(o.key.toString(), o.doc_count);
                  });
                }

                if (uniqueFilterValues.size > 0) {
                  uniqueFilterValues.forEach((count, key) => {
                    const opt = optionLabel(rootStore.state.attribute, { attributeKey: attrToFilter, optionId: key });

                    // is there any situation when label could be empty and we should still support it?
                    if (trim(opt.label) !== '') {
                      filterOptions.push({
                        id: key,
                        label: opt.label,
                        order: opt.order,
                        count,
                      });
                    }
                  });
                }

                if (filterOptions.length) {
                  if (sortFilterOptions) {
                    // Sort alphabetically
                    filterOptions.sort((a, b) => {
                      return a.label > b.label ? 1 : -1;
                    });
                  } else {
                    // Sort ASC by order
                    filterOptions.sort((a, b) => a.order - b.order);
                  }
                }
              } else {
                // special case is range filter for prices
                const storeView = currentStoreView();
                const currencySign = storeView.i18n.currencySign;

                if (res.aggregations['agg_range_' + attrToFilter]) {
                  let index = 0;
                  const count = res.aggregations['agg_range_' + attrToFilter].buckets.length;

                  for (const option of res.aggregations['agg_range_' + attrToFilter].buckets) {
                    filterOptions.push({
                      id: option.key,
                      from: option.from,
                      to: option.to,
                      label:
                        index === 0 || index === count - 1
                          ? option.to
                            ? '< ' + currencySign + option.to
                            : '> ' + currencySign + option.from
                          : currencySign + option.from + (option.to ? ' - ' + option.to : ''), // TODO: add better way for formatting, extract currency sign
                    });
                    index++;
                  }
                }
              }

              if (attributeData) {
                const filterData: Attribute = {
                  default_frontend_label: attributeData.default_frontend_label,
                  frontend_label: attributeData.frontend_label,
                  attribute_id: attributeData.attribute_id,
                  attribute_code: attributeData.attribute_code,
                  frontend_input: attributeData.frontend_input,
                  is_visible: attributeData.is_visible,
                  is_visible_on_front: attributeData.is_visible_on_front,
                  position: attributeData.position,
                  options: filterOptions,
                };

                context.dispatch('addAvailableFilter', {
                  key: attrToFilter,
                  options: filterData,
                });
              }
            }
          }
        }

        return subloaders;
      })
      .catch((err) => {
        console.error(err);
        rootStore.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: Format.message('no_products_sync'),
          action1: { label: Format.message('notification_action') },
        });
      });

    if (
      rootStore.state.config.entities.twoStageCaching &&
      rootStore.state.config.entities.optimize &&
      !Vue.prototype.$isServer &&
      !rootStore.state.twoStageCachingDisabled
    ) {
      // second stage - request for caching entities
      console.log('Using two stage caching for performance optimization - executing second stage product caching');
      // TODO: in this case we can pre-fetch products in advance getting more products than set by pageSize
      rootStore
        .dispatch('product/list', {
          query: precachedQuery,
          start: current,
          size: perPage,
          excludeFields: null,
          includeFields: null,
          updateState: false, // not update the product listing - this request is only for caching
        })
        .catch((err) => {
          console.info("Problem with second stage caching - couldn't store the data");
          console.info(err);
        })
        .then(() => {
          const t2 = new Date().getTime();
          rootStore.state.twoStageCachingDelta2 = t2 - t0;
          console.log(
            'Using two stage caching for performance optimization - Time comparison stage1 vs stage2',
            rootStore.state.twoStageCachingDelta1,
            rootStore.state.twoStageCachingDelta2,
          );
          if (rootStore.state.twoStageCachingDelta1 > rootStore.state.twoStageCachingDelta2) {
            // two stage caching is not making any good
            rootStore.state.twoStageCachingDisabled = true;
            console.log('Disabling two stage caching');
          }
        });
    }
    return productPromise;
  },
  addAvailableFilter({ commit }, { key, options } = []) {
    if (key) commit(types.CATEGORY_ADD_AVAILABLE_FILTER, { key, options });
  },
  resetFilters(context) {
    context.commit(types.CATEGORY_REMOVE_FILTERS);
  },
  searchProductQuery(context, productQuery) {
    context.commit(types.CATEGORY_UPD_SEARCH_PRODUCT_QUERY, productQuery);
  },
  setSearchOptions({ commit }, searchOptions) {
    commit(types.CATEGORY_SET_SEARCH_OPTIONS, searchOptions);
  },
  mergeSearchOptions({ commit }, searchOptions) {
    commit(types.CATEGORY_MERGE_SEARCH_OPTIONS, searchOptions);
  },
};

export default actions;
