import { Logger } from '@vue-storefront/core/lib/logger';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { CartStore } from 'theme/stores/cart/cart';
import { FreeShippingMethodsStore } from 'theme/stores/freeShippingMethods/freeShippingMethods';

import { CartService } from '../../data-resolver/CartService';
import {
  preparePaymentMethodsToSync,
  prepareShippingInfoForUpdateTotals,
  createOrderData,
  createShippingInfoData,
} from '../../helpers';
import * as types from '../mutation-types';

const totalsActions = {
  async getTotals({ rootState }, { addressInformation, hasShippingInformation }) {
    if (hasShippingInformation) {
      return CartService.setShippingInfo(addressInformation, rootState.user.token);
    }

    return CartService.getTotals(rootState.user.token);
  },

  async overrideServerTotals(
    { commit, getters, rootGetters, dispatch },
    { addressInformation, hasShippingInformation },
  ) {
    const { resultCode, result } = await dispatch('getTotals', { addressInformation, hasShippingInformation });
    const cartStore = new CartStore();
    const freeShippingStore = new FreeShippingMethodsStore();

    if (resultCode === 200) {
      const totals = result.totals || result;
      const itemsAfterTotal = prepareShippingInfoForUpdateTotals(totals.items);
      Logger.info('Overriding server totals. ', 'cart', totals)();

      cartStore.cartTotals = totals.total_segments;

      for (const key of Object.keys(itemsAfterTotal)) {
        const item = itemsAfterTotal[key];
        const product = { server_item_id: item.item_id, totals: item, qty: item.qty };

        await dispatch('updateItem', { product });
      }

      // TODO: This is just a hack, this should be send correctly from backend #BGDEL-5293
      const totalsSegments = totals.total_segments.map((t) =>
        t.code === 'shipping' ? { ...t, value: totals.base_shipping_incl_tax } : t,
      );

      await commit(types.CART_UPD_TOTALS, { itemsAfterTotal, totals, platformTotalSegments: totalsSegments });
      await commit(types.CART_SET_TOTALS_SYNC);
      await freeShippingStore.fetchFreeShippingMethods(getters.getCartToken);

      // we received payment methods as a result of this call, updating state
      if (result.payment_methods && getters.canUpdateMethods) {
        const { uniqueBackendMethods, paymentMethods } = preparePaymentMethodsToSync(
          result.payment_methods.map((method) => ({ ...method, is_server_method: true })),
          rootGetters['payment/paymentMethods'].filter((itm) => typeof itm !== 'object' || !itm.is_server_method),
        );

        await dispatch('payment/replaceMethods', paymentMethods, { root: true });
        EventBus.$emit('set-unique-payment-methods', uniqueBackendMethods);
      }

      cartStore.loading = false;

      return;
    }

    Logger.error(result, 'cart')();
  },

  async syncTotals(
    { dispatch, getters, rootGetters },
    payload: { forceServerSync: boolean; methodsData?: any } = { forceServerSync: false, methodsData: null },
  ) {
    const methodsData = payload ? payload.methodsData : null;
    await dispatch('pullMethods', { forceServerSync: payload.forceServerSync });

    if (getters.canSyncTotals && (getters.isTotalsSyncRequired || payload.forceServerSync)) {
      const shippingMethodsData =
        methodsData ||
        createOrderData({
          shippingDetails: rootGetters['checkout/getShippingDetails'],
          shippingMethods: rootGetters['shipping/shippingMethods'],
          paymentMethods: rootGetters['payment/paymentMethods'],
          paymentDetails: rootGetters['checkout/getPaymentDetails'],
        });

      if (shippingMethodsData.country) {
        await dispatch('overrideServerTotals', {
          hasShippingInformation: shippingMethodsData.method_code || shippingMethodsData.carrier_code,
          addressInformation: createShippingInfoData(shippingMethodsData),
        });

        return;
      }

      Logger.error('Please do set the tax.defaultCountry in order to calculate totals', 'cart')();
    }
  },

  async refreshTotals({ dispatch }, payload) {
    Logger.warn('The "cart/refreshTotals" action is deprecated and not supported in Vue Storefront 1.11', 'cart')();

    await dispatch('syncTotals', payload);
  },
};

export default totalsActions;
