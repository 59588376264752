import { Module } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';

import WishlistState from '../types/WishlistState';

import actions from './actions';
import mutations from './mutations';

export const module: Module<WishlistState, RootState> = {
  namespaced: true,
  state: {
    loaded: false,
    items: [],
  },
  actions,
  mutations,
  getters: {
    isWishlistLoaded: (state) => state.loaded,
  },
};
