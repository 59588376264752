import { createModule } from '@vue-storefront/core/lib/module';

import { KEY } from './key';

export { KEY } from './key';

const store = {
  namespaced: true,
  state: {
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: [],
    },
    navigation: [],
    profileDrawer: false,
  },
  getters: {
    getNavigation: (state) => state.navigation || [],
  },
  mutations: {
    setCheckoutMode(state, action) {
      state.checkoutMode = action === true;
    },

    setMicrocart(state, action) {
      state.microcart = action === true;
      state.overlay = action === true;
    },

    setSidebar(state, action) {
      state.sidebar = action === true;
    },

    setSubmenu(state, { id }) {
      if (id) {
        state.submenu.path = [...state.submenu.path, id];
      } else if (state.submenu.path.length) {
        state.submenu.path = state.submenu.path.slice(0, -1);
      }

      state.submenu.depth = state.submenu.path.length;
    },

    setWishlist(state, action) {
      state.wishlist = action === true;
      state.overlay = action === true;
    },

    setOverlay(state, action) {
      state.overlay = action === true;
    },

    setLoader(state, action) {
      state.loader = action === true;
    },

    setAuthElem(state, action) {
      state.authElem = action;
    },

    setNavigationInfo(state, value) {
      state.navigation = value;
    },

    setProfileDrawer(state, action) {
      state.profileDrawer = action === true;
      state.overlay = action === true;
    },
  },
  actions: {
    toggleMicrocart({ commit, state }) {
      commit('setMicrocart', !state.microcart);
    },

    toggleWishlist({ commit, state }) {
      commit('setWishlist', !state.wishlist);
    },
  },
};

export const Ui = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] },
});
