import { MutationTree } from 'vuex';

import type { CartState } from '../types/CartState';

import * as types from './mutation-types';

const mutations: MutationTree<CartState> = {
  [types.CART_OPEN_EDIT_MODE](state, { product, selectedOptions, qty }) {
    state.editMode = { product, selectedOptions, qty };
  },

  [types.CART_EDIT_MODE_SET_FILTERS](state, { filterOptions }) {
    state.editMode.selectedOptions[filterOptions.attribute_code] = filterOptions;
  },

  [types.CART_EDIT_QTY](state, { qty }) {
    state.editMode.qty = qty;
  },

  [types.CART_CLOSE_EDIT_MODE](state) {
    state.editMode = { product: null, selectedOptions: {}, qty: 0 };
  },
};

export default mutations;
