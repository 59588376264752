import omit from 'lodash-es/omit';
import toString from 'lodash-es/toString';
import isArray from 'lodash-es/isArray';
import i18n from '@vue-storefront/i18n';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import rootStore from '@vue-storefront/core/store';
import { Logger } from '@vue-storefront/core/lib/logger';

import { populateProductConfigurationAsync, setConfigurableProductOptionsAsync } from '../../../helpers/index';

function _internalMapOptions(productOption) {
  const optionsMapped = [];
  for (const option of productOption.extension_attributes.configurable_item_options) {
    optionsMapped.push({
      label: option.label,
      value: option.value,
    });
  }
  productOption.extension_attributes.configurable_item_options = productOption.extension_attributes.configurable_item_options.map(
    (op) => {
      return omit(op, ['label', 'value']);
    },
  );
  return optionsMapped;
}

export function findConfigurableChildAsync({
  product,
  configuration = null,
  selectDefaultChildren = false,
  availabilityCheck = true,
  specialPriceCheck = false,
  specialPriceCheckWithFilters = false,
}) {
  const productImage = product.image;
  let selectedVariant;

  if (configuration && !Object.keys(configuration).length) {
    if (specialPriceCheck) {
      selectedVariant = product.configurable_children.find((configurableChild) => {
        if (configurableChild.status === 1 && configurableChild.special_price) {
          return true;
        }
      });
    } else if (availabilityCheck) {
      selectedVariant = product.configurable_children.find((configurableChild) => {
        if (configurableChild.status === 1 && configurableChild.stock && configurableChild.stock.is_in_stock) {
          return true;
        }
      });
    }
  } else {
    selectedVariant = product.configurable_children.find((configurableChild) => {
      if (availabilityCheck) {
        if (configurableChild.stock && !rootStore.state.config.zento.theme.category.listOutOfStockProducts) {
          if (!configurableChild.stock.is_in_stock) {
            return false;
          }
        }
      }

      if (configurableChild.status >= 2) {
        /** disabled product */
        return false;
      }

      if (selectDefaultChildren) {
        return true; // return first
      }

      /**
       * Determines if configurable parent accepts backorders and assign value to child stock
       */
      if ('stock' in product && product.stock.backorders && 'stock' in configurableChild) {
        Object.assign(configurableChild.stock, {
          backorders: product.stock.backorders,
        });
      }

      if (configuration.sku) {
        return configurableChild.sku === configuration.sku; // by sku or first one
      } else {
        return Object.keys(omit(configuration, ['price'])).every((configProperty) => {
          if (isArray(configuration[configProperty])) {
            if (configuration[configProperty].length === 0) {
              return true; // skip empty
            }

            if (specialPriceCheck && specialPriceCheckWithFilters) {
              return (
                configuration[configProperty].filter(
                  (option) =>
                    toString(option.id) === toString(configurableChild[configProperty]) &&
                    configurableChild.special_price,
                ).length !== 0
              );
            }

            return (
              configuration[configProperty].filter(
                (option) => toString(option.id) === toString(configurableChild[configProperty]),
              ).length !== 0
            );
          } else {
            if (!configuration[configProperty] || typeof configuration[configProperty].id === 'undefined') {
              return true; // skip empty
            }

            return toString(configurableChild[configProperty]) === toString(configuration[configProperty].id);
          }
        });
      }
    });
  }

  /**
   *  If image exist show parent image, if not children image will be shown
   */
  if (
    selectedVariant !== undefined &&
    productImage !== (undefined || 'no_selection') &&
    selectedVariant?.image === (undefined || 'no_selection')
  ) {
    selectedVariant.image = productImage;
  } else {
    return selectedVariant;
  }

  return selectedVariant;
}

export function configureProductAsync(
  context,
  {
    product,
    configuration,
    selectDefaultVariant = true,
    fallbackToDefaultWhenNoAvailable = true,
    setProductErorrs = false,
  },
) {
  // use current product if product wasn't passed
  if (product === null) product = context.getters.productCurrent;
  const hasConfigurableChildren = product.configurable_children && product.configurable_children.length > 0;

  if (hasConfigurableChildren) {
    // handle custom_attributes for easier comparing in the future
    product.configurable_children.forEach((child) => {
      const customAttributesAsObject = {};
      if (child.custom_attributes) {
        child.custom_attributes.forEach((attr) => {
          customAttributesAsObject[attr.attribute_code] = attr.value;
        });
        // add values from custom_attributes in a different form
        Object.assign(child, customAttributesAsObject);
      }
    });

    // find selected variant
    let desiredProductFound = false;
    let selectedVariant = findConfigurableChildAsync({
      product,
      configuration,
      availabilityCheck: true,
      // specialPriceCheck: // TODO: uncomment once backend offers proper information
      //   rootStore.state.category?.current?.enable_virtual_category &&
      //   rootStore.state.category?.current?.assign_discounted_only,
      specialPriceCheck: rootStore.state.config.zento.theme.category.specialPriceCheck, // TODO: remove once backend offers proper information
      specialPriceCheckWithFilters: rootStore.state.config.zento.theme.category.specialPriceCheckWithFilters, // TODO: remove once proper testing is done
    });

    if (!selectedVariant) {
      if (fallbackToDefaultWhenNoAvailable) {
        selectedVariant = findConfigurableChildAsync({
          product,
          selectDefaultChildren: true,
          availabilityCheck: true,
        }); // return first available child
        desiredProductFound = true;
      } else {
        desiredProductFound = false;
      }
    } else {
      desiredProductFound = true;
    }

    if (typeof navigator !== 'undefined') {
      // this is fix for not preloaded images for offline
      if (selectedVariant && !navigator.onLine && context.state.offlineImage) {
        selectedVariant.image = context.state.offlineImage;
        Logger.debug('Image offline fallback to ', context.state.offlineImage)();
      }
    }
    if (selectedVariant) {
      if (!desiredProductFound) {
        // update the configuration
        populateProductConfigurationAsync(context, { product: product, selectedVariant: selectedVariant });
        configuration = context.state.current_configuration;
      }
      if (setProductErorrs) {
        product.errors = {}; // clear the product errors
      }
      product.is_configured = true;

      if (
        rootStore.state.config.cart.setConfigurableProductOptions &&
        !selectDefaultVariant &&
        !(Object.keys(configuration).length === 1 && configuration.sku)
      ) {
        // the condition above: if selectDefaultVariant - then "setCurrent"
        // is setting the configurable options; if configuration = { sku: '' }
        // -> this is a special case when not configuring the product but just searching by sku
        // set the custom options
        const productOption = setConfigurableProductOptionsAsync(context, {
          product: product,
          configuration: configuration,
        });
        if (productOption) {
          selectedVariant.product_option = productOption;
          selectedVariant.options = _internalMapOptions(productOption);
        }
      } /* else {
        Logger.debug('Skipping configurable options setup', configuration)()
      } */
      const fieldsToOmit = ['name'];
      if (selectedVariant.image === '') fieldsToOmit.push('image');

      // We need to send the parent SKU to the Magento cart sync but use the child SKU internally in this case
      selectedVariant = omit(selectedVariant, fieldsToOmit);

      selectedVariant.min_price = selectedVariant.special_price
        ? selectedVariant.special_price
        : selectedVariant.final_price &&
          selectedVariant.regular_price &&
          parseFloat(selectedVariant.final_price) < parseFloat(selectedVariant.regular_price)
        ? selectedVariant.final_price
        : selectedVariant.regular_price
        ? selectedVariant.regular_price
        : selectedVariant.price;

      selectedVariant.min_price_incltax = selectedVariant.special_price_incltax
        ? selectedVariant.special_price_incltax
        : selectedVariant.final_price_incltax &&
          selectedVariant.regular_price_incltax &&
          parseFloat(selectedVariant.final_price_incltax) < parseFloat(selectedVariant.regular_price_incltax)
        ? selectedVariant.final_price_incltax
        : selectedVariant.regular_price_incltax
        ? selectedVariant.regular_price_incltax
        : product.min_price_incltax;

      if (selectedVariant.news_to_date) {
        if (new Date(selectedVariant.news_to_date).getTime() > Date.now()) {
          selectedVariant.new = true;
        } else {
          selectedVariant.new = false;
        }
      } else if (selectedVariant.news_from_date && !selectedVariant.news_to_date) {
        if (new Date(selectedVariant.news_from_date).getTime() < Date.now()) {
          selectedVariant.new = true;
        } else {
          selectedVariant.new = false;
        }
      } else if (selectedVariant.news_from_date && selectedVariant.news_to_date) {
        if (
          new Date(selectedVariant.news_from_date).getTime() < Date.now() &&
          new Date(selectedVariant.news_to_date).getTime() > Date.now()
        ) {
          selectedVariant.new = true;
        } else {
          selectedVariant.new = false;
        }
      }

      // use chosen variant
      if (selectDefaultVariant) {
        context.dispatch('setCurrent', selectedVariant);
      }
      EventBus.$emit('product-after-configure', {
        product: product,
        configuration: configuration,
        selectedVariant: selectedVariant,
      });
    }

    if (!selectedVariant && setProductErorrs) {
      // can not find variant anyway, even the default one
      product.errors.variants = i18n.t('No available product variants');
      if (selectDefaultVariant) {
        context.dispatch('setCurrent', product); // without the configuration
      }
    }

    if (!selectedVariant.min_price) {
      selectedVariant.min_price = selectedVariant.special_price
        ? selectedVariant.special_price
        : selectedVariant.final_price &&
          selectedVariant.regular_price &&
          parseFloat(selectedVariant.final_price) < parseFloat(selectedVariant.regular_price)
        ? selectedVariant.final_price
        : selectedVariant.regular_price
        ? selectedVariant.regular_price
        : selectedVariant.price;
    }

    if (!selectedVariant.min_price_incltax) {
      selectedVariant.min_price_incltax = selectedVariant.special_price_incltax
        ? selectedVariant.special_price_incltax
        : selectedVariant.final_price_incltax &&
          selectedVariant.regular_price_incltax &&
          parseFloat(selectedVariant.final_price_incltax) < parseFloat(selectedVariant.regular_price_incltax)
        ? selectedVariant.final_price_incltax
        : selectedVariant.regular_price_incltax
        ? selectedVariant.regular_price_incltax
        : product.min_price_incltax;
    }

    return selectedVariant;
  } else {
    if (fallbackToDefaultWhenNoAvailable) {
      return product;
    } else {
      return null;
    }
  }
}
