import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

@Store
export class CartStore extends BaseStore {
  /**
   * Determines grand totals
   */
  @Field
  private cartTotalsData: {};

  /**
   * Determines coupon code
   */
  @Field
  private couponCodeData: string | boolean;

  /**
   * Determines loading place order button
   */
  @Field
  public loading: boolean;

  /**
   * Determines totals for cart and checkout
   */
  public get cartTotals() {
    return this.cartTotalsData || [];
  }

  /**
   * Set totals for cart and checkout
   */
  public set cartTotals(value) {
    this.cartTotalsData = value;
  }

  /**
   * Determines coupon code
   */
  public get couponCode() {
    return this.couponCodeData;
  }

  /**
   * Set totals for cart and checkout
   */
  public set couponCode(value) {
    this.couponCodeData = value;
  }

  // TODO: Check why getter doesn't update component
  /**
   * Determines place order loader
   */
  // public get placeOrderLoader() {
  //   return this.loading;
  // }

  /**
   * Set place order loader
   */
  // public set placeOrderLoader(value) {
  //   this.loading = value;
  // }
}
