import type { Module } from 'vuex';

import type { CartState } from '../types/CartState';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const cartStore: Module<CartState, any> = {
  namespaced: true,
  state: {
    isMicrocartOpen: false,
    itemsAfterPlatformTotals: {},
    platformTotals: null,
    platformTotalSegments: null,
    cartIsLoaded: false,
    cartServerToken: '', // server side ID to synchronize with Backend (for example Magento)
    shipping: [],
    payment: [],
    cartItemsHash: '',
    cartServerLastSyncDate: 0,
    cartServerLastTotalsSyncDate: 0,
    cartItems: [], // TODO: check if it's properly name-spaced
    connectBypassCount: 0,
    isAddingToCart: false,
    isRestoring: false,
  },
  getters,
  actions,
  mutations,
};
