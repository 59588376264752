import config from 'config';

import type { ViewportData } from '../types';

const MOBILE_THRESHOLD = config.zento.viewportThresholds.mobile;
const TABLET_THRESHOLD = config.zento.viewportThresholds.tablet;
const DESKTOP_THRESHOLD = config.zento.viewportThresholds.desktop;

const isServer = typeof window === 'undefined';

export function getViewportData() {
  // Set a default viewport for SSR
  let viewport: ViewportData = {
    width: 320,
    height: 568,
    scale: 1,
  };

  if (!isServer) {
    const visualViewport = (window as any).visualViewport || {};

    // TODO: visualViewport should be used, but it has incompatibility across OS. Find a solution in the future
    viewport = {
      width: window.innerWidth || visualViewport.width,
      height: window.innerHeight || visualViewport.height,
      scale: visualViewport.scale || 1,
    };
  }

  return viewport;
}

export const ApplicationContextState = {
  namespaced: true,
  state: {
    viewport: getViewportData(),
  },
  getters: {
    getViewportWidth: (state) => state.viewport.width,
    getViewportHeight: (state) => state.viewport.height,
    getViewportScale: (state) => state.viewport.scale,
    isMobile: (state) => state.viewport.width < MOBILE_THRESHOLD,
    isTablet: (state) => state.viewport.width < TABLET_THRESHOLD,
    isDesktop: (state) => state.viewport.width >= DESKTOP_THRESHOLD,
    isServer: () => isServer,
  },
  actions: {
    updateViewport(context, { width, height, scale }) {
      context.commit('setViewport', { width, height, scale });
    },
  },
  mutations: {
    setViewport(state, viewport: ViewportData) {
      state.viewport = viewport;
    },
  },
};
