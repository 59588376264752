import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import dayjs from 'dayjs';
import dayjsRelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(dayjsRelativeTime);

/**
 * Format duration
 * @param {Number} d
 */
export function duration (d) {
  let storeLocale = currentStoreView().i18n.defaultLocale.toLocaleLowerCase()
  const separatorIndex = storeLocale.indexOf('-')
  const languageCode = separatorIndex ? storeLocale.substr(0, separatorIndex) : storeLocale

  const isStoreLocale = dayjs().locale(storeLocale).locale()
  const isLanguageLocale = dayjs().locale(languageCode).locale()
  const locale = isStoreLocale || isLanguageLocale
  let method = d > 0 ? 'fromNow' : 'toNow'
  let date = dayjs(Date.now() + d);

  if (locale) {
    date = date.locale(languageCode);
  }

  return date[method]();
}
