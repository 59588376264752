import { createModule } from '@vue-storefront/core/lib/module';

import { module } from './store';
import { plugin } from './store/plugin';
import { KEY } from './key';

export { KEY } from './key';
export { cacheStorage } from './cache';

export const Wishlist = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }], plugin },
});
