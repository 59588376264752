import type { GetterTree } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import type { CartState } from '../types/CartState';

const getters: GetterTree<CartState, RootState> = {
  isEditMode(state) {
    return state.editMode.product !== null;
  },

  getEditingProductId(state) {
    return state.editMode.product ? state.editMode.product.id : null;
  },

  getEditingProduct(state) {
    return state.editMode.product;
  },

  getSelectedOptions(state) {
    return state.editMode.selectedOptions;
  },

  getEditingQty(state) {
    return state.editMode.qty;
  },
};

export default getters;
