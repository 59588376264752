import type { Module } from 'vuex';

import IMyAccountState from '../types/MyAccountState';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const MyAccountState: Module<IMyAccountState, any> = {
  namespaced: true,
  state: {
    myaccount: false,
    modalActive: false,
    drawerActive: false,
  } as IMyAccountState,
  getters,
  actions,
  mutations,
};
