import { localizedRoute as localizedRouteHelper, localizedDispatcherRoute as localizedDispatcherRouteHelper, currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '../helpers';

export const multistore = {
  methods: {
    /**
     * Return localized route params
     * @param {String} relativeUrl
     * @param {Int} width
     * @param {Int} height
     */
    localizedRoute (routeObj, storeCode = '') {
      let code = storeCode;

      if (!code) {
        let storeView

        if (isServer) {
          storeView = this.$ssrContext.helpers.currentStoreView()
        } else {
          storeView = currentStoreView()
        }

        code = storeView.storeCode;
      }

      return localizedRouteHelper(routeObj, code)
    },
    /**
     * Return localized route params for URL Dispatcher
     * @param {String} relativeUrl
     * @param {Int} width
     * @param {Int} height
     */
    localizedDispatcherRoute (routeObj) {
      let storeView

      if (isServer) {
        storeView = this.$ssrContext.helpers.currentStoreView()
      } else {
        storeView = currentStoreView()
      }

      return localizedDispatcherRouteHelper(routeObj, storeView.storeCode)
    }
  }
}
