import type { Module } from 'vuex';

import ICheckoutSteps from '../types/CheckoutSteps';

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const CheckoutStepsStore: Module<ICheckoutSteps, any> = {
  namespaced: true,
  state: {
    section: 'personalDetails',

    personalDetails: {
      valid: false,
    },

    shipment: {
      valid: false,
    },

    payment: {
      valid: false,
    },

    reviewOrder: {
      valid: false,
    },
  } as ICheckoutSteps,
  getters,
  actions,
  mutations,
};
