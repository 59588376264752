import { Format } from '@zento-lib/components/i18n/inline';
import { ActionTree } from 'vuex';
import rootStore from '@vue-storefront/core/store';
import RootState from '@vue-storefront/core/types/RootState';
import { Logger } from '@vue-storefront/core/lib/logger';

import CompareState from '../types/CompareState';
import { cacheStorage } from '../cache';

import * as types from './mutation-types';
const actions: ActionTree<CompareState, RootState> = {
  load({ commit, getters }, force = false) {
    if (!force && getters.isCompareLoaded) return;
    commit(types.SET_COMPARE_LOADED);
    cacheStorage.getItem('current-compare', (err, storedItems) => {
      if (err) throw new Error(err);
      commit(types.COMPARE_LOAD_COMPARE, storedItems);
      Logger.info('Compare state loaded from browser cache: ', 'cache', storedItems)();
    });
  },
  addItem({ commit }, product) {
    commit(types.COMPARE_ADD_ITEM, { product });
    rootStore.dispatch('notification/spawnNotification', {
      type: 'success',
      message: Format.message('compare_add_message'),
      action1: { label: Format.message('notification_action') },
    });
  },
  removeItem({ commit }, product) {
    commit(types.COMPARE_DEL_ITEM, { product });
    rootStore.dispatch('notification/spawnNotification', {
      type: 'success',
      message: Format.message('compare_remove_message'),
      action1: { label: Format.message('notification_action') },
    });
  },
  clear({ commit }) {
    commit(types.COMPARE_LOAD_COMPARE, []);
  },
};

export default actions;
