import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import IStockState from '../../types/StockState';

import actions from './actions';

export const stockModule: Module<IStockState, RootState> = {
  namespaced: true,
  actions,
  state: {
    cache: {},
  },
};
