import type { Module } from 'vuex';
import type RootState from '@vue-storefront/core/types/RootState';

import AttributeState from '../../types/AttributeState';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const attributeModule: Module<AttributeState, RootState> = {
  namespaced: true,
  state: {
    list_by_code: {},
    list_by_id: {},
    blacklist: [],
    labels: {},
  },
  getters,
  actions,
  mutations,
};
