import { AbstractRequester } from '../abstract/AbstractRequester';
import type { RequesterOptions, NormalizedOperation } from '../abstract/types';

import type { GraphQlOperation } from './types';

export class GraphQlRequester<Opts extends RequesterOptions> extends AbstractRequester<
  GraphQlOperation<unknown, unknown>,
  Opts
> {
  public constructor(options: Opts) {
    super();

    // Initialize the requester scheduler
    this.init(options);
  }

  protected normalizeOperation(query) {
    return query;
  }

  /**
   * Execute the actual GraphQl request based on received operation
   */
  protected request<T extends NormalizedOperation<GraphQlOperation<unknown, unknown>>>(operation: T) {
    operation.fetchAt = +new Date(); // Mark operation start time

    return this.doRequest(operation.operation.raw, operation.params)
      .then((result) => result.data)
      .finally(() => (operation.resultAt = +new Date())); // Mark operation end time
  }

  /**
   * Given an array of operations, combines them into a single GraphQl request and returns the results as a map of operation name/alias to result
   */
  protected async requestBatch<T extends NormalizedOperation<GraphQlOperation<unknown, unknown>>>(
    ...options: T[]
  ): Promise<Array<T['result']>> {
    // Compute the query and variables
    const operations: { [type in GraphQlOperation<unknown, unknown>['operation']['type']]: Record<string, string> } = {
      mutation: {},
      query: {},
    };
    const variables: Record<string | number, any> = {};
    const variablesUsageMap: {
      [type in GraphQlOperation<unknown, unknown>['operation']['type']]: Map<string, { type: string }>;
    } = {
      mutation: new Map(),
      query: new Map(),
    };

    // Determine duplicated operations with different parameters requiring aliasing
    options.forEach((o) => {
      o.fetchAt = +new Date(); // Mark operation start time

      const ops = operations[o.operation.type];

      if (ops[o.alias || o.operation.parsed.name]) {
        // There is another operation with the same name, alias this one
        o.alias = this.findLegalIdentifier(o.alias || o.operation.parsed.name, ops);
      }

      ops[o.alias || o.operation.parsed.name] = this.removeOperationWrapper(o.operation.raw, o.operation);

      // Add missing default parameters values to the resulting object
      o.params = o.params || {};
      o.operation.parsed.variables.forEach((p) => {
        if ('defaultValue' in p && !(p.name in (o.params as Record<string, any>))) {
          o.params[p.name] = p.defaultValue;
        }
      });

      Object.keys(o.params).forEach((k) => {
        let identifier = k;

        if (k in variables) {
          if (variables[k] !== o.params[k]) {
            // A previous variable with the same name was already defined, alias this one to the last alias with the same name or a new one
            identifier = this.findLegalIdentifier(k, variables, (base) => variables[base] === o.params[k]);

            if (k !== identifier) {
              // Adjust the raw query to use the aliased variable name
              ops[o.alias || o.operation.parsed.name] = this.aliasVariable(
                ops[o.alias || o.operation.parsed.name],
                k,
                identifier,
              );
            }
          }
        }

        // Add the new variable
        variables[identifier] = o.params[k];

        // Mark the variable usage for current operation type
        variablesUsageMap[o.operation.type].set(
          identifier,
          o.operation.parsed.variables.find((v) => v.name === k),
        );
      });
    });

    // Tokenize operations by type
    const tokenizedOperations: { [type in GraphQlOperation<unknown, unknown>['operation']['type']]: string } = {
      mutation: '',
      query: '',
    };
    ['mutation', 'query'].forEach((o) => {
      const contents = Object.keys(operations[o])
        .map((k) => `${k}: ${operations[o][k]}`)
        .join(',\n');
      const operationDefinition = `${o} ${o.slice(0, 1).toUpperCase()}${o.slice(1)}Wrapper`;

      if (contents) {
        tokenizedOperations[o] = `${operationDefinition}${this.computeOperationParameters(
          variablesUsageMap,
          o as 'mutation' | 'query',
        )} {${contents}}`;
      }
    });

    // Await the result and de-alias to the input operations order
    const results = await Promise.all(
      [
        tokenizedOperations.mutation
          ? this.doRequest(
              tokenizedOperations.mutation,
              Array.from(variablesUsageMap.mutation.keys()).reduce((ret, k) => ({ ...ret, [k]: variables[k] }), {}),
            )
          : null,
        tokenizedOperations.query
          ? this.doRequest(
              tokenizedOperations.query,
              Array.from(variablesUsageMap.query.keys()).reduce((ret, k) => ({ ...ret, [k]: variables[k] }), {}),
            )
          : null,
      ].filter((op) => !!op),
    );

    // Forward the first server error in case of errors
    const failedRequest = results.find((r) => r && 'errors' in r);
    if (failedRequest) {
      throw failedRequest.errors[0];
    }

    // Compute the final result format
    const result = { data: {} };
    results.forEach((r) => {
      Object.keys(r.data ?? {}).forEach((k) => {
        result.data[k] = r.data[k];
      });
    });

    // De-structure result into composing operations and recompose the result with it's first selection (replaced by the alias)
    return options.map((o) => {
      const r = result.data[o.alias || o.operation.parsed.name];

      o.resultAt = +new Date(); // Mark operation end time

      return {
        [o.operation.parsed.selections[0].name]: r,
      };
    });
  }

  /**
   * GraphQl operation tokenizer
   */
  protected tokenizedOperation(o: NormalizedOperation<GraphQlOperation<unknown, unknown>>['operation']) {
    return o.raw;
  }

  private doRequest(operations: string, variables: Record<string | number, any>) {
    return fetch(this.options.url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        ...this.customHeaders,
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: operations,
        variables,
      }),
    }).then(async (resp) => await resp.json());
  }

  /**
   * Given a standard operation removes it's type and name alongside it's enclosing }
   */
  private removeOperationWrapper(raw: string, operation: GraphQlOperation<unknown, unknown>['operation']) {
    const head = new RegExp(
      `(?:^${operation.type}(?:[\\s\\n])*${operation.parsed.name}(?:[\\s\n])*(?:\\(.*\\))*(?:[\\s\n])*\\{)`,
      'g',
    );
    const tail = /(?:.*)\}(?:[\s\n])*$/g;

    return raw.replace(head, '').replace(tail, '').trim();
  }

  /**
   * Given a hash and a key base, searches for the first non existing key in the collection while adding an incremental suffix to the base key name
   */
  private findLegalIdentifier(
    base: string,
    collection: Record<string, any>,
    specialEquality: (base: string) => boolean = () => false,
  ) {
    if (!(base in collection)) {
      return base;
    }

    let suffix = 1;
    let test = base;
    while (true) {
      test = test + suffix;

      if (!(test in collection) || specialEquality(test)) {
        return test;
      } else {
        suffix += 1;
      }
    }
  }

  /**
   * Replace query variables with their aliased identifiers
   */
  private aliasVariable(raw: string, original: string, aliased: string) {
    if (original === aliased) {
      return raw;
    }

    return raw.replace(new RegExp(`\\$${original}`, 'g'), `$${aliased}`);
  }

  /**
   * Given the variables map/operation and the operation type computes the operation's parameters list and returns them pre-formatted
   */
  private computeOperationParameters(
    variablesMap: {
      [type in GraphQlOperation<unknown, unknown>['operation']['type']]: Map<string, { type: string }>;
    },
    operation: 'mutation' | 'query',
  ) {
    const variables = Array.from(variablesMap[operation].keys()).map(
      (k) => `$${k}: ${variablesMap[operation].get(k).type}`,
    );

    return variables.length ? ` (${variables.join(', ')})` : '';
  }
}
