import { module } from './store'
import { createModule } from '@vue-storefront/core/lib/module'

// import { VueStorefrontModule, VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
import { beforeEach } from './router/beforeEach'
import { KEY } from './key';

export { KEY } from './key';
export { cacheStorage } from './cache';

export const Url = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }] },
  router: { beforeEach }
})

