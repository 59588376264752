import Vue, { WatchHandler } from 'vue';
import { CreateVueStore } from 'vue-class-store';

import type { IZentoConfig } from '../../common/stores';
import { proxify } from '../util/proxy';

import { DataSource, DataSources } from './DataSource';

export { Field } from './Field';

type LogMessage = ErrorMessage;

type ErrorMessage = {
  /**
   * The store the error originated from
   */
  store: string;

  /**
   * The method raising the error condition
   */
  method: string;

  /**
   * The error message
   */
  message: string;

  /**
   * Original error stack
   */
  stack: string;
};

export type StoreContext = {
  ssrContext: { _registeredStores: Record<string, BaseStore> } & Record<string | number, any>;
  config: IZentoConfig;
  isServer: boolean;
};

export abstract class BaseStore {
  private static _context: StoreContext;

  /**
   * List of log messages propagated from within stores
   */
  private static logs: LogMessage[] = [];

  private static registrationQueue: Array<{ type: string; instance: BaseStore }> = [];

  /**
   * Proxy data sources in order to allow lazy loading functionality
   */
  private static proxiedDataSources = new Proxy(
    {},
    {
      get(dataSources, dataSource: DataSources) {
        if (!(dataSource in dataSources)) {
          dataSources[dataSource] = new DataSource(dataSource, BaseStore._context);
        }

        return dataSources[dataSource];
      },
    },
  );

  /**
   * Watched store properties keeper
   */
  private __storeWatchedFields: { [k in keyof this]?: Set<WatchHandler<unknown>> } = {};

  /**
   * Register application initialization context
   */
  public static registerContext(ssrContext: StoreContext['ssrContext'], config: StoreContext['config']) {
    // Normalize SSR context stores keeper
    ssrContext._registeredStores = ssrContext._registeredStores || {};

    BaseStore._context = { ssrContext, config, isServer: typeof window === 'undefined' };

    // Register pending stores (if any)
    while (BaseStore.registrationQueue.length) {
      const s = BaseStore.registrationQueue.pop();
      BaseStore.register(s.type, s.instance);
    }
  }

  /**
   * Allows deferred store registration from the @Store decorator
   */
  public static register<T extends BaseStore>(type: string, instance: T) {
    if (BaseStore._context) {
      // Register the new store instance
      BaseStore._context.ssrContext._registeredStores[type] = instance;
    } else {
      BaseStore.registrationQueue.push({ type, instance });
    }
  }

  public constructor(data: Partial<Record<keyof BaseStore, any>> = {}) {
    // Reuse existing store instances when available
    const constructor = this.constructor;
    const type = constructor.name;
    const context = BaseStore._context;

    if (context.ssrContext._registeredStores[type]) {
      console.debug(`Resolving store '${type}' from cached instances`);
      return context.ssrContext._registeredStores[type];
    }

    // Attach any provided hydration and initialization data (initialization can always overwrite hydration)
    data = { ...(this.hydrationState[type] || {}), ...data };
    Object.keys(data).forEach((k) => (this[k] = data[k]));

    // Execute before registration functionality
    this.beforeRegistration();

    console.debug(`New store '${type}' instance created`);
  }

  /**
   * Store context getter
   */
  protected get context() {
    return BaseStore._context;
  }

  /**
   * Data sources access
   */
  public get dataSources(): { [dataSource in DataSources]: DataSource } {
    return BaseStore.proxiedDataSources as { [dataSource in DataSources]: DataSource };
  }

  /**
   * Allows explicit grouping of multiple operations into a single request
   *
   * Due to it's async nature, runInParallel can be used for either grouping queries that have to be executed together before others (prefixed with await) or
   * by allowing the natural queueing process (not using the await).
   *
   * Expected usage:
   * this.runInParallel(
   *   condition ? doQuery : null
   * );
   */
  public async runInParallel(...promises: Array<Promise<any> | undefined | null>) {
    return Promise.all(promises.filter((p) => !!p));
  }

  /**
   * Allows programmatic watching of store's instance fields from non reactive contexts (relative to the current store)
   */
  public watch(field: string, handler: WatchHandler<unknown>) {
    if (!this.__storeWatchedFields[field]) {
      this.__storeWatchedFields[field] = new Set<WatchHandler<unknown>>();
    }

    this.__storeWatchedFields[field].add(handler);

    return () => this.__storeWatchedFields[field].delete(handler);
  }

  /**
   * Functionality to execute before store registration into the root store
   *
   * Store implementations should override this method to ensure external pre-requirements are satisfied before the store is ready to use
   * Only sync functionality is supported
   */
  protected beforeRegistration() {
    // Implement in child classes when required
  }

  /**
   * Functionality to execute after store registration into the root store
   *
   * Store implementations should override this method to ensure external post registration dependencies are satisfied before the store is ready to use
   * Only sync functionality is supported
   */
  protected afterRegistration() {
    // Implement in child classes when required
  }

  /**
   * Mutations execution mechanism with build in safety and logging support
   */
  protected async mutate(mutationFn: () => Promise<void>): Promise<true | Error> {
    return await mutationFn()
      .then(() => true)
      .catch((e) => e);
  }

  /**
   * Uniformly logs errors while extracting relevant information from error's stack
   */
  protected logError(error: Error) {
    const e: ErrorMessage = {
      message: error.message,
      stack: error.stack || '',
      method: (error.stack || '').split('\n')[1],
      store: this.constructor.name,
    };

    // TODO: Extend the logging mechanism be configurable
    BaseStore.logs.push(e);
    console.error(`${e.store} ecountered an error in ${e.method}: ${e.message}`);
  }

  /**
   * Client side hydration state getter
   */
  private get hydrationState() {
    return typeof window !== 'undefined' ? (window as any).__INITIAL_STORE_STATE__ : {};
  }

  // TODO: Mutation with catch guard
  // TODO: Uniform error handling support
}

/**
 * Trigger all watchers registered for the specified store field in series
 */
function triggerWatchers(obj: Record<string, unknown>, field: string, newVal: any, oldVal: any) {
  if (obj.__storeWatchedFields && obj.__storeWatchedFields[field]) {
    (Array.from(obj.__storeWatchedFields[field]) as Array<WatchHandler<unknown>>).forEach((h) => h(newVal, oldVal));
  }
}

const baseStoreProperties = Object.getOwnPropertyNames(BaseStore).reduce((ret, k) => ({ ...ret, [k]: true }), {});

/**
 * Converts decorated class to a vue-class-store store
 */
export function Store<T extends BaseStore>(constructor: new () => T): any {
  if (typeof constructor !== 'function') {
    throw new Error(`Field: Invalid @Store decorator usage. Expected to be applied at class level`);
  }

  const decorated = CreateVueStore(constructor) as any;

  return function convertStoreInstance(...args: any[]) {
    const decoratedInstance = decorated(...args);

    // Determine the list of store's fields
    const fields: Record<keyof typeof decoratedInstance, boolean> = Object.getOwnPropertyNames(decoratedInstance)
      .concat(...(decoratedInstance.__storeFields || [])) // Add known decorated fields (as they have no initializers)
      .filter((k) => !k.startsWith('__') && !baseStoreProperties[k] && k !== 'constructor')
      .reduce((ret, k) => ({ ...ret, [k]: true }), {});

    const instance = proxify(decoratedInstance, {
      set(obj: Record<keyof typeof decoratedInstance, any>, prop: string, value: any) {
        const oldVal = obj[prop];

        if (fields[prop] || ('__storeWatchedFields' in obj && obj.__storeWatchedFields[prop])) {
          Vue.delete(obj, prop);
          Vue.set(obj, prop, value);

          triggerWatchers(obj, prop, value, oldVal);
        } else {
          obj[prop] = value;

          triggerWatchers(obj, prop, value, oldVal);
        }

        return true;
      },
    });

    // Register the wrapped store instance
    BaseStore.register(constructor.name, instance);

    // Execute after registration functionality
    instance.afterRegistration();

    return instance;
  };
}
