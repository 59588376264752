import { createModule } from '@vue-storefront/core/lib/module';

import { ReviewModule } from './store';
import { KEY } from './key';

export { KEY } from './key';

export const Review = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: ReviewModule }] },
});
